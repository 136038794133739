import React from 'react'
import P from '../../../commons/P'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

const Disclaimer = () => {
  const navigate = useNavigate()

  return (
    <DisclaimerContainer>
      <P style={{ marginBottom: 0 }}>
        <Small>* Campi obbligatori.</Small>
      </P>
      <P style={{ marginBottom: 0 }}>
        <Small>
          ** L'invio della richiesta equivale al conferimento del consenso per la finalità
          di cui al punto 2 dell’informativa, anche per l’utilizzo di tecniche di
          comunicazione a distanza, facoltativo ma necessario per offrirti il servizio.{' '}
          <a style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/privacy')}>
            Informativa privacy completa
          </a>
          .
        </Small>
      </P>
    </DisclaimerContainer>
  )
}

// region Style
const Small = styled.small`
  font-size: 1.2rem;
`
const DisclaimerContainer = styled.div`
  text-align: initial;
  @media (min-width: 576px) {
    margin-left: 8.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  @media (min-width: 768px) {
    margin-left: 16.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 1em;
`
// endregion


export default Disclaimer
