import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import H2 from '../../commons/H2'
import Header from '../../commons/Header'
import TitleSection from '../../commons/TitleSection'
import Footer from '../../commons/footer/Footer'
import AgentsMap from './AgentsMap'
import AgentsInfo from './AgentsInfo'
import {IAgent} from '../../../types'
import headerBackground from '../../../img/la_nostra_rete_header.jpg'
import mobileHeaderBackground from '../../../img/la_nostra_rete_header.jpg'
import {regions} from '../../../constants'

const agents: IAgent[] = [
  {
    name: "RUSSO VALERIA",
    owner: 'RUSSO VALERIA',
    oam: 'A16073',
    address: 'Via Dante Alighieri 77',
    city: 'Cisterna di Latina (LT)',
    region: regions.lazio,
    email: 'valeria.russo@agenti.bancasistema.it',
    coordinates: [12.829873112766787, 41.60363734505877],
  },
  {
    name: "PRESTIFIN SRL",
    owner: 'BUOSI FRANCESCO',
    oam: 'A8227',
    address: 'Piazza Rinascita, 13',
    city: 'Carbonia (CI)',
    region: regions.sardegna,
    email: 'francesco.buosi@agenti.bancasistema.it',
    coordinates: [8.52553733965062, 39.168186796115286],
    associated: true
  },
  {
    name: "2 EMME FINANZIAMENTI SNC",
    owner: 'MANCUSO GIUSEPPE e MALETTA GIUSEPPE',
    oam: 'A9309',
    address: 'Via Carlo Pisacane snc',
    city: 'Catanzaro (CZ)',
    region: regions.calabria,
    email: 'giuseppe.mancuso@agenti.bancasistema.it',
    coordinates: [16.642163773598817, 38.832665239387225],
    associated: true
  },
  {
    name: "FIDILINE SRL",
    owner: 'TALLUTO MARCO ',
    oam: 'A10847',
    address: 'P.zza Papa G. Paolo II, 26',
    city: 'Palermo (PA)',
    region: regions.sicilia,
    email: 'marco.talluto@agenti.bancasistema.it',
    coordinates: [13.338212579556508, 38.1506592946517]
  },
  {
    name: "FIDILINE SRL",
    owner: 'TALLUTO MARCO ',
    oam: 'A10847',
    address: 'Via dè Carracci, 75/b',
    city: 'Bologna (BO)',
    region: regions.emiliaRomagna,
    email: 'giacomo.cavazzuti@agenti.bancasistema.it ',
    coordinates: [11.331346404439712, 44.51130109123101],
    associated: true
  },
  {
    name: "SIMONELLI PASQUALINA",
    owner: 'SIMONELLI PASQUALINA',
    oam: 'A6624',
    address: 'Via Segesta, 48',
    city: 'Roma (RM)',
    region: regions.lazio,
    email: 'pasqualina.simonelli@agenti.bancasistema.it',
    coordinates: [12.511297697149477, 41.87367835106818],
    associated: true
  },
  {
    name: "DE SIMONE FRANCESCO",
    owner: 'DE SIMONE FRANCESCO',
    oam: 'A4113',
    address: 'Via San Giovanni Evangelista',
    city: 'Corigliano Rossano (CS)',
    region: regions.calabria,
    email: 'francesco.desimone@agenti.bancasistema.it',
    coordinates: [16.517596092526986, 39.63199762450699]
  },
  {
    name: "MINNITI WALTER GAETANO SALVATORE",
    owner: 'MINNITI WALTER GAETANO SALVATORE',
    oam: 'A11521',
    address: 'Via Petrella ,7',
    city: 'Catania (CT)',
    region: regions.sicilia,
    email: 'walter.minniti@agenti.bancasistema.it',
    coordinates: [15.10368784283485, 37.52428831763362],
    offsetX: -0.25
  },
  {
    name: "PERGOLA NUNZIO",
    owner: 'PERGOLA NUNZIO',
    oam: 'A8648',
    address: 'Via Guido Rossa, 2',
    city: 'Trofarello (TO)',
    region: regions.piemonte,
    email: 'nunzio.pergola@agenti.bancasistema.it',
    coordinates: [7.738283539892174, 44.98149273782849],
    offsetY: 0.3
  },
  {
    name: "AL PIU’ PRESTITO SRL",
    owner: 'AL PIU’ PRESTITO SRL',
    oam: 'A17170',
    address: 'Corso Francia, 66 – 10143',
    city: 'Torino (TO)',
    region: regions.piemonte,
    email: 'alpiuprestito@agenti.bancasistema.it',
    coordinates: [7.738283539892174, 44.98149273782849],
    offsetX: 0.25
  },
  {
    name: "DI RAIMONDO GIANLUCA",
    owner: 'DI RAIMONDO GIANLUCA',
    oam: 'A14288',
    address: 'Via Orazio Giorgio Pluchino, 7',
    city: 'Modica (RG)',
    region: regions.sicilia,
    email: 'gianluca.diraimondo@agenti.bancasistema.it',
    coordinates: [14.762830708170059, 36.86841221634385]
  },
  {
    name: "FRANCESCHETTI ROSANNA",
    owner: 'FRANCESCHETTI ROSANNA',
    oam: 'A10021',
    address: 'Viale R. Margherita, 106',
    city: 'Anagni (FR)',
    region: regions.lazio,
    email: 'r.franceschetti@agenti.bancasistema.it',
    coordinates: [13.150345405695765, 41.74716226499878],
    associated: true
  },

  {
    name: "AL PIU’ PRESTITO SRL",
    owner: 'AL PIU’ PRESTITO SRL',
    oam: '17170',
    address: "Via Vasari, 10/A – 37138",
    city: 'Verona (VR)',
    region: regions.veneto,
    email: 'alpiuprestito@agenti.bancasistema.it',
    coordinates: [12.314923782245929, 45.58370916387055],
    offsetX: 0.25
  },
  {
    name: "GUARNIERI PAOLO",
    owner: 'GUARNIERI PAOLO',
    oam: 'A3722',
    address: "Via Beato Luigi Guanella, 2/A - 35133",
    city: 'Padova (PD)',
    region: regions.veneto,
    email: 'Paolo.guarnieri@agenti.bancasistema.it',
    coordinates: [12.314923782245929, 45.58370916387055],
    offsetX: -0.5
  },
  {
    name: "SEGATO VLADIMIRO",
    owner: 'SEGATO VLADIMIRO',
    oam: 'A14917',
    address: "Via Massimo d'Antona, 20",
    city: 'Mogliano Veneto (TV)',
    region: regions.veneto,
    email: 'vladimiro.segato@agenti.bancasistema.it',
    coordinates: [12.314923782245929, 45.58370916387055],
    offsetY: 0.7
  },
  {
    name: "FINGECA ITALIA SRL",
    owner: 'PICCOLO CARMINE',
    oam: 'A15496',
    address: 'Viale Kennedy, 58',
    city: 'Aversa (CE)',
    region: regions.campania,
    email: 'carmine.piccolo@agenti.bancasistema.it',
    coordinates: [14.183295883905254, 40.98956133875901],
    offsetY: 0.2
  },
  {
    name: "CAVASSI ALESSANDRO",
    owner: 'CAVASSI ALESSANDRO',
    oam: 'A15490',
    address: 'Via Marangoni, 28/A',
    city: 'Udine (UD)',
    region: regions.friuliVeneziaGiulia,
    email: 'alessandro.cavassi@agenti.bancasistema.it',
    coordinates: [13.229335401955032, 46.06168542610197]
  },
  {
    name: "REGGIO MARKETING SRLS",
    owner: 'DENTI GIANMARCO',
    oam: 'A15652',
    address: 'Via della Canalina, 18',
    city: 'Reggio Emilia (RE)',
    region: regions.emiliaRomagna,
    email: 'gianmarco.denti@agenti.bancasistema.it',
    coordinates: [10.617049584061137, 44.685923896932536]
  },
  {
    name: "GALILEO SAS",
    owner: 'GALILEO SAS',
    oam: 'A15671',
    address: 'Corso Casale, 311/F',
    city: 'Torino (TO)',
    region: regions.piemonte,
    email: 'marco.borello@agenti.bancasistema.it',
    coordinates: [7.732235350638175, 45.08105035960063],
    offsetY: -0.2,
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Dante Alighieri, 152',
    city: 'Bari (BA)',
    region: regions.puglia,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [16.866647683910678, 41.121927696875204],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Giovanni Porzio, 4 – Centro Direzionale Isola G1',
    city: 'Napoli (NA)',
    region: regions.campania,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [14.277989726227203, 40.85918460237602],
    associated: true,
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Piazza Guglielmo Marconi, 11',
    city: 'Foggia (FG)',
    region: regions.puglia,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [15.547427855088207, 41.462125433762544],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Giuseppe Garibaldi, 9',
    city: 'Campobasso (CB)',
    region: regions.molise,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [14.664503056946888, 41.56094420073404],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Tribuna,198',
    city: 'Manfredonia (FG)',
    region: regions.puglia,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [15.922579268586004, 41.63317933010667],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Renato Paolini, 66',
    city: 'Pescara (PE)',
    region: regions.abruzzo,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [14.199867539784215, 42.46410579409581],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA ",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Torrione 116/118 – 84127',
    city: 'Salerno (SA)',
    region: regions.campania,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [14.78138278549071, 40.67276196930393],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Giuseppe Mazzini 155/a',
    city: 'Potenza (PZ)',
    region: regions.basilicata,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [15.804065404358399, 40.641846133020074],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Solito, 81',
    city: 'Taranto (TA)',
    region: regions.puglia,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [17.261578712720194, 40.461280879201084],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Via Muratori, 141',
    city: 'Modena (MA)',
    region: regions.emiliaRomagna,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [10.858767867970576, 44.59389572169364],
    associated: true
  },
  {
    name: "QVINTO NETWORK SPA",
    owner: 'DI STASI LUCA',
    oam: 'A3205',
    address: 'Piazza San Lorenzo, 10',
    city: 'Palermo (PA)',
    region: regions.sicilia,
    email: 'luca.distasi@agenti.bancasistema.it',
    coordinates: [13.331260785658749, 38.15734362334619]
  },
  {
    name: "PERRONE FRANCESCO",
    owner: 'PERRONE FRANCESCO',
    oam: 'A12356',
    address: "Via Monticano 12/H",
    city: 'Conegliano Veneto (TV)',
    region: regions.veneto,
    email: 'francesco.perrone@agenti.bancasistema.it',
    coordinates: [12.296737502249496, 45.872459882927274],
  },
  {
    name: "BPS SAS",
    owner: 'BPS SAS',
    oam: 'A7693',
    address: "Viale Emilia, 114 - 58100",
    city: 'Grosseto (GR)',
    region: regions.toscana,
    email: 'bps@agenti.bancasistema.it',
    coordinates: [11.115986757800947, 42.77107326559942],
    associated: true
  },
  {
    name: "BPS SAS",
    owner: 'BPS SAS',
    oam: 'A7693',
    address: "Viale Vittorio Alfieri, 10 - 57124",
    city: 'Livorno (LI)',
    region: regions.toscana,
    email: 'bps@agenti.bancasistema.it',
    coordinates: [10.326645520743211, 43.55435685726428],
    associated: true
  },
  {
    name: "BPS SAS",
    owner: 'BPS SAS',
    oam: 'A7693',
    address: "Via Romana, 4/A - 52100",
    city: 'Arezzo (AR)',
    region: regions.toscana,
    email: 'bps@agenti.bancasistema.it',
    coordinates: [ 11.866359373748852, 43.4641633488375],
    associated: true
  },
  {
    name: "BPS SAS",
    owner: 'BPS SAS',
    oam: 'A7693',
    address: "Via Valentini, 88 - 59100",
    city: 'Prato (PO)',
    region: regions.toscana,
    email: 'bps@agenti.bancasistema.it',
    coordinates: [11.098782204549558, 43.8894103061076],
    associated: true
  },
  {
    name: "BPS SAS",
    owner: 'BPS SAS',
    oam: 'A7693',
    address: "Lungarno Mediceo, 29",
    city: 'Pisa (PI)',
    region: regions.toscana,
    email: 'bps@agenti.bancasistema.it',
    coordinates: [10.405479212861009, 43.715629345619654],
    associated: true
  },
  {
    name: "LEASECREDI SRL",
    owner: 'LEASECREDI SRL',
    oam: 'A9153',
    address: "Via Pierluigi da Palestrina",
    city: 'Cagliari (CA)',
    region: regions.sardegna,
    email: 'sergio.muntoni@agenti.bancasistema.it',
    coordinates: [9.130225725737805, 39.22460066485498],
  },
  {
    name: "LUZO EDMOND",
    owner: 'LUZO EDMOND',
    oam: 'A17062',
    address: 'Piazza Marie Curie, 5/A 2004b',
    city: 'Bussero (Mi)',
    region: regions.lombardia,
    email: 'edmond.luzo@agenti.bancasistema.it',
    coordinates: [8.906885823642455, 45.627129119732146]
  },
  {
    name: "TAGLIAVERGA MARCO",
    owner: 'TAGLIAVERGA MARCO',
    oam: '17043',
    address: 'Via Ampere, 100 - 20131',
    city: 'Milano (MI)',
    region: regions.lombardia,
    email: 'marco.tagliaverga@agenti.bancasistema.it',
    coordinates: [8.906885823642455, 45.627129119732146],
    offsetX: 0.5
  },
  {
    name: "SOFFRITTI DONATELLA",
    owner: 'SOFFRITTI DONATELLA',
    oam: 'A8119',
    address: "Via Gianbattista Moroni, 174",
    city: 'Bergamo (BG)',
    region: regions.lombardia,
    email: 'donatella.soffritti@agenti.bancasistema.it',
    coordinates: [9.66147804155661, 45.69002557805625],
  },
  {
    name: "ICQ ITALIA SRL",
    owner: 'GIUSEPPE ORAZIO',
    oam: 'A11531',
    address: "Via Enrico Fermi, 65",
    city: 'Cardito (NA)',
    region: regions.campania,
    email: 'Giuseppe.orazio@agenti.bancasistema.it',
    coordinates: [14.297871203006473, 40.93280150818264],
  },
  {
    name: "BARRA TERESA",
    owner: 'BARRA TERESA',
    oam: 'A16682',
    address: "Via Nuova Belvedere, 129",
    city: 'Cardito (NA)',
    region: regions.campania,
    email: 'Teresa.barra@agenti.bancasistema.it',
    coordinates: [14.284900139505083, 40.94183845748156],
    offsetX: -0.1,
  },
  {
    name: "FANTINI VALERIA",
    owner: 'FANTINI VALERIA',
    oam: 'A16725',
    address: "Via Trilussa, 119 c",
    city: 'Albano Laziale (RM)',
    region: regions.lazio,
    email: 'Valeria.fantini@agenti.bancasistema.it',
    coordinates: [12.658903853933058, 41.72189425418047],
  },
  {
    name: "CIACCI GIUSEPPE",
    owner: 'CIACCI GIUSEPPE',
    oam: 'A16647',
    address: "Via Bepi Romagnoni, 23",
    city: 'Roma (RM)',
    region: regions.lazio,
    email: 'Giuseppe.ciacci@agenti.bancasistema.it',
    coordinates: [12.348800639765678, 41.77778371377215],
  },
  {
    name: "GE AGENZIA IN ATTIVITA' FINANZIARIA SRL",
    owner: 'MACCIOCCHI MASSIMO',
    oam: 'A15645',
    address: 'Corso Trieste 16',
    city: 'Torino (TO)',
    region: regions.piemonte,
    email: 'massimo.macciocchi@agenti.bancasistema.it',
    coordinates: [7.665917618911033, 45.062757524355085],
    offsetX: -0.3
  },
  {
    name: "FIDILINE SRL",
    owner: 'TALLUTO MARCO ',
    oam: 'A10847',
    address: 'Via Emilia, 84-86',
    city: 'Palermo (PA)',
    region: regions.sicilia,
    email: 'marco.talluto@agenti.bancasistema.it',
    coordinates: [13.338212579556508, 38.1506592946517],
    offsetX: 0.2
  }
]

const LaNostraRete = () => {
  return (
    <>
      <Header/>
      <TitleSection
        backgroundUrl={`url(${headerBackground})`}
        mobileBackgroundUrl={`url(${mobileHeaderBackground})`}
        title={"LA NOSTRA RETE"}
        subtitle={"il team QuintoPuoi è sempre più vicino a te"}
      >
      </TitleSection>
      <Main>
        <PageTextContainer>
          <StyledP>
            Oggi Banca Sistema, specializzata nel credito al consumo, è strutturata con una linea distributiva di Partner formata da più di 70 intermediari OAM e oltre 800 tra Agenti monomandatari, Mediatori e Collaboratori presenti in tutta Italia e una macchina operativa dedicata interna alla banca dislocata a Milano, Bologna e Roma.
          </StyledP>
          <StyledP>
            Qui trovi la copertura dei nostri agenti QuintoPuoi a tua disposizione per consigliarti la soluzione di credito in linea con le tue esigenze. Scegli l’agenzia più vicina a te, scegli il Credito alla Famiglia di Banca Sistema.
          </StyledP>
        </PageTextContainer>
        <MapContainer>
          <AgentsMap agents={agents}/>
        </MapContainer>
        <AgentsTextContainer>
          <StyledH2>
            I NOSTRI AGENTI
          </StyledH2>
          <AgentsInfo agents={agents}/>
        </AgentsTextContainer>
      </Main>
      <Footer/>
    </>
  )
}


// region Style
const Main = styled.div`
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const PageTextContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding: 100px 15px 15px 15px;
  margin-right: auto;
  margin-left: auto;
`
const AgentsTextContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding: 15px 15px 15px 15px;
  margin-right: auto;
  margin-left: auto;
`
const StyledP = styled(P)`
  text-align: initial;
`
const StyledH2 = styled(H2)`
  text-align: initial;
`
const MapContainer = styled.div`
  max-width: 1100px;
  min-height: 400px;
  background: transparent;
  margin: auto;
`
// endregion

export default LaNostraRete
