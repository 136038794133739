import React from 'react'
import styled from 'styled-components'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import H1 from '../../commons/H1'
import P from '../../commons/P'

const Cookies = () => {
  return(
    <>
      <Header/>
      <Page>
        <Container>
          <Row>
            <RowInnerContainer>
              <StyledH1>Cookies</StyledH1>
              <PageContainer>
                <JustifiedP>Banca Sistema S.p.A. fornisce all’utente, nel rispetto della normativa nazionale (cfr. Provvedimento del Garante per la protezione dei dati personali “Individuazione delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie”, 8 maggio 2014) e del regolamento UE 679/2016 (GDPR) informazioni sulle tipologie, finalità e modalità di utilizzo e gestione dei cookie utilizzati sul sito web <A href="http://www.bancasistema.it">www.bancasistema.it</A> e <A href="https://www.quintopuoi.it">www.quintopuoi.it</A>.</JustifiedP>
                <JustifiedP>La presente Informativa ha anche lo scopo di fornire indicazioni agli utenti di questo sito circa le azioni per rifiutare, modificare o prestare il consenso sui cookie utilizzati. L’utilizzo di questo sito Web comporta l’accettazione, da parte dell’utente, del ricorso ai cookie (e tecnologie analoghe) conformemente alla presente informativa.</JustifiedP>
                <JustifiedP>I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall’Utente inviano al suo terminale (solitamente al browser), dove vengono memorizzati al fine di essere poi ritrasmessi agli stessi siti alla visita successiva. Sono usati per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni sui siti (senza l’uso dei cookie “tecnici” alcune operazioni risulterebbero molto complesse o impossibili da eseguire). I cookie possono essere memorizzati in modo permanente sul Suo computer ed avere una durata variabile (c.d. cookie persistenti), ma possono anche svanire con la chiusura del browser o avere una durata limitata (c.d. cookie di sessione). Nel corso della navigazione su un sito, l’utente può ricevere sul suo terminale anche cookie che sono inviati da siti o da web server diversi (c.d. “cookie di terze parti”), sui quali possono risiedere alcuni elementi (quali, ad esempio, immagini, mappe, suoni, specifici link a pagine di altri domini) presenti sul sito che lo stesso sta visitando.</JustifiedP>
                <P>Il nostro Sito utilizza</P>
                <Ul>
                  <li>
                    <JustifiedP style={{marginBottom: 0}}>
                      Cookie Necessari (c.d. Cookies Tecnici) per consentire all’Utente un agevole utilizzo del sito e un più facile reperimento delle informazioni, semplificando la connessione e le trasmissioni di dati tra Utente e Sito. In particolare, utilizziamo
                    </JustifiedP>
                  </li>
                  <li>
                    <JustifiedP style={{marginBottom: 0}}>
                      Cookie di navigazione o di sessione temporanei, che garantiscono la normale navigazione e fruizione del Sito permettendo, ad esempio, di salvare informazioni inerenti alla navigazione, di memorizzare dati per mantenere la sessione di navigazione attiva o di autenticarsi per accedere ad aree riservate. I cookie di sessione utilizzati in questo Sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione dell’Utente. I cookie di sessione – che non vengono memorizzati in modo persistente sul computer dell’Utente e svaniscono con la chiusura del browser – sono utilizzati al solo fine di trasmettere gli identificativi di sessione e i dati immessi dall’Utente stesso necessari per consentire l’esplorazione sicura ed efficiente del Sito;
                    </JustifiedP>
                  </li>
                  <li>
                    <P>Cookie Statistici (c.d. Cookies analytics) per raccogliere informazioni sull’utilizzo del Sito.</P>
                    <Ul>
                      <li>
                        <JustifiedP>
                          In particolare, ci avvaliamo del servizio di Google Analytics: Si tratta di un servizio di analisi web fornito da Google Inc, (“Google”) che utilizza dei cookie che vengono depositati sul computer dell’utente per consentire di comprendere in che modo i visitatori interagiscono con noi.<br/>
                          Google utilizza cookie proprietari per monitorare le interazioni dei visitatori. Questo cookie viene utilizzato per memorizzare informazioni, ad esempio l’ora in cui si è verificata la visita corrente, eventuali precedenti visite al sito da parte dello stesso visitatore e il sito che ha segnalato la pagina web al visitatore.<br/>
                          Noi ci avvaliamo della funzione di anonimizzazione degli indirizzi IP degli utenti che visitano il sito internet, al fine di non rendere gli stessi identificabili da parte di Google. L’implementazione di tale impostazione di Google Analytics consente di anonimizzare l’indirizzo IP, impostando il valore 0 in sostituzione della parte terminale dell’IP (ultimo ottetto in caso di indirizzo IPv4 e gli ultimi 80 bit degli indirizzi IPv6).
                        </JustifiedP>
                        <Table>
                          <tbody>
                            <Tr>
                              <Td>
                                <P style={{marginBottom: 0}}>Indirizzo IP Utente</P>
                              </Td>
                              <Td>
                                <P style={{marginBottom: 0}}>Indirizzo IP anonimizzato</P>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>
                                <P style={{marginBottom: 0}}>125.31.214.144</P>
                              </Td>
                              <Td>
                                <P style={{marginBottom: 0}}>125.31.214.0</P>
                              </Td>
                            </Tr>
                          </tbody>
                        </Table>
                        <JustifiedP>Tale processo di anonimizzazione avviene in fase di trasmissione del dato ai sistemi di Google, per cui l’immagazzinamento in memoria dei dati e la successiva elaborazione avviene senza che sia mai stato scritto l’indirizzo IP completo da parte di Google. Inoltre, è stato implementato il blocco della condivisione delle informazioni verso Google e relativi Partner.</JustifiedP>
                        <P>Agli indirizzi:</P>
                        <P>
                          <A href="https://policies.google.com/technologies/cookies?hl=it">https://policies.google.com/technologies/cookies?hl=it (Policy in italiano)</A><br/>
                          <A href="https://support.google.com/analytics/answer/2763052?hl=it">https://support.google.com/analytics/answer/2763052?hl=it</A>
                        </P>
                        <JustifiedP>vengono proposte le politiche di come Google utilizza i cookie c.d. “Cookie Analitici” (destinati all’analisi del traffico per le statistiche dei siti web). Per questi Cookie, è necessario il consenso preventivo del visitatore qualora il Titolare del trattamento (anche per il tramite del gestore del sito) non perfezionasse la mascheratura dell’indirizzo IP. I dati identificativi dell’utente vengono utilizzati per analizzare come avviene la visita dell’utente sul sito web della Titolare.</JustifiedP>
                      </li>
                      <li>
                        <JustifiedP>Cookie di marketing (c.d. cookies di profilazione) di terze parti necessari a creare o personalizzare profili utenti al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’Utente all’interno delle pagine del Sito.</JustifiedP>
                        <Table2 cellPadding={0} cellSpacing={0}>
                          <tbody>
                          <Tr>
                            <Td2>
                              <b>NOME</b>
                            </Td2>
                            <Td2>
                              <b>SCOPO DEL COOKIE</b>
                            </Td2>
                            <Td2>
                              <b>INFORMATIVA</b>
                            </Td2>
                          </Tr>
                          <Tr>
                            <Td2 style={{verticalAlign: 'top'}}>
                              <b>Google</b>
                            </Td2>
                            <Td2>Google Adwords &amp; Google Remarketing Cookie per:
                              <Ul>
                                <li>erogazione pubblicità basata sugli interessi manifestati attraverso la navigazione internet;</li>
                                <li>invio pubblicità a soggetti che hanno precedentemente visitato questo sito;</li>
                                <li>ottimizzazione della navigazione;</li>
                                <li>condivisione di contenuti sui social network.</li>
                              </Ul>
                            </Td2>
                            <Td2 style={{verticalAlign: 'top'}}>
                              <A href="https://policies.google.com/privacy?hl=it&amp;gl=es" target="_blank" rel="noopener">Link</A>
                            </Td2>
                          </Tr>
                          <Tr>
                            <Td2 style={{verticalAlign: 'top'}}>
                              <b>Facebook</b>
                            </Td2>
                            <Td2>
                              <Ul>
                                <li>Pixel di conversione Facebook<br/>
                                  Consentono al Titolare di monitorare le conversioni che si verificano sul suo sito Web come risultato delle inserzioni che sta eseguendo su Facebook. Ad esempio, nel caso in cui il Titolare desideri monitorare le adesioni ad una particolare campagna pubblicizzata su Facebook, il cookie comunica a Facebook ogni volta che un utente ha aderito ad una particolare campagna sul sito del Titolare. Facebook confronta l’evento di conversione con il gruppo di persone a cui è stata mostrata l’inserzione o che hanno cliccato su di essa, al fine di fornire al Titolare le informazioni che lo aiutano a capire il ritorno sull’investimento per la sua spesa pubblicitaria.</li>
                                <li>Plug-In sociale di Facebook<br/>
                                  Sul Sito sono presenti plug-in sociali (“Plugins”) della rete sociale Facebook. Questi servizi sono offerti dalla società Facebook Inc. (“Provider”).<br/>
                                  Facebook è gestito da Facebook Inc., 1601 Willow Road Menlo Park, CA 94025, USA (“Facebook”).</li>
                              </Ul>
                            </Td2>
                            <Td2 style={{verticalAlign: 'top'}}>
                              <A href="https://it.linkedin.com/legal/cookie-policy?form=MY01SV&amp;OCID=MY01SV" target="_blank" rel="noopener">Link</A>
                            </Td2>
                          </Tr>
                          <Tr>
                            <Td2 style={{verticalAlign: 'top'}}><b>Teads</b></Td2>
                            <Td2>
                              <I>Cookie di profilazione pubblicitaria</I>
                              <ul>
                                <li>Erogazione di pubblicità basata sugli interessi manifestati attraverso la navigazione internet</li>
                                <li>Personalizzazione dei contenuti</li>
                                <li>Ottimizzazione della navigazione</li>
                              </ul>
                            </Td2>
                            <Td2 style={{verticalAlign: 'top'}}>
                              <A href="https://www.teads.com/teads-website-privacy-policy/" target="_blank" rel="noopener">Link</A>
                            </Td2>
                          </Tr>
                          <Tr>
                            <Td2 style={{verticalAlign: 'top'}}><b>Vertigo</b></Td2>
                            <Td2>
                              <I>Cookie di profilazione pubblicitaria</I><br/>
                              <I>Cookie di retargeting</I>
                              <Ul>
                                <li>Invio pubblicità a soggetti potenzialmente interessati a questo servizio</li>
                                <li>Personalizzazione dei contenuti per presentare offerte, promozioni e opportunità commerciali.</li>
                              </Ul>
                            </Td2>
                            <Td2 style={{verticalAlign: 'top'}}>
                              <A href="http://affiliates.vertigomediaperformance.com/privacy_policy" target="_blank" rel="noopener">Link</A>
                            </Td2>
                          </Tr>
                          </tbody>
                        </Table2>
                      </li>
                    </Ul>
                  </li>
                </Ul>
                <P><strong>Tempo di conservazione dei dati/durata dei cookie</strong></P>
                <JustifiedP>
                  I dati dell’utente vengono conservati per il tempo strettamente necessario all’adempimento delle finalità per cui sono stati raccolti, nel rispetto dei termini prescrizionali o dei diversi termini eventualmente stabiliti dalla legge per la relativa conservazione o, per un tempo maggiore, nel caso in cui sia necessario conservarli per esigenze di tutela dei diritti del titolare del trattamento (ad esempio, alcuni cookie – come quelli di sessione – restano attivi solo fino alla chiusura del browser, mentre altri cookie – come quelli di profilazione di terze parti – “sopravvivono” alla chiusura del browser e sono disponibili anche in successive visite dell’utente).
                </JustifiedP>
                <P>
                  <strong>I Cookie e le impostazioni del browser</strong>
                </P>
                <JustifiedP>
                  L’Utente può autorizzare, limitare o bloccare i cookie attraverso le impostazioni del browser, tuttavia, se l’Utente imposterà il proprio terminale in modo da rifiutare i cookie tecnici, alcuni servizi del Sito potrebbero non essere visualizzati correttamente o funzionare in maniera non ottimale.
                </JustifiedP>
                <JustifiedP>In questa sezione l’Utente trova le informazioni per disattivare i cookie sul proprio browser, come fornite dal fornitore del browser utilizzato dall’utente. Se tale browser non si trova nell’elenco sotto riportato, l’Utente è pregato di consultare le istruzioni riportate sul proprio browser in merito alla gestione dei cookie.
                </JustifiedP>
                <P>
                  Google Chrome:<br/>
                  <A href="https://support.google.com/chrome/answer/95647?hl=it&amp;co=GENIE.Platform=Desktop" target="_blank" rel="noopener">Cancellare, attivare e gestire i cookie in Chrome (Desktop, Android, iPhone e iPad)</A>
                </P>
                <P>Mozilla Firefox:<br/>
                  <A href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop" target="_blank" rel="noopener">La protezione antitracciamento avanzata in Firefox per desktop</A>
                </P>
                <P>Safari su Mac:<br/>
                  <A href="https://support.apple.com/it-it/guide/safari/sfri11471/15.1/mac/12.0" target="_blank" rel="noopener">Gestire i cookie e i dati dei siti web in Safari sul Mac</A>
                </P>
                <P>Safari su Safari su iPhone, iPad o iPod touch:<br/>
                  <A href="https://support.apple.com/it-it/HT201265" target="_blank" rel="noopener">Come cancellare la cronologia e i cookie da Safari su iPhone, iPad o iPod touch</A>
                </P>
                <P>Microsoft Internet Explorer / Edge:<br/>
                  <A href="https://support.microsoft.com/it-it/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noopener">Supporto Tecnico Microsoft – Eliminare e gestire i cookie</A>
                </P>
                <P>Opera:<br/>
                  <A href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noopener">Web Preferences (Disponibile solo in inglese)</A>
                </P>
                <JustifiedP>
                  Ti ricordiamo che se disattivi i cookie, potresti disabilitare alcune funzionalità del sito. Anche con tutti i cookie disabilitati, il tuo browser continuerà a memorizzare una piccola quantità di informazioni, necessarie per le funzionalità di base del sito.
                </JustifiedP>
              </PageContainer>
            </RowInnerContainer>
          </Row>
        </Container>
      </Page>
      <Footer/>
    </>
  )
}

// region Style
const Page = styled.div`
  @media(max-width: 990px) {
    margin-top: 70px;
  }
  margin-top: 128px;
  padding-top: 4rem;
  display: block;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row  = styled.div`
  margin-bottom: 3rem !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const RowInnerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledH1 = styled(H1)`
  margin-bottom: 0.75em;
  text-align: initial;
`
const JustifiedP = styled(P)`
  text-align: justify;
`
const PageContainer = styled.div`
  text-align: initial;
`
const A = styled.a`
  color: inherit;
`
const Ul = styled.ul`
  list-style: disc;
  padding-left: 3rem;
  margin-bottom: 1em;
`
const Table = styled.table` 
  width: 60%;
  border-collapse: collapse;
  border-spacing: 0;
`
const Table2 = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
`
const Tr = styled.tr`
  border-bottom: solid 1px #ccc;
`
const Td = styled.td`
  width: 60%;
  padding: 0.7rem 0;
`
const Td2 = styled.td`
  padding: 0.7rem 0;
`
const I = styled.i`
  font-style: normal;
  color: #706f6f;
`
// endregion

export default Cookies
