const isDev = process.env.NODE_ENV === 'development'

export const baseUrl = isDev ? 'https://qpbe.azurewebsites.net/api' : 'https://be.quintopuoi.it/api'
export const reCaptchaPublicKey = isDev
  ? '6LdbPvIfAAAAAFTFSpYRQPpIBLbtnsIjjjUR5pHq'
  : '6LfZCcwfAAAAAL5nkdWfJgD6Kf5ArxJqX6itfkhd'

/*
  export const reCaptchaPublicKey = isDev
  ? '6LdbPvIfAAAAAFTFSpYRQPpIBLbtnsIjjjUR5pHq'
  : '6LfZCcwfAAAAAL5nkdWfJgD6Kf5ArxJqX6itfkhd'
*/

/*
Ambiente VALID:
export const baseUrl = isDev ? 'https://qpbe.azurewebsites.net/api' : 'https://qpvalidbe.azurewebsites.net/api'

Ambiente DEVEL:
export const baseUrl = isDev ? 'https://qpbe.azurewebsites.net/api' : 'https://qpbe.azurewebsites.net/api'

Ambiente PROD:
export const baseUrl = isDev ? 'https://qpbe.azurewebsites.net/api' : 'https://be.quintopuoi.it/api'
*/

export const thumbnailUrl = isDev
  ? 'http://localhost:3000/public/thumbnails'
  : 'https://be.quintopuoi.it/public/thumbnails'
// http://localhost:3000/public/thumbnails/default_thumbnail.jpeg
