import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ICategory } from './Article'

export interface VideoProps {
  category?: ICategory
  content?: string
  creationDate?: string
  id?: number
  name: string
  publicationDate?: string
  published?: boolean
  subtitle?: string
  thumbnail?: string
  title: string
}

const Video: React.FC<VideoProps> = ({ thumbnail, title, subtitle }) => {
  return (
    <>
      <VideoWrapper>
        <VideoContainer>
          <IFrame
            src={thumbnail}
            // src="https://www.youtube.com/embed/09ra4UluNTg"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
            allowFullScreen={true}
          />
        </VideoContainer>
        <VideoTitle>
          <TitleContainer>{title}</TitleContainer>
          <SubTitleContainer>{subtitle}</SubTitleContainer>
        </VideoTitle>
      </VideoWrapper>
    </>
  )
}

export default Video

const VideoContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  padding-top: 56.25%;
  display: block;
  content: '';
  box-sizing: inherit;
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
`
const VideoTitle = styled.div``

const TitleContainer = styled.h2`
  margin: 2rem 0;
  text-align: left;
  font-weight: 700;
  padding: 0 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SubTitleContainer = styled.h5`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: 700;
  padding: 0 2rem 2rem 2rem;
`
const VideoWrapper = styled.div`
  box-shadow: 4px 7px 11px -2px rgba(112, 112, 112, 0.62);
  border-radius: 1rem;
  margin-top: 5rem;
  width: 40%;
  @media (min-width: 768px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`
