import React from 'react'
import styled from 'styled-components'

const GlossarySlide = ({children}: any) => {
  return (
    <div className={'slide-container'}>
      <GlossaryBlock>
        <Container>
          <Row>
            {children}
          </Row>
        </Container>
      </GlossaryBlock>
    </div>
  )
}

//region Style
const GlossaryBlock = styled.div`
  width: 100%;
  display: inline-block;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
//endregion

export default GlossarySlide
