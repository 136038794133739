import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Link from "./Link";
import { useNavigate } from "react-router-dom";
import Select, { ISelectData, Options } from "./Select";
import Identity from "../../images/ico_identity.svg";
import Money from "../../images/ico_money.svg";
import Time from "../../images/ico_time.svg";

interface FormProps {
  title?: string | string[];
  yellowTitle?: string;
  subtitle?: string;
  isTitleUppercase?: boolean;
  windowOffset: number;
  children?: any
}

const Form: React.FC<FormProps> = ({
  isTitleUppercase,
  windowOffset,
  children
}) => {
  const dataFromLocalStorage: ISelectData[] = JSON.parse(
    localStorage.getItem("selectData") || "[]"
  );
  const navigate = useNavigate();
  const [data, setData] = useState<ISelectData>({
    name: "",
    value: "",
    category: "",
  });
  const [dataToNextPage, setDataToNextPage] =
    useState<ISelectData[]>(dataFromLocalStorage);
  const [detachForm, setDetachForm] = useState<boolean>(false);

  const dataFromSelect = (selectData: ISelectData) => setData(selectData);

  // gestisce la modifica dei campi nella form, sostituendo o appendendo un nuovo campo ad un oggetto che viene poi inviato
  useEffect(() => {
    const arr = dataToNextPage;
    const hasElement = arr.some((el) => el.category === data.category);
    if (!hasElement) arr.push(data);
    else {
      arr.forEach((el, i) => {
        if (data.category === el.category) arr[i] = data;
      });
    }

		const filteredArr = arr.filter((el) => el.category !== '')
		setDataToNextPage(filteredArr)
	}, [data])

  const customerCategoryList: Options[] = [
    {
      label: 'Dipendente azienda SPA/SRL',
      value: 'Privato'
    },
    {
      label: 'Dipendente Pubblico/Statale',
      value: 'Pubblico-Statale'
    },
    {
      label: 'Pensionato INPS',
      value: 'Pensionato'
    },
    {
      label: 'Partita IVA/Autonomo',
      value: 'P.IVA e AUTONOMO'
    }
  ]

	const amountList: Options[] = [
		{
			label: 'Fino a 5.000 €',
			value: '5000',
		},
		{
			label: 'Fino a 10.000 €',
			value: '10000',
		},
		{
			label: 'Fino a 15.000 €',
			value: '15000',
		},
		{
			label: 'Fino a 20.000 €',
			value: '20000',
		},
		{
			label: 'Fino a 25.000 €',
			value: '25000',
		},
		{
			label: 'Fino a 30.000 €',
			value: '30000',
		},
		{
			label: 'Fino a 50.000 €',
			value: '50000',
		},
		{
			label: 'Fino a 60.000 €',
			value: '60000',
		},
		{
			label: 'Oltre 60.000 €',
			value: 'Oltre',
		},
	]

  const durationList: Options[] = [
    {
     label: '24 rate',
     value: '24',
    },
    {
     label: '36 rate',
     value: '36',
    },
    {
     label: '48 rate',
     value: '48',
    },
    {
     label: '60 rate',
     value: '60',
    },
    {
     label: '72 rate',
     value: '72',
    },
    {
     label: '84 rate',
     value: '84',
    },
    {
     label: '96 rate',
     value: '96',
    },
    {
     label: '108 rate',
     value: '108',
    },
    {
     label: '120 rate',
     value: '120',
    },
   ]

  const handleScroll = () => {
    if (window.pageYOffset >= windowOffset) {
      setDetachForm(true);
    } else {
      setDetachForm(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <QuoteCalculator>
      <form>
        <div>
          {children}
        </div>
        <QuoteCalculatorContainer>
          <QuoteCalculatorBar className={detachForm ? "is-pinned" : ""}>
            <ContainerFluid>
              <Row>
                <Col>
                  <Select
                    name={"macroprofessione"}
                    options={customerCategoryList}
                    placeHolderText={"CHI SONO*"}
                    icon={Identity}
                    dataFromSelect={dataFromSelect}
                  />
                </Col>{" "}
                <Col>
                  <Select
                    name={"importo"}
                    options={amountList}
                    placeHolderText={"IMPORTO*"}
                    icon={Money}
                    dataFromSelect={dataFromSelect}
                  />
                </Col>
                <Col>
                  <Select
                    name={"durata"}
                    options={durationList}
                    placeHolderText={"DURATA*"}
                    icon={Time}
                    dataFromSelect={dataFromSelect}
                  />
                </Col>
                <ColAuto>
                  <Link
                    text={"Continua"}
                    textStyle={{ textTransform: "uppercase" }}
                    linkStyle={{
                      margin: "0 5px",
                      height: "70%",
                    }}
                    onClick={() => {
                      navigate('/preventivatore')
                    }}
                  ></Link>
                </ColAuto>
              </Row>
            </ContainerFluid>
            <ContainerText>
              <a
                href="/anticipo-tfs/"
                style={{
                  color: "#fff",
                  borderLeft: "solid 4px #FCBB00",
                  padding: "1px 0 0px 10px",
                  display: "inline-block",
                  fontSize: "15px",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                <span>SCOPRI ANCHE IL NOSTRO </span>
                <span style={{ color: "#FCBB00", textTransform: "uppercase" }}>
                  {" "}
                  anticipo tfs
                </span>
              </a>
            </ContainerText>
          </QuoteCalculatorBar>
        </QuoteCalculatorContainer>
      </form>
    </QuoteCalculator>
  );
};

export default Form;

const QuoteCalculator = styled.section`
  position: relative;
  max-width: 114rem;
  margin: 0 auto;
  margin-top: -8rem;
  background-color: #222;
  border-radius: 4.5rem;
  z-index:1;
`;

const QuoteCalculatorContainer = styled.div`
  min-height: 7.6rem;
`;

const QuoteCalculatorBar = styled.div`
  padding-bottom: 1.5rem;
  max-width: 114rem;
  transition: padding 0.1s ease-out;
  z-index: 10;
`;

const ContainerFluid = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-left: 1rem;
`;

const Col = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ColAuto = styled.div`
  flex: 0 0 auto;
  width: auto;
  a {
   min-width: unset!important;
  }
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  margin-bottom: 1.5rem !important;
`;

const ContainerText = styled.div`
  text-align: center;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
`;
