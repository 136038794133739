import React from 'react'
import styled from 'styled-components'
import bgImage from '../../../img/shut.jpg'
import P from '../../commons/P'

const ChooseBs = () => {
  return (
    <Container>
      <InnerContainer>
        <Row>
          <ImgContainer>
            <Img src={bgImage} alt={'Una coppia che sorride guardando un tablet'}/>
          </ImgContainer>
          <TextContainer>
            <TextInnerContainer>
             {/* <Title>
                SCEGLI BANCA SISTEMA PER IL TUO <Span>ANTICIPO TFS</Span>
              </Title>*/}
              <Title>
                SCEGLI BANCA SISTEMA PER IL TUO ANTICIPO TFS
              </Title>
              <Subtitle>
                <P>Banca Sistema, già specializzata nei prestiti contro cessione del quinto, ti offre un servizio finanziario nel rispetto della normativa applicabile.</P>
                <P style={{marginBottom: '0px'}}>Puoi ottenere il tuo TFS senza attendere i tempi di liquidazione del tuo ente pensionistico, beneficiando di una <strong>liquidità immediata che potrai usare per realizzare un grande progetto o anche semplicemente per far fronte ai bisogni della tua famiglia</strong>.</P>
              </Subtitle>
            </TextInnerContainer>
          </TextContainer>
        </Row>
      </InnerContainer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  margin-top: 3rem !important;
  display: block;
`
const InnerContainer = styled.div`
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  background: #fff;
  margin-right: 0;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
`
const ImgContainer = styled.div`
  padding-right: 0;
  padding-left: 0;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  position: relative;
  width: 100%;
`
const Img = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`
const TextContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  padding-right: 0;
  padding-left: 0;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
`
const TextInnerContainer = styled.div`
  @media (min-width: 993px){
    padding: 40px 100px;
  }
  padding: 30px;
`
const Title = styled.div`
  text-align: initial;
  font-size: 30px;
  color: #222;
  line-height: 36px;
  font-weight: bold;
  margin-bottom: 1.5rem;
`
const Span = styled.span`
  color: #FCBB00;
`
const Subtitle = styled.div`
  text-align: initial;
  font-size: 16px;
`
// endregion

export default ChooseBs
