import React from 'react'
import styled from 'styled-components'

const P = styled.p`
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.5rem;
  margin-bottom: 1em;
`

export default P
