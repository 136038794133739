import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import Collapsable from '../../commons/footer/Collapsable'
import P from '../../commons/P'


// region Testi FAQ
const collapsable1Title = 'Qual è la differenza tra cessione del quinto e prestito personale?'
const Collapsable1Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP>
        La differenza tra cessione del quinto e prestito personale riguarda principalmente 2 elementi: la <strong>modalità di rimborso</strong> e <strong>l’importo della rata</strong>.
      </CollapsableTextP>
      <CollapsableTextP>
        Nella cessione del quinto il pagamento della rata viene effettuato attraverso una <strong>trattenuta diretta</strong> dalla <strong>busta paga</strong> o dal <strong>cedolino della pensione</strong>.
        L’importo trattenuto non può essere superiore a un quinto dell’importo della busta paga o del cedolino.
      </CollapsableTextP>
      <CollapsableTextP style={{marginBottom: 0}}>
        Nel prestito personale invece la rata viene pagata attraverso una <strong>modalità prestabilita</strong> in <strong>fase di contratto</strong>,
        ad esempio un addebito diretto in conto corrente, e l’importo viene definito a seconda dell’entità del prestito e della sua durata.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable2Title = 'Qual è la durata del rimborso?'
const Collapsable2Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        La durata del rimborso viene stipulata in <strong>fase contrattuale</strong>, sempre considerando che la rata massima non può superare
        un quinto dello stipendio o della pensione. La <strong>durata massima</strong> di un piano di ammortamento con cessione del quinto è di <strong>10 anni</strong> (120 mesi),
        mentre la <strong>durata minima</strong> è di 2 anni (24 mesi).
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable3Title = 'Quali sono i costi?'
const Collapsable3Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        I costi della cessione del quinto dipendono da diversi fattori elencati nei fogli informativi, tra i quali spiccano il <strong>tasso di interesse</strong>,
        imposta di bollo, le <strong>spese di istruttoria</strong> e la <strong>polizza assicurativa obbligatoria</strong>.
        La polizza assicurativa è obbligatoria perché copre il rischio vita e il rischio impiego, oltre che tutelare chi concede il prestito
        da un’eventuale insolvenza. La sua entità è variabile e dipende dall’impiego, dall’età del richiedente e dall’anzianità di servizio.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable4Title = 'Cosa devi sapere prima di richiedere la cessione del quinto'
const Collapsable4Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP>Prima di richiedere la cessione del quinto è importante valutare alcuni aspetti determinanti. Eccoli nel dettaglio:</CollapsableTextP>
      <Ul style={{marginBottom: 0}}>
        <li>capire la <strong>propria capacità di reddito</strong> e comprendere se prelevare un quinto dello stipendio consente di ottemperare comunque alle spese di mantenimento; </li>
        <li><strong>valutare tutti i costi</strong>;</li>
        <li><strong>leggere attentamente il contratto e la documentazione precontrattuale</strong> e capire come funzionano alcune dinamiche come il recesso, l’estinzione anticipata e l’iter in caso di insolvenza.</li>
      </Ul>
    </CollapsableTextContainer>
  )
}

const collapsable5Title = 'Ho già un finanziamento in corso. Posso comunque fare richiesta di cessione del quinto?'
const Collapsable5Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        Sì, è possibile richiedere la cessione del quinto anche se vi sono già in essere altri finanziamenti in corso.
        In questo caso la concessione richiede un’attenta analisi del merito creditizio del cliente, per comprenderne l’effettiva solvibilità.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable6Title = "Cosa significa \"Delega di pagamento?\""
const Collapsable6Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP>
        La delega di pagamento è una forma di cessione che prevede di <strong>raddoppiare l’importo prelevato</strong> dallo <strong>stipendio</strong>.
        Se la cessione del quinto prevede di impegnare mensilmente il 20% dello stipendio, con la “delega di pagamento” o “doppio quinto” ne verrà impegnato il 40%.
      </CollapsableTextP>
      <CollapsableTextP style={{marginBottom: 0}}>
        Alla delega di pagamento possono accedere tutti coloro che hanno accesso alla cessione del quinto,
        ad accezione dei pensionati, sempre rispettando i criteri del merito creditizio del cliente.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable7Title = "Il datore di lavoro verrà informato della mia richiesta di cessione del quinto?"
const Collapsable7Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        Sì, il datore di lavoro viene a conoscenza della cessione del quinto del proprio dipendente. L’istituto bancario che eroga il finanziamento,
        infatti, lo deve contattare perché dal momento della sottoscrizione del contratto di cessione del quinto per il datore di lavoro
        scattano <strong>tre obblighi</strong>: <strong>trattenimento della rata</strong> dalla <strong>busta paga</strong>, <strong>versamento della rata</strong> all’<strong>Istituto bancario erogante </strong>
        e <strong>rispetto del vincolo</strong> apposto dall’<strong>ente erogante sul TFR</strong>, in caso di dimissioni o licenziamento.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable8Title = "Quando conviene la cessione del quinto?"
const Collapsable8Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP>La cessione del quinto conviene perché permette di accedere a somme di liquidità con il pagamento di una <strong>rata costante</strong> e contenuta rispetto ad altri finanziamenti. </CollapsableTextP>
      <CollapsableTextP>Nel dettaglio la cessione del quinto è consigliata nei seguenti casi:</CollapsableTextP>
      <Ul style={{marginBottom: 0}}>
        <li>quando il richiedente vuole ottenere <strong>liquidità elevate</strong>, anche fino a 75.000, dilazionando l’importo fino a 10 anni e mantenendo una rata non superiore a 1/5 dello stipendio. </li>
        <li>
          quando si è in possesso di un <strong>contratto di lavoro</strong> con assunzione a <strong>tempo indeterminato</strong> in aziende del settore pubblico/statale o private SPA/SRL con più di 15 dipendenti.
          Alla cessione del quinto possono inoltre accedere anche i pensionati con pensione di anzianità e reversibilità.
        </li>
      </Ul>
    </CollapsableTextContainer>
  )
}

const collapsable9Title = "Quando non viene accettata la cessione del quinto?"
const Collapsable9Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP>La cessione del quinto viene rifiutata quando l’istituto bancario erogante valuta le seguenti anomalie:</CollapsableTextP>
      <Ul style={{marginBottom: 0}}>
        <li><strong>valutazione assicurativa negativa</strong>: la compagnia assicurativa, dopo attenta valutazione, non ritiene che l’azienda datrice di lavoro sia solida;</li>
        <li>
          <strong>problematiche nella gestione societaria</strong>: viene rilevato che l’azienda datrice di lavoro presenta problematiche di gestione societaria
          come la presenza di procedimenti concorsuali quali l’amministrazione straordinaria, il concordato preventivo e il fallimento;
        </li>
        <li><strong>storico del datore di lavoro non adeguato</strong>: casistica nella quale il datore di lavoro non ha provveduto a versare regolarmente precedenti cessioni del quinto; </li>
        <li>
          <strong>mancanza di TFR a garanzia</strong>: alla firma della cessione del quinto viene costituito un vincolo sul TFR per tutta la durata del rapporto.
          Quando si rileva l’insufficienza del TFR a garanzia, la cessione del quinto può non essere finalizzata.
        </li>
        <li>
          <strong>contratto di lavoro non idoneo</strong>: si manifesta quando vi è un contratto di lavoro senza continuità operativa,
          come nel caso dei lavoratori stagionali (per esempio, nei settori turistici e di agricoltura).
        </li>
        <li><strong>politiche interne dell’istituto di credito</strong></li>
      </Ul>
    </CollapsableTextContainer>
  )
}
// endregion

const FAQs = () => {
  return (
    <FAQ>
      <Container>
        <InnerContainer>
          <StyledH2>
            FAQ CESSIONE DEL QUINTO
          </StyledH2>
          <CollapsableContainer>
            <CollapsableWrapper>
              <Collapsable title={collapsable1Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable1Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable2Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable2Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable3Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable3Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable4Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable4Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable5Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable5Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable6Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable6Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable7Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable7Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable8Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable8Text/>
              </Collapsable>
            </CollapsableWrapper>
            <Collapsable title={collapsable9Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
              <Collapsable9Text/>
            </Collapsable>
          </CollapsableContainer>
          <StyledP>
            (*) Per informazioni economiche e contrattuali fare riferimento alle “Informazioni Europee di Base sul
            Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte le filiali della Banca e presso gli agenti
            in attività finanziaria che operano su mandato diretto della Banca per la promozione e il collocamento del
            prodotto Anticipo TFS. La concessione del finanziamento è subordinata all’approvazione di Banca Sistema
            S.p.A..
          </StyledP>
        </InnerContainer>
      </Container>
    </FAQ>
  )
}

// region Style
const FAQ = styled.div`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  margin-top: 3rem;
  display: block;
`
const Container = styled.div`
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const InnerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledH2 = styled(H2)`
  text-align: center;
`
const CollapsableContainer = styled.div`
  margin-bottom: 3rem;
`
const CollapsableWrapper = styled.div`
  margin-bottom: 0.4rem;
`
const CollapsableTextContainer = styled.div`
  color: #222;
  font-size: 1.5rem;
  @media (max-width: 769px) {
    font-size: 1.2rem;
  }
`
const CollapsableTextP = styled(P)`
  font-size: inherit;
`
const StyledP = styled(P)`
  margin-bottom: 0;
  font-size: 11px;
  color: #222;
  text-align: center;
  line-height: 16px;
`
const Ul = styled.ul`
  list-style: disc;
  padding-left: 3rem;
  margin-bottom: 1em;
  font-size: inherit;
`
// endregion

export default FAQs
