import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import Bold from '../../commons/Bold'
import {useNavigate} from 'react-router-dom'

const Intro = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <StyledP>
        Banca Sistema è lieta di presentarvi <Bold>PrestitoPuoi</Bold>, il nuovo “nato” nella gamma dei nostri finanziamenti, per rispondere al meglio a tutte le esigenze di credito dei nostri clienti.
      </StyledP>
      <StyledP>
        <Bold>PrestitoPuoi</Bold> è un prestito personale non finalizzato, quindi liquidità pronto uso per realizzare in breve tempo qualsiasi progetto!
      </StyledP>
      <StyledP>
        Con <Bold>PrestitoPuoi</Bold> potrai decidere senza pensieri di arredare casa, acquistare una nuova auto o l’ultimo modello della tua moto preferita. Ma anche prenotare un viaggio o pianificare un grande evento della tua vita…un matrimonio, una cerimonia per la laurea di tuo figlio, un’occasione speciale. Oppure potrai anche gestire più comodamente le tue spese di famiglia, di natura medica o legale.
      </StyledP>
      <StyledP>
        Qualunque sia il tuo progetto noi possiamo finanziarlo fino a 30.000 euro.
        Cosa aspetti a contattarci?
      </StyledP>
      <LinkContainer>
        <LandingLink onClick={() => navigate('/landing-prestitopuoi')}>
          RICHIEDI QUI UNA PROPOSTA PERSONALIZZATA
        </LandingLink>
      </LinkContainer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  max-width: 1125px;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  margin: 7rem auto 0 auto;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`
const StyledP = styled(P)`
  text-align: left;
`
const LinkContainer = styled.div`
  margin-top: 4rem;
`
const LandingLink = styled.div`
  background-color: #222222;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0;
  cursor: pointer;
  position: relative;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.1s ease-out;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  padding: 1rem 3rem;
  border: none;
`
// endregion

export default Intro
