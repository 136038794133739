import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import Bold from '../../commons/Bold'
import icoDB from '../../../img/pp_database.png'
import icoPerson from '../../../img/pp_person.png'
import icoTablet from '../../../img/pp_tablet.png'
import icoMeter from '../../../img/pp_meter.png'
import icoLens from '../../../img/pp_lens.png'
import icoUmbrella from '../../../img/pp_umbrella.png'
import { useMediaQuery } from 'react-responsive'

interface ProductDescriptionProps {backgroundColor?: string}

const ProductDescription = ({backgroundColor}: ProductDescriptionProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <Container>
      <Grid backgroundColor={backgroundColor}>
        <Title isMobile={isMobile}>CARATTERISTICHE PRODOTTO</Title>
        <Title gridArea={isMobile ? 'cell4' : ''} isMobile={isMobile}>TIPOLOGIE DI CLIENTELA</Title>
        <Title gridArea={isMobile ? 'cell7' : ''} isMobile={isMobile}>SERVIZI e ASSICURAZIONI</Title>
        <GridElement isMobile={isMobile}>
          <Img isMobile={isMobile} src={icoDB} alt={'Icona di un database'}/>
          <Ul>
            <Li>Importi da 1.500 a 30.000 euro<sup>1</sup></Li>
            <Li>Durata: da 12 a 84 rate mensili<sup>1</sup></Li>
            <Li>Tasso di interesse fisso</Li>
            <Li>Spese di istruttoria: max 400 euro</Li>
            <Li>Rate costanti e predeterminate</Li>
          </Ul>
        </GridElement>
        <GridElement isMobile={isMobile}>
          <Img isMobile={isMobile} src={icoPerson} alt={'Icona di un utente'}/>
          <StyledP>
            <Bold>Consumatori Privati</Bold> (persone fisiche che agiscono per scopi strettamente personali). Possibilità di inserire coobbligato
          </StyledP>
        </GridElement>
        <GridElement isMobile={isMobile}>
          <Img isMobile={isMobile} src={icoTablet} alt={'Icona di un tablet'}/>
          <StyledP>
            <Bold>Firma digitale del contratto:</Bold><br/> sicurezza e velocità
          </StyledP>
        </GridElement>
        <GridElement isMobile={isMobile}>
          <Img isMobile={isMobile} src={icoMeter} alt={"Metro da sartoria"}/>
          <Ul>
            <Li>Soluzioni con opzione cambio/salto rata disponibili</Li>
            <Li>Non è prevista la fornitura di <Bold>garanzie reali</Bold></Li>
            <Li>Tempi brevi di erogazione, generalmente in 24/48 ore</Li>
          </Ul>
        </GridElement>
        <GridElement isMobile={isMobile}>
          <Img isMobile={isMobile} src={icoLens} alt={"Icona di una lente d'ingrandimento"}/>
          <div>
            <StyledP>
              <Bold>Requisiti di base:</Bold>
            </StyledP>
            <Ul>
              <Li>Età 18 - 75</Li>
              <Li>Residenza in Italia</Li>
              <Li>Reddito dimostrabile</Li>
              <Li>Titolare di conto corrente</Li>
            </Ul>
          </div>
        </GridElement>
        <GridElement isMobile={isMobile}>
          <Img isMobile={isMobile} src={icoUmbrella} alt={'Icona di un ombrello'}/>
          <StyledP>
            <Bold>Assicurazioni:</Bold><br/>
            disponibili coperture facoltative
          </StyledP>
        </GridElement>
      </Grid>
      <Footer>Nota 1. Per i lavoratori autonomi i limiti attuali sono ridotti a max 20.000 euro / 72 mesi</Footer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  max-width: 1125px;
  margin: auto;
`
const Grid = styled.div<{backgroundColor?: string}>`
  @media (max-width: 768px) {
    flex-direction: column;
    grid-template-rows: auto auto auto auto auto auto auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: "cell1" 
                         "cell2"
                         "cell3"
                         "cell4"
                         "cell5"
                         "cell6"
                         "cell7"
                         "cell8"
                         "cell9";
  }
  margin: 5rem 0 1rem 0;
  display: grid;
  grid-template-rows: 0.3fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "cell1 cell2 cell3"
                       "cell4 cell5 cell6";
  background-color: ${({backgroundColor}) => backgroundColor || '#E3E3E3'};
  border-radius: 33px;
  padding: 2rem;
`
const Title = styled(H2)<{gridArea?: string, isMobile: boolean}>`
  margin: ${({isMobile}) => isMobile ? '2rem 0' : ''};
  font-size: 2rem;
  grid-area: ${({gridArea}) => gridArea || 'unset'};
  color: #252523;
`
const GridElement = styled.div<{gridArea?: string, isMobile: boolean}>`
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: ${({isMobile}) => isMobile ? 'center' : 'flex-start'};
  justify-content: flex-start;
  place-self: ${({isMobile}) => isMobile ? 'center' : 'unset'};
  grid-area: ${({gridArea}) => gridArea || 'unset'};
`
const StyledP = styled.p`
  text-align: left;
`
const Ul = styled.ul`
  list-style-type:circle;
  padding-left: 1rem;
`
const Li = styled.li`
  text-align: left;
`
const Img = styled.img<{isMobile: boolean}>`
  width: ${({isMobile}) => isMobile ? '60px' : '70px'};
`
const Footer = styled(P)`
  padding: 0 1.5rem;
  text-align: left;
  font-size: 1.4rem;
`
// endregion

export default ProductDescription
