import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import Collapsable from '../../commons/footer/Collapsable'

interface FormCollapsableProps {
  innerTextBackgroundColor?: string
}

const CollapsableText = ({innerTextBackgroundColor}: FormCollapsableProps) => {
  return (
    <CollapsableTextContainer innerTextBackgroundColor={innerTextBackgroundColor}>
      <CollapsableInnerTextContainer>
        <CollapsableP>
          QuintoPuoi è un prodotto di Banca Sistema S.p.A.. Per informazioni economiche e contrattuali fare riferimento alle “Informazioni Europee di Base sul Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte le filiali della Banca e presso gli agenti in attività finanziaria che operano su mandato diretto della Banca per la promozione e il collocamento del prodotto QuintoPuoi. La concessione del finanziamento è subordinata all’approvazione di Banca Sistema S.p.A..
        </CollapsableP>
      </CollapsableInnerTextContainer>
    </CollapsableTextContainer>
  )
}

const FormCollapsable = ({innerTextBackgroundColor}: FormCollapsableProps) => {
 return (
   <Collapsable titleStyle={collapsableTitleStyle} headerStyle={collapsableHeaderStyle} replaceTextContainer={true} buttonWidth={'2.4rem'} buttonHeight={'2.4rem'} buttonIconSize={'1.5rem'}>
     <CollapsableText innerTextBackgroundColor={innerTextBackgroundColor}/>
   </Collapsable>
 )
}

// region Style
const collapsableHeaderStyle = {
  background: '#252525',
  minHeight: '4rem',
  padding: '1rem',
  fontWeight: 'unset'
}
const collapsableTitleStyle = {
  textTransform: 'uppercase',
  fontWeight: 'unset',
  fontSize: '1.3rem'
}
const CollapsableInnerTextContainer = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #F5F5F5;
  display: block;
`
const CollapsableTextContainer = styled.div<any>`
  ${props => props.innerTextBackgroundColor ? `background-color: ${props.innerTextBackgroundColor}` : 'background-color: white'};
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;
`
const CollapsableP = styled(P)`
  font-size: 1.2rem;
  margin-bottom: 0;
  background-color: white;
`
// endregion

export default FormCollapsable
