import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import Bold from '../../commons/Bold'
import arrow from '../../../img/right_arrow_broken.png'
import H2 from '../../commons/H2'

const Pluses = () => {
  return (
    <Container>
      <StyledP>
        Scopri anche tu i PLUS di <Bold>PrestitoPuoi</Bold>:
      </StyledP>
      <StagesContainer>
        <Stage>
          <StyledH2>Tasso fisso e rate</StyledH2>
          <StyledH2>predeterminate</StyledH2>
        </Stage>
        <Img src={arrow} alt={'Freccia verso destra'}/>
        <Stage>
          <StyledH2>Non vincolato ad uno</StyledH2>
          <StyledH2>specifico acquisto</StyledH2>
        </Stage>
        <Img src={arrow} alt={'Freccia verso destra'}/>
        <Stage>
          <StyledH2>Gestibile anche</StyledH2>
          <StyledH2>totalmente a distanza</StyledH2>
        </Stage>
      </StagesContainer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  max-width: 1125px;
  margin: 6rem auto 6rem auto;
  padding: 0 15px;
`
const StyledP = styled(P)`
  @media (max-width: 769px) {
    font-size: 2rem;
  }
  font-size: 3rem;
  text-align: left;
`
const StagesContainer = styled.div`
  @media (max-width: 480px) {
    flex-direction: column;
  }
  margin: 3rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const Stage = styled.div`
  width: 100%;
  background-image: linear-gradient(169deg, #f3b706 0%, #f29505 100%);
  color: white;
  border-radius: 10px;
  padding: 1rem 1rem;
  margin: 0 1rem;
`
const Img = styled.img`
  @media (max-width: 480px) {
    transform: rotate(90deg);
  }
  width: 5rem;
`
const StyledH2 = styled(H2)`
  margin: unset;
`
// endregion

export default Pluses
