import React from "react"
import styled from "styled-components"
import {useMediaQuery} from 'react-responsive'

interface TitleSectionProps {
  background?: string
  mobileBackground?: string
}

const TitleSection = ({background, mobileBackground}: TitleSectionProps) => {

  const isMobile = useMediaQuery({ query: '(max-width: 655px)' })

  return (
    <SectionContainer>
      <Img src={isMobile ? mobileBackground : background} alt={'prestitoPuoiJumbotron'}/>
    </SectionContainer>
  )
}

// region Style
const SectionContainer = styled.div`
  @media(max-width: 990px) {
    margin-top: 70px;
    margin-bottom: -3rem;
  }
  margin-top: 128px;
`
const Img = styled.img`
  width: 100%;
`
// endregion

export default TitleSection
