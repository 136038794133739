import React from 'react'
import styled from 'styled-components'
import Collapsable from '../../commons/footer/Collapsable'
import P from '../../commons/P'
import Highlighter from 'react-highlight-words'

const Collapsable1Text = ({keywords}: any) => {
  return (
    <>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'E’ una forma di finanziamento non finalizzato che rientra nel credito ai consumatori.'}/></StyledP>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Si rivolge ai lavoratori dipendenti (sia pubblici che privati) che si impegnano a restituire la somma avuta in prestito cedendo al finanziatore fino ad un quinto dello stipendio.'/></StyledP>
      <StyledP style={{marginBottom: 0}}><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='E’ un finanziamento a tasso di interesse fisso, che prevede un rimborso in un predefinito numero di rate, con cadenza mensile. Il rimborso delle rate è automatico, con trattenuta sulla busta paga da parte del proprio datore di lavoro.'/></StyledP>
    </>
  )
}
const Collapsable2Text = ({keywords}: any) => {
  return (
    <>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'E’ una forma di finanziamento non finalizzato che rientra nel credito ai consumatori.'}/></StyledP>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Si rivolge ai pensionati che si impegnano a restituire la somma avuta in prestito cedendo al finanziatore fino ad un quinto della pensione.'}/></StyledP>
      <StyledP/>
      <StyledP style={{marginBottom: 0}}>
        <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'E’ un finanziamento a tasso di interesse fisso, che prevede un rimborso in un predefinito numero di rate, generalmente con scadenza mensile. Il rimborso delle rate è automatico, con trattenuta sulla pensione da parte del proprio ente pensionistico.'}/>
      </StyledP>
    </>
  )
}
const Collapsable3Text = ({keywords}: any) => {
  return (
    <>
      <StyledP style={{marginBottom: 0}}>
        <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Possono richiedere tali finanziamenti solo ed esclusivamente i Lavoratori Dipendenti che presentino determinate caratteristiche in relazione alla categoria giuridica del Datore di Lavoro, all’anzianità di servizio maturata ed alla tipologia di contratto di lavoro subordinato.'}/>
      </StyledP>
    </>
  )
}
const Collapsable4Text = ({keywords}: any) => {
  return (
    <StyledP style={{marginBottom: 0}}>
      <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Il documento di identità ed il codice fiscale. Le ultime 2 buste paga/cedolino della pensione, il CUD ed il certificato di stipendio/attestato di servizio. Tali documenti sono necessari anche ai fini della formulazione del documento denominato “Informazioni Europee di Base sul Credito ai Consumatori” da parte della Banca. Nel corso dell’Istruttoria la Banca potrà richiedere ulteriore documentazione ritenuta necessaria.'}/>
    </StyledP>
  )
}
const Collapsable5Text = ({keywords}: any) => {
  return (
    <>
      <StyledP><strong><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Per la Cessione del Quinto dello Stipendio/Pensione'/></strong><Highlighter autoEscape={true} searchWords={keywords} textToHighlight=': la rata di rimborso è mensile. La rata viene trattenuta su ogni busta paga mensile/cedolino della pensione direttamente dal Datore di Lavoro/Ente Pensionistico che provvede a sua volta a riversarla alla Banca.'/></StyledP>
      <StyledP style={{marginBottom: 0}}><strong><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Per la Delegazione di pagamento'/></strong><Highlighter autoEscape={true} searchWords={keywords} textToHighlight=': la rata di rimborso è mensile. La rata viene trattenuta su ogni busta paga mensile direttamente dal Datore di Lavoro che provvede a sua volta a riversarla alla Banca in forza di un mandato irrevocabile conferito dal Cliente ai sensi dell’art. 1723 secondo comma del codice civile.'/></StyledP>
    </>
  )
}
const Collapsable6Text = ({keywords}: any) => {
  return (
    <StyledP style={{marginBottom: 0}}>
      <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'La Rata di rimborso non può essere superiore ad un quinto dello stipendio/pensione mensile netto ed il prestito dovrà avere una durata minima di 24 mesi e una durata massima di 120 mesi.'}/>
    </StyledP>
  )
}
const Collapsable7Text = ({keywords}: any) => {
  return (
    <StyledP style={{marginBottom: 0}}>
      <Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Il tasso di interesse è fisso per tutta la durata del prestito ed il piano di ammortamento è a rata costante '/><br/>
      <Highlighter autoEscape={true} searchWords={keywords} textToHighlight='(ammortamento alla francese).'/>
    </StyledP>
  )
}
const Collapsable8Text = ({keywords}: any) => {
  return (
    <>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Sono previste per legge le seguenti garanzie: '/></StyledP>
      <Ol>
        <li style={{fontSize: 'inherit'}}>
          <Highlighter autoEscape={true} searchWords={keywords} textToHighlight='La costituzione di un vincolo a favore della Banca sul TFR (Trattamento di Fine Rapporto ai sensi
          dell’articolo 2120 del codice civile e indennità similari) maturato e maturando dal Cliente presso il proprio Datore di Lavoro o Fondo Pensione.'/>
        </li>
      </Ol>
      <StyledP>
        <Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Il TFR è destinato a garantire il rimborso del prestito in caso di cessazione per qualsiasi causa del rapporto di lavoro tra Cliente e il Datore di Lavoro, nel corso del periodo di ammortamento del prestito. In forza del suddetto vincolo il Cliente non può avvalersi, per tutta la durata del prestito, del diritto di cui all’articolo 2120 comma 6° del codice civile (anticipazioni del TFR) se non per la quota eccedente l’importo del debito residuo del prestito.'/>
      </StyledP>
      <Ol start={2}>
        <li style={{fontSize: 'inherit'}}><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='La stipula di una polizza assicurativa a copertura del Rischio Impiego del Cliente (ad integrazione dell’eventuale TFR) ed un’altra a copertura del Rischio Vita.'/></li>
      </Ol>
      <StyledP style={{marginBottom: 0}}>
        <Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Il Finanziatore, all’atto di perfezionamento del contratto, stipulerà le polizze assicurative a copertura del Rischio Impiego e del Rischio Vita, di cui la Banca sarà contraente e beneficiaria, con oneri a proprio carico, sostenendo il pagamento del premio ossia la somma dovuta alla compagnia per la copertura assicurativa prestata.'/>
      </StyledP>
    </>
  )
}
const Collapsable9Text = ({keywords}: any) => {
  return (
    <>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='La polizza Rischio Impiego garantisce il mancato adempimento dell’obbligazione di rimborso del prestito da
        parte del Cliente in caso di risoluzione definitiva, per qualsiasi causa, del rapporto di lavoro tra il
        Cliente stesso ed il Datore di Lavoro.'/></StyledP>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='La polizza garantisce il rimborso della parte del debito residuo non coperta dall’eventuale TFR esistente
        al momento della cessazione del rapporto di lavoro.'/></StyledP>
      <StyledP style={{marginBottom: 0}}><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='Al verificarsi dell’evento oggetto di copertura, qualora la compagnia assicurativa provveda a
        liquidare l’indennizzo alla Banca, è previsto il diritto di rivalsa da parte della compagnia assicurativa
        nei confronti del Cliente per l’importo liquidato, pertanto la compagnia assicurativa rimane surrogata nei
        diritti, ragioni, azioni e privilegi del Finanziatore nei confronti del Cliente.'/></StyledP>
    </>
  )
}
const Collapsable10Text = ({keywords}: any) => {
  return (
    <>
      <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='La polizza Rischio Vita garantisce il mancato adempimento dell’obbligazione di rimborso del prestito da parte
        del Cliente in caso di decesso avvenuto prima della naturale scadenza del finanziamento.'/></StyledP>
      <StyledP style={{marginBottom: 0}}><Highlighter autoEscape={true} searchWords={keywords} textToHighlight='In caso di pagamento del sinistro la compagnia assicurativa non ha diritto alla rivalsa nei confronti degli
        eredi del Cliente di quanto liquidato alla Banca. A tal fine il Cliente è tenuto a rilasciare, in sede di
        istruttoria del prestito, apposite dichiarazioni sul suo stato di salute oltre al consenso ai sensi
        dell’art. 1919 del codice civile.'/></StyledP>
    </>
  )
}
const Collapsable12Text = ({keywords}: any) => {
  return (
    <StyledP style={{marginBottom: 0}}>
      <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Si, in qualsiasi momento'}/>
    </StyledP>
  )
}
const Collapsable13Text = ({keywords}: any) => {
  return (
    <StyledP style={{marginBottom: 0}}>
      <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Si, la Cessione del Quinto rientra nel settore del “credito ai consumatori”. Si applicano pertanto le norme riservate alle suddette forme di credito. La Cessione del Quinto dello Stipendio è, inoltre, regolata dalle norme di cui al DPR 5/1/1950 n. 180 e successive modifiche ed integrazioni, oltre alla relativa disciplina regolamentare.'}/>
    </StyledP>
  )
}
const Collapsable14Text = ({keywords}: any) => {
  return (
    <StyledP style={{marginBottom: 0}}>
      <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Si, la Delegazione di Pagamento rientra nel settore del “credito ai consumatori”. Si applicano pertanto alla Delegazione di Pagamento le norme riservate alle suddette forme di credito. La Delegazione di pagamento è disciplinata dagli articoli 1269 e 1723 del codice civile oltre che da specifiche disposizioni regolamentari emanate, con riferimento ai dipendenti pubblici e statali, dal Ministero dell’Economia e delle Finanze.'}/>
    </StyledP>
  )
}

const FAQs = ({keywords = []}: any) => {
  return (
    <div>
      <CollapsableContainer>
        <Collapsable
          title={'CHE COS’È LA CESSIONE DEL QUINTO DELLO STIPENDIO?'}
          lightTheme={true}
          keywords={keywords}
        >
          <Collapsable1Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'CHE COS’È LA CESSIONE DEL QUINTO DELLA PENSIONE?'}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable2Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'CHI PUÒ OTTENERE LA CESSIONE DEL QUINTO DELLO STIPENDIO / DELEGAZIONE DI PAGAMENTO?'}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable3Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'QUALI SONO I DOCUMENTI NECESSARI PER OTTENERE LA CESSIONE DEL QUINTO DELLO STIPENDIO / PENSIONE E DELEGAZIONE DI PAGAMENTO?'}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable4Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'COME AVVIENE IL RIMBORSO DEL PRESTITO?'}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable5Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'COME VIENE CALCOLATA LA RATA E LA DURATA DEL PRESTITO?'}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable6Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'IL TASSO DEL PRESTITO È FISSO O VARIABILE? '}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable7Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'QUALI SONO LE GARANZIE RICHIESTE?'}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable8Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'COSA GARANTISCE LA POLIZZA RISCHIO IMPIEGO? '}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable9Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'COSA GARANTISCE LA POLIZZA RISCHIO VITA? '}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable10Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'È POSSIBILE ESTINGUERE ANTICIPATAMENTE IL PRESTITO? '}
          lightTheme={true}
          keywords={keywords}>
          <Collapsable12Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <CollapsableContainer>
        <Collapsable
          title={'LA CESSIONE DEL QUINTO RIENTRA NEL CREDITO AI CONSUMATORI? '}
          lightTheme={true}
          keywords={keywords} >
          <Collapsable13Text keywords={keywords}/>
        </Collapsable>
      </CollapsableContainer>
      <Collapsable
        title={'LA DELEGAZIONE DI PAGAMENTO RIENTRA NEL CREDITO AI CONSUMATORI? '}
        lightTheme={true}
        keywords={keywords}>
        <Collapsable14Text keywords={keywords}/>
      </Collapsable>
    </div>
  )
}

// region Style
const simpleTextStyle = {
  fontWeight: 400,
  lineHeight: 1.5,
  fontSize: '1.5rem'
}
const CollapsableContainer = styled.div`
  margin-bottom: 0.4rem;
`
const StyledP = styled(P)`
  color: #222;
  @media (max-width: 769px) {
    font-size: 1.2rem;
  }
`
const Ol = styled.ol`
  color: #222;
  padding-left: 3rem;
  margin-bottom: 1em;
  list-style: decimal;
  @media (max-width: 769px) {
    font-size: 1.2rem;
  }
`
// endregion

export default FAQs
