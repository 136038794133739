import React, {useEffect} from 'react'
import styled from 'styled-components'
import headerBackground from '../../../img/prestitopuoi_header.jpg'
import mobileBackground from '../../../img/prestitopuoi_header_mobile.jpg'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import TitleSection from './TitleSection'
import Intro from './Intro'
import Pluses from './Pluses'
import ProductDescription from './ProductDescription'
import HowToRequire from './HowToRequire'
import UsefulDocuments from './UsefulDocuments'
import ChoosePrestitoPuoi from './ChoosePrestitoPuoi'
import { UtmsType } from '../../pages/landingGenerale/LandingGenerale'
import SEO from '../../commons/SEO'
import LinkToTrasparenza from './LinkToTrasparenza'

const seoTitle = 'PrestitoPuoi'
const seoDescription = 'PrestitoPuoi'

const PrestitoPuoi = () => {

  // Recupero utms da query string e salvo valori dell'utms in localStorage
  useEffect(()=> {
    const urlParamsLand = new URLSearchParams(window.location.search);
    if(urlParamsLand.has('utm_source')) {
      let utms: UtmsType = {}
      utms.utm_source = urlParamsLand.get('utm_source')
      utms.utm_medium = urlParamsLand.get('utm_medium')
      utms.utm_content = urlParamsLand.get('utm_content')
      utms.utm_campaign = urlParamsLand.get('utm_campaign')
      utms.utm_term = urlParamsLand.get('utm_term')
      localStorage.setItem('qp_utms',JSON.stringify(utms))
    }
  }, [])

  // Gestione dell'evento di conversione Google
  useEffect(() => {
    console.log('Handling PP landing conversion')
    //@ts-ignore
    const gtag = window['gtag']
    gtag && gtag('event', 'conversion_event_page_view_1', {
      // <event_parameters>
    })
  }, [])

  return (
    <>
      <SEO title={seoTitle} description={seoDescription}/>
      <Header/>
      <TitleSection background={headerBackground} mobileBackground={mobileBackground}/>
      <Main>
        <Intro/>
        <Pluses/>
        <ProductDescription/>
        <HowToRequire/>
        <UsefulDocuments/>
        <LinkToTrasparenza/>
        <ChoosePrestitoPuoi/>
      </Main>
      <Footer/>
    </>
  )
}

// region Style
const Main = styled.div`
  opacity: 1;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
// endregion

export default PrestitoPuoi
