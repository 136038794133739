import React, { useEffect } from 'react'
import './App.css'
import { reCaptchaPublicKey } from './config'
import {HelmetProvider} from 'react-helmet-async'
import { Route, Routes } from 'react-router-dom'
import Home from './components/pages/home/Home'
import AnticipoTfs from './components/pages/anticipoTfs/AnticipoTfs'
import Assistenza from './components/pages/assistenza/Assistenza'
import ChiSiamo from './components/pages/chiSiamo/ChiSiamo'
import Cookies from './components/pages/cookies/Cookies'
import EmergenzaCOVID19 from './components/pages/emergenzaCOVID19/EmergenzaCOVID19'
import FirmaDigitale from './components/pages/firmaDigitale/FirmaDigitale'
import LaNostraRete from './components/pages/laNostraRete/LaNostraRete'
import LandingPensionati from './components/pages/landingPensionati/LandingPensionati'
import LandingDipendentiPubblici from './components/pages/landingDipendentiPubblici/LandingDipendentiPubblici'
import LandingDipendentiPrivati from './components/pages/landingDipendentiPrivati/LandingDipendentiPrivati'
import LandingPrestitoPuoi from './components/pages/landingPrestitoPuoi/LandingPrestitoPuoi'
import LandingGenerale from './components/pages/landingGenerale/LandingGenerale'
import Privacy from './components/pages/privacy/Privacy'
import QuintoPuoi from './components/pages/quintoPuoi/QuintoPuoi'
import ThanksPrestitoPuoi from './components/pages/thanks/ThanksPrestitoPuoi'
import Thanks from './components/pages/thanks/Thanks'
import Trasparenza from './components/pages/trasparenza/Trasparenza'
import NotFound from './components/pages/NotFound'
import Preventivatore from './components/pages/preventivatore/Preventivatore'
import PreventivatoreTfs from './components/pages/preventivatoreTfs/PreventivatoreTfs'
import News from './components/pages/news/News'
import Articles from './components/pages/news/Articles'
import ArticlePage from './components/pages/news/ArticlePage'
import SEO from './components/commons/SEO'
//@ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3'
import CookieBot from 'react-cookiebot'
import Videos from './components/pages/news/Videos'
import PrestitoPuoi from './components/pages/prestitoPuoi/PrestitoPuoi'

const seoTitle = "Quinto Puoi - La cessione del quinto - SCOPRI QUINTO PUOI"
const seoDescription = "Quinto Puoi - La cessione del quinto - SCOPRI QUINTO PUOI"

function App() {
  useEffect(() => {
    ReactRecaptcha3.init(reCaptchaPublicKey).then((status: string) =>
      console.log('Google reCaptcha status is: ', status)
    )
  }, [])

  return (
    <div className="App">
      <CookieBot domainGroupId={'86c85263-80a0-49d3-b41d-d68c57854be3'} />
      <HelmetProvider context={{}}>
        <SEO title={seoTitle} description={seoDescription}/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/anticipo-tfs" element={<AnticipoTfs />} />
          <Route path="/assistenza" element={<Assistenza />} />
          <Route path="/chi-siamo" element={<ChiSiamo />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/emergenza-covid-19" element={<EmergenzaCOVID19 />} />
          <Route path="/firma-digitale" element={<FirmaDigitale />} />
          <Route path="/grazie" element={<Thanks />} />
          <Route path="/la-nostra-rete" element={<LaNostraRete />} />
          <Route path="/landing-dipendenti-privati" element={<LandingDipendentiPrivati />} />
          <Route path="/landing-dipendenti-privati/grazie" element={<Thanks />} />
          <Route path="/landing-dipendenti-pubblici" element={<LandingDipendentiPubblici />} />
          <Route path="/landing-dipendenti-pubblici/grazie" element={<Thanks />} />
          <Route path="/landing-generale" element={<LandingGenerale />} />
          <Route path="/landing-generale/grazie" element={<Thanks />} />
          <Route path="/landing-pensionati" element={<LandingPensionati />} />
          <Route path="/landing-pensionati/grazie" element={<Thanks />} />
          <Route path="/landing-prestitopuoi" element={<LandingPrestitoPuoi />} />
          <Route path="/landing-prestitopuoi/grazie" element={<ThanksPrestitoPuoi />} />
          <Route path="/news" element={<News />} />
          <Route path="news/articles" element={<Articles />} />
          <Route path="/news/articles/:id" element={<ArticlePage />} />
          <Route path="/news/video" element={<Videos />} />
          <Route path="/prestitopuoi" element={<PrestitoPuoi />} />
          <Route path="/preventivatore" element={<Preventivatore />} />
          <Route path="/preventivatore/conferma" element={<Preventivatore />} />
          <Route path="/preventivatore-tfs" element={<PreventivatoreTfs />} />
          <Route path="/preventivatore-tfs/grazie" element={<PreventivatoreTfs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/quinto-puoi" element={<QuintoPuoi />} />
          <Route path="/trasparenza" element={<Trasparenza />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HelmetProvider>
    </div>
  )
}

export default App
