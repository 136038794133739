// TODO: l'HTML va sanificato prima di essere mostrato ?
import React from 'react'

const CustomHTML = ({html, style}: any) => {
  return (
    <article style={style} dangerouslySetInnerHTML={{__html: html}}/>
  )
}

export default CustomHTML
