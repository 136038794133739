import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import TitleSection from '../../commons/TitleSection'
import mainBackground from '../../../img/bg_intro.jpg'
import headerBackground from '../../../img/TRASPARENZA.jpg'
import mobileHeaderBackground from '../../../img/trasparenza-mobile.jpg'
import P from '../../commons/P'
import Form from '../../commons/Form'
import H1 from '../../commons/H1'
import axios from 'axios'
import { baseUrl } from '../../../config'
import CustomHTML from '../../commons/CustomHTML'
import { useNavigate } from 'react-router-dom'
import FAQs from '../assistenza/FAQs'
import H2 from '../../commons/H2'

const Subtitle = () => {
  return (
    <b>
      Crediamo che la trasparenza sia l’asset fondante <br />
      di qualsiasi buona relazione
    </b>
  )
}
const FormText = () => {
  return (
    <>
      <StyledH1>
        Scopri la nostra cessione del quinto. <Em>Richiedi qui il tuo prestito!</Em>
      </StyledH1>
      <FormStyledP>
        Il finanziamento è rivolto a pensionati in convenzione INPS, lavoratori assunti a tempo indeterminato da aziende
        SpA/Srl con più di 15 dipendenti, dipendenti pubblici/statali/parapubblici
      </FormStyledP>
    </>
  )
}

const Trasparenza = () => {
  const [firstBlockHtml, setFirstBlockHtml] = useState('')
  const [secondBlockHtml, setSecondBlockHtml] = useState('')

  // Recupero il contenuto dinamico
  useEffect(() => {
    axios.get(`${baseUrl}/blocks/`).then((res) => {
      setFirstBlockHtml(res.data?.payload[0]?.content)
      setSecondBlockHtml(res.data?.payload[1]?.content)
    })
  }, [])

  const navigate = useNavigate()

  return (
    <>
      <Header />
      <TitleSection
        backgroundUrl={`url(${headerBackground})`}
        mobileBackgroundUrl={`url(${mobileHeaderBackground})`}
        title={'TRASPARENZA'}>
        <Subtitle />
      </TitleSection>
      <Main>
        <Form windowOffset={300}>
          <FormText />
        </Form>
        <Wrapper>
          <Container>
            <Row>
              <TextContainer>
                <StyledP>
                  In questa sezione sono contenuti i principali documenti di carattere informativo previsti dalla
                  normativa in relazione ai prodotti di Credito ai consumatori nei quali rientra il prodotto{' '}
                  <b>QuintoPuoi</b>.
                </StyledP>
                <StyledP>
                  <span>
                    In particolare, con le nuove regole previste dal decreto 141 del 2010 (che recepisce la direttiva
                    europea in materia di credito al consumo), volte a tutelare maggiormente i consumatori, ad aumentare
                    la trasparenza dei contratti e dei costi e ad informare correttamente il consumatore prima della
                    firma del contratto di finanziamento, è stato introdotto il Modello delle “Informazioni Europee di
                    base sul credito ai consumatori” in breve SECCI (dall’inglese Standard European consumer credit
                    information) che permette al consumatore, prima che questi sia vincolato da un contratto di credito
                    o da una proposta irrevocabile, di conoscere le informazioni necessarie per consentirgli il
                    confronto tra le diverse offerte di credito sul mercato, così che possa prendere una decisione
                    informata e consapevole in merito alla conclusione del contratto.&nbsp;
                  </span>
                </StyledP>
                <StyledP>
                  <span>
                    Il SECCI ha sostituito i precedenti documenti informativi previsti dal legislatore anche per tali
                    prodotti, quali: foglio informativo, documento di sintesi e principali diritti del cliente.&nbsp;
                  </span>
                </StyledP>
                <StyledP>
                  <span>
                    La Banca provvede all’assolvimento degli obblighi di trasparenza sopra descritti, ovvero alla
                    consegna del SECCI e degli ulteriori documenti pre-contrattuali (Guide, Informativa sui Tassi
                    Effettivi Globali Medi - TEGM), direttamente o per il tramite di intermediari del credito (agenti in
                    attività finanziaria) presenti sul territorio nazionale.&nbsp;
                  </span>
                </StyledP>
                <StyledP style={{ marginBottom: 0 }}>
                  <span>
                    Per ogni informazione puoi venire in Filiale, chiamarci al numero indicato alla sezione&nbsp;
                    <a
                      style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        navigate('/assistenza')
                      }}>
                      Assistenza Clienti
                    </a>
                    , o contattarci via e-mail o rivolgerti ad un nostro agente in attività finanziaria. Il nostro
                    personale è a tua completa disposizione per fornirti gratuitamente l’assistenza necessaria sulla
                    documentazione e sulle caratteristiche del prodotto.
                  </span>
                </StyledP>
              </TextContainer>
              <div style={{ width: '100%' }} className={'trasparenza-attachments-buttons-container'}>
                <CustomHTML style={buttonGridStyle} html={firstBlockHtml} />
              </div>
              <div className={'trasparenza-attachments-container'}>
                <CustomHTML html={secondBlockHtml} />
              </div>
            </Row>
          </Container>
        </Wrapper>
      </Main>
      <FAQsContainer>
        <StyledH2>
          FAQ
        </StyledH2>
        <FAQs/>
      </FAQsContainer>
      <Footer />
    </>
  )
}

const buttonGridStyle = {
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr 1fr'
}

// region Style
const Main = styled.div`
  background-image: url(${mainBackground});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const StyledH2 = styled(H2)`
  text-align: center;
`
const FAQsContainer = styled.div`
  max-width: 1140px;
  margin: auto;
  padding: 3rem 0;
`
const Wrapper = styled.div`
  padding-top: 4rem;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const TextContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  text-align: initial;
`
const StyledP = styled(P)`
  text-align: initial;
`
const StyledH1 = styled(H1)`
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const FormStyledP = styled(P)`
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
const Em = styled.em`
  font-style: normal;
  color: #fcbb00;
`
// endregion

export default Trasparenza
