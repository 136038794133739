import React from 'react'
import Link from '../Link'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const GlossaryLink = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <Link onClick={() => {navigate('/assistenza?go-to=glossary')}} text={'VAI AL GLOSSARIO'}/>
    </Container>
  )
}

const Container = styled.div`
  
`

export default GlossaryLink
