import React from 'react'
import styled from 'styled-components'

const H3 = styled.h3`
  font-size: 2rem;
  letter-spacing: .5px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.75em;
  padding: 0;
  border: 0;
`

export default H3
