import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import PreventivatoreTitleSection from '../../preventivatoreCommons/TitleSection'
import PreventivatoreForm from '../../preventivatoreCommons/PreventivatoreFrom'
import defaultHeaderBackground from '../../../img/PREVENTIVATORE_NEUTRO.jpg'
import publicHeaderBackground from '../../../img/PREVENTIVATORE_PUBBLICO.jpg'
import privateHeaderBackground from '../../../img/PREVENTIVATORE_PRIVATO.jpg'
import retiredHeaderBackground from '../../../img/PREVENTIVATORE_PENSIONATO.jpg'
import defaultMobileHeaderBackground from '../../../img/prev_pubblico-NEUTRO.jpg'
import publicMobileHeaderBackground from '../../../img/prev_pubblico-mobile.jpg'
import privateMobileHeaderBackground from '../../../img/prev_privato-mobile.jpg'
import retiredMobileHeaderBackground from '../../../img/prev_pensionato-mobile.jpg'
import bgUrl from '../../../img/bg_intro.jpg'
import ThankYouSection from './ThankYouSection'
import AbandonAlert from '../../commons/AbandonAlert'
import SEO from '../../commons/SEO'
import H2 from '../../commons/H2'
import P from '../../commons/P'

const Subtitle = () => {
  return(
    <strong>
      Compila tutti i campi del form. <br/>
      Verrai ricontattato dai nostri consulenti per avere una proposta personalizzata.
    </strong>
  )
}

const seoTitle = 'Preventivo cessione del quinto online | QuintoPuoi'
const seoDescription = 'Preventivo cessione del quinto: richiedi un preventivo online gratuito per il tuo prestito. Compila i campi del form per essere ricontattato.'

const Preventivatore = () => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [bgToShow, setBgToShow] = useState(defaultHeaderBackground)
  const [mobileBgToShow, setMobileBgToShow] = useState(defaultMobileHeaderBackground)

  const getBgToShow = () => {
    const employeeType = JSON.parse(localStorage.getItem('selectData') || '[]').find((data: any) => data.category === 'macroprofessione')
    if(employeeType) {
      if(employeeType.value === 'Pubblico-Statale') {
        setBgToShow(publicHeaderBackground)
        setMobileBgToShow(publicMobileHeaderBackground)
      } else if(employeeType.value === 'Privato') {
        setBgToShow(privateHeaderBackground)
        setMobileBgToShow(privateMobileHeaderBackground)
      } else if(employeeType.value === 'Pensionato') {
        setBgToShow(retiredHeaderBackground)
        setMobileBgToShow(retiredMobileHeaderBackground)
      }
    }
  }

  useEffect(() => {
    getBgToShow()
    if (window.location.href.includes('conferma')) {
      setShowConfirm(true)
    } else {
      setShowConfirm(false)
    }
  }, [])

  return (
    <>
      <SEO title={seoTitle} description={seoDescription}/>
      <AbandonAlert/>
      <Header/>
      <PreventivatoreTitleSection backgroundUrl={`url(${bgToShow})`} mobileBackgroundUrl={`url(${mobileBgToShow})`} title={'PREVENTIVATORE'}>
        <Subtitle/>
      </PreventivatoreTitleSection>
      <Main>
        {showConfirm ?
          <ThankYouSection/> :
          <>
            <PreventivatoreForm routeAfterSubmit={'/preventivatore/conferma'} lpType={'qppreventivatore'}/>
            <TextContainer>
              <StyledH2>
                COME RICHIEDERE UN PREVENTIVO PER LA CESSIONE DEL QUINTO
              </StyledH2>
              <StyledP>
                Richiedere un preventivo gratuito per la cessione del quinto è molto facile con Banca Sistema.
                Grazie al nostro <strong>preventivatore online</strong> è sufficiente inserire alcune informazioni per poi essere contattati dai nostri consulenti
                e ricevere un preventivo personalizzato creato in base alle tue esigenze.
              </StyledP>
              <StyledP>
                Come puoi vedere nel Form, la richiesta di un preventivo per la cessione del quinto prevede l’inserimento di alcuni campi obbligatori.
                Nel <strong>primo campo</strong> dovrai inserire le <strong>informazioni generali</strong>, vale a dire la specifica della tua posizione lavorativa
                (Sei dipendente pubblico? Privato? Sei pensionato?), l’<strong>importo desiderato per il tuo finanziamento</strong> e la <strong>durata</strong> che vorresti sostenere.
                Se lo desideri, anche se non obbligatoria, puoi inserire anche la <strong>finalità della tua richiesta</strong>, ovvero il progetto che vorresti sviluppare
                con la liquidità derivante dalla cessione del quinto.
              </StyledP>
              <StyledP>
                Nel <strong>secondo campo</strong>, invece, è obbligatorio inserire i tuoi <strong>dati personali</strong>, ovvero il tuo nome, cognome, l’anno di nascita,
                la città e la provincia di residenza, il tuo <strong>numero di telefono</strong> e la tua <strong>e-mail</strong>. Con questi dati saremo in grado di contattarti
                per proporti la migliore soluzione di finanziamento su misura per te.
              </StyledP>
            </TextContainer>
          </>
        }
      </Main>
      <Footer/>
    </>
  )
}

// region Style
const Main = styled.div`
  background-image: url(${bgUrl});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const TextContainer = styled.div`
  max-width: 1120px;
  padding: 0 1.5rem;
  margin: 9rem auto 0 auto;
`
const StyledH2 = styled(H2)`
  text-align: initial;
`
const StyledP = styled(P)`
  text-align: initial;
`
// endregion

export default Preventivatore
