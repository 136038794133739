import React, {useEffect} from 'react'
import styled from 'styled-components'
import AbandonAlert from '../../commons/AbandonAlert'
import LandingFooter from '../../landingCommons/LandingFooter'
import Preventivatore from './preventivatore/Preventivatore'
import Pluses from './Pluses'
import HowToRequire from './HowToRequire'
import headerBackground from '../../../img/landing_prestitopuoi_header.jpg'
import prestitopuoiLogo from '../../../img/prestitopuoi_logo_2rows.png'
import prestitopuoiLogoWhite from '../../../img/prestitopuoi_logo_2rows_white.png'
import bancaSistemaLogo from '../../../images/banca_sistema_new.svg'
import { useLocation } from 'react-router-dom'
import { UtmsType } from '../landingGenerale/LandingGenerale'
import ProductDescription from '../prestitoPuoi/ProductDescription'
import P from '../../commons/P'
import {useNavigate} from 'react-router-dom'

const FooterDisclaimerTextComponent = ({navigate}: any) => {
  return (
    <StyledP>
      Banca Sistema promuove e colloca il prodotto “PrestitoPuoi” in virtù del rapporto di collaborazione con Santander Consumer Bank S.p.A. , che è l’erogatore, senza vincoli di esclusiva e senza costi aggiuntivi per il Cliente.<br/>
      Per informazioni aggiuntive consultare la documentazione di trasparenza disponibile <Span onClick={() => navigate('/prestitopuoi')}>qui</Span>.
    </StyledP>
  )
}

const LandingPrestitoPuoi = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  useEffect(() => {
    //@ts-ignore
    const gtag = window['gtag']

    //TODO: scommentare con l'evento di conversione corretto
    //gtag && gtag('event', 'conversion', { send_to: 'AW-707945372/MPfUCMKp6KwBEJzHydEC' })
  }, [])

  // Recupero utms da query string e salvo valori dell'utms in localStorage
  useEffect(() => {
    const urlParamsLand = new URLSearchParams(window.location.search)
    if (urlParamsLand.has('utm_source')) {
      let utms: UtmsType = {}
      utms.utm_source = urlParamsLand.get('utm_source')
      utms.utm_medium = urlParamsLand.get('utm_medium')
      utms.utm_content = urlParamsLand.get('utm_content')
      utms.utm_campaign = urlParamsLand.get('utm_campaign')
      utms.utm_term = urlParamsLand.get('utm_term')
      localStorage.setItem('qp_utms', JSON.stringify(utms))
    }
  }, [])

  return (
    <Background>
      <AbandonAlert />
      <Header>
        <img src={prestitopuoiLogo} width={'200px'} alt={'Logo Prestitopuoi'}/>
        <img src={bancaSistemaLogo} width={'100px'} alt={'Logo di Banca Sistema'}/>
      </Header>
      <img src={headerBackground} width={'100%'} alt={'Immagine di background con persone che saltano: uomo con attrezzatura da trekking, coppia di imbianchini, uomo con dei pacchi in mano e una sposa'}/>
      <PageStart>
        <LandingMainContainer>
            <Preventivatore
              routeAfterSubmit={'/landing-prestitopuoi/grazie'}
              lpType={'Landing_PrestitoPuoi'}
              teadsConversionType={''}
            />
          <Pluses/>
          <ProductDescription backgroundColor={'white'}/>
          <HowToRequire/>
          <Disclaimer>
            Banca Sistema S.p.A. promuove e colloca il prodotto “PrestitoPuoi” in virtù del rapporto di collaborazione con Santander Consumer Bank S.p.A. , che è l’erogatore, senza vincoli di esclusiva e senza costi aggiuntivi per il Cliente.
          </Disclaimer>
        </LandingMainContainer>
      </PageStart>
      <LandingFooter logo={prestitopuoiLogoWhite} disclaimerTextComponent={<FooterDisclaimerTextComponent navigate={navigate}/>}/>
    </Background>
  )
}

// region Style
const PageStart = styled.div`
  padding-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`
const Background = styled.div`
  margin: auto;
  background: #FFB500;
`
const Header = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: white;
`
const LandingMainContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Disclaimer = styled(P)`
  padding: 0 0 1.5rem 1.5rem;
  text-align: left;
  font-size: 1.4rem;
  font-style: italic;
`
const Span = styled.span`
  color: #FCBB00;
  cursor: pointer;
  text-decoration: underline;
`
const StyledP = styled.p`
  font-weight: unset;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  line-height: 1.8;
`
// endregion

export default LandingPrestitoPuoi
