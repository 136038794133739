import React from "react"
import styled from "styled-components"
import Link from "../../commons/Link"
import { useNavigate } from "react-router-dom"

const LoanBox = () => {
  const navigate = useNavigate()

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "10% 80% 10%",
        margin: "50px 0"
      }}
    >
      <div></div>
      <Container>
        <h4
          style={{
            fontSize: "20px",
            color: "#222",
            letterSpacing: "0.3px",
            marginBottom: "20px"
          }}
        >
          Cessione del quinto: il prestito facile, veloce e sicuro riservato ai pensionati e ai lavoratori dipendenti
        </h4>
        <Link
          onClick={() => {
            navigate("/quinto-puoi")
          }}
          text={"Scopri la nostra cessione del quinto"}
          textStyle={{ textTransform: "uppercase" }}
        />
      </Container>
      <div></div>
    </div>
  )
}

export default LoanBox

const Container = styled.div`
  background: #fff;
  border-left: solid 5px #fcbb00;
  padding: 3rem 1.5rem;
  text-align: center;
`
