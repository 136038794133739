import React from 'react'
import styled from 'styled-components'
import bgfooter from '../../../img/bg_footer.jpg'
import H4 from '../H4'
import Link from '../Link'
import P from '../P'
import phoneIcon from '../../../img/ico_phone.svg'
import FooterLink from './FooterLink'
import agentIcon from '../../../img/ico_agent.svg'
import careerIcon from '../../../img/ico_career.svg'
import { useNavigate } from 'react-router-dom'

const LinksBlock = () => {
  const navigate = useNavigate()

  return (
    <OuterContainer>
      <InnerContainer>
        <Columns>
          <ReservedAreas>
            <StyledH4>AREE RISERVATE</StyledH4>
            <Label>CRM</Label>
            <Link link={'https://crmcq.quintopuoi.it'} text={'ACCEDI'} linkStyle={{ marginBottom: '2rem' }} />
            <Label>AREA DOCUMENTALE</Label>
            <Link link={'https://doc.quintopuoi.it/loginqp.php'} text={'ACCEDI'} linkStyle={{ marginBottom: '2rem' }} />
          </ReservedAreas>
          <UsefulLinks>
            <StyledH4>LINK UTILI</StyledH4>
            <Ul>
              <Li>
                <UsefulLinksText onClick={() => navigate('/privacy')}>PRIVACY</UsefulLinksText>
              </Li>
              <Li>
                <UsefulLinksText onClick={() => navigate('/cookies')}>COOKIES</UsefulLinksText>
              </Li>
              <Li>
                <UsefulLinksText onClick={() => navigate('/trasparenza')}>TRASPARENZA</UsefulLinksText>
              </Li>
              <Li>
                <UsefulLinksText onClick={() => navigate('/emergenza-covid-19')}>EMERGENZA COVID-19</UsefulLinksText>
              </Li>
              <Li>
                <Link link={"https://bancasistema.it/reclami"} target={'_blank'} text={'RECLAMI'} linkStyle={{ marginBottom: '2rem', minWidth: '2rem', minHeight: '1rem' }}>
                  RECLAMI
                </Link>
              </Li>
              <Li>
                <StyledA href={`${process.env.PUBLIC_URL}/uploads/Dichiarazione_di_accessibilita_sito_web_QuintoPuoi.pdf`} target="_blank">
                  ACCESSIBILITÀ
                </StyledA>
              </Li>
              {/* Scommentare quando sarà attiva sezione NEWS */}
              {/*  <Li>
                <UsefulLinksText onClick={() => navigate('/news')}>NEWS</UsefulLinksText>
              </Li> */}
            </Ul>
          </UsefulLinks>
          <GreenNumber>
            <StyledH4>NUMERO VERDE</StyledH4>
            <Link link={'tel:800124344'} text={'800 124 344'} linkStyle={phoneLinkStyle} />
            <br />
            <StyledP>ATTIVO DAL LUNEDÌ AL VENERDÌ
              <br/>
              DALLE ORE 9.00 ALLE ORE 13.00
              <br/>
              ASSISTENTE VIRTUALE 7/7 GIORNI 24H</StyledP>
            <StyledP>
              oppure scrivici a <a href="mailto:assistenzaclienti@quintopuoi.it">
                assistenzaclienti@quintopuoi.it
              </a>
            </StyledP>
          </GreenNumber>
          <WorkWithUs>
            <FooterLink
              link={'mailto:selezioneagenti@bancasistema.it'}
              title={'DIVENTA NOSTRO AGENTE'}
              linkStyle={{ backgroundImage: `url(${agentIcon})`, marginBottom: '2rem' }}
            />
            <FooterLink
              link={'http://bancasistema.it/lavora-con-noi'}
              title={'LAVORA CON NOI'}
              subtitle={'Energia ed entusiasmo sono le qualità che cerchiamo'}
              linkStyle={{ backgroundImage: `url(${careerIcon})` }}
            />
          </WorkWithUs>
        </Columns>
      </InnerContainer>
    </OuterContainer>
  )
}

// region Style
const phoneLinkStyle: React.CSSProperties = {
  backgroundImage: `url(${phoneIcon})`,
  paddingLeft: '4rem',
  backgroundPosition: '1.6rem center',
  backgroundRepeat: 'no-repeat',
  marginBottom: '0.25rem'
}
const OuterContainer = styled.div`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  padding-left: 1.10294rem;
  padding-right: 1.10294rem;

  padding-top: 10rem;
  padding-bottom: 5rem;
  background-image: url(${bgfooter});
  background-position: center top;
  background-size: cover;
  border-top: 1px solid #d8d8d8;
`
const InnerContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-right: -15px;
  margin-left: -15px;
`
const ReservedAreas = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 3rem !important;
  padding-right: 3rem !important;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 768px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  flex: 0 0 100%;
  max-width: 100%;
`
const Label = styled.label`
  margin-bottom: 0.6rem;
  line-height: 2.2;
  font-size: 1.2rem;
  font-weight: 700;
  //color: #aeaeae;
  color:#222;
  display: block;
`
const UsefulLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3rem;
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  color:#222;  
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 768px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
  flex: 0 0 100%;
`
const Ul = styled.ul`
  font-size: 1.2rem;
  font-weight: 700;
  color: #aeaeae;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  list-style: none;
`
const Li = styled.li`
  line-height: 2.2;
`
const StyledA = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #222;
`
const UsefulLinksText = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: #222;
`
const GreenNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 5rem;
  padding-bottom: 3rem;
  padding-left: 15px;
  p {
      color: #222;
  }

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 768px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  flex: 0 0 100%;
  max-width: 100%;
`
const StyledP = styled(P)`
  color: #767474;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8;
  display: block;
  text-align: initial;
  a {
    color: #222;
    :hover {
        color: #FCBB00;
    }
  }
`
const WorkWithUs = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem !important;
  max-width: 100%;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 768px) {
    max-width: 25%;
    flex: 0 0 25%;
  }
  flex: 0 0 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledH4 = styled(H4)`
  margin-bottom: 2rem;
`
// endregion

export default LinksBlock
