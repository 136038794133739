import React from 'react'
import styled from 'styled-components'
import prestitoPuoiChoose from '../../../img/prestitopuoi_middle_image.jpg'
import P from '../../commons/P'
import Bold from '../../commons/Bold'
import checkIcon from '../../../img/ico_check_brand.svg'


const HowToRequire = () => {
  return (
    <Container>
      <Title>
        Cosa serve per richiedere <Bold>PrestitoPuoi</Bold>:
      </Title>
      <Subtitle>Elenco esemplificativo e non esaustivo, le Società si riservano di richiedere ulteriore documentazione</Subtitle>
      <Grid>
        <SideImg src={prestitoPuoiChoose} alt={'Donna che corre con un trolley in una mano'}/>
        <GridElement>
          <Img src={checkIcon} alt={'Icona di una spunta gialla'}/>
          <StyledP>IBAN di un conto corrente intestato al richiedente (non sono ammesse carte prepagate)</StyledP>
        </GridElement>
        <GridElement>
          <Img src={checkIcon} alt={'Icona di una spunta gialla'}/>
          <StyledP>Documento di identità (patente/carta di identità )</StyledP>
        </GridElement>
        <GridElement>
          <Img src={checkIcon} alt={'Icona di una spunta gialla'}/>
          <StyledP>Codice fiscale (Tessera Sanitaria)</StyledP>
        </GridElement>
        <GridElement>
          <Img src={checkIcon} alt={'Icona di una spunta gialla'}/>
          <StyledP>Documento di reddito valido (busta paga / CUD / cedolino della pensione, etc.)</StyledP>
        </GridElement>
      </Grid>
      <Disclaimer>
        Banca Sistema S.p.A. promuove e colloca il prodotto “PrestitoPuoi” in virtù del rapporto di collaborazione con Santander Consumer Bank S.p.A. , che è l’erogatore, senza vincoli di esclusiva e senza costi aggiuntivi per il Cliente.
      </Disclaimer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  max-width: 1125px;
  margin: 7rem auto 7rem auto;
  padding: 0 1.5rem;
`
const Title = styled(P)`
  @media (max-width: 769px) {
    font-size: 2rem;
  }
  font-size: 3rem;
  text-align: left;
  margin-bottom: 0;
`
const Subtitle = styled.div`
  text-align: initial;
  margin-bottom: 3.5rem;
  font-style: italic;
`
const Grid = styled.div`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-left: 1.5rem;
  }
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto auto auto;
`
const SideImg = styled.img`
  @media (max-width: 480px) {
    display: none;
  }
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 5;
`
const Img = styled.img``
const GridElement = styled.div`
  @media (max-width: 480px) {
    padding-left: unset;
  }
  width: 100%;
  padding-left: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const StyledP = styled(P)`
  @media (min-width: 769px) {
    font-size: 1.8rem;
  }
  margin-bottom: unset;
  padding-left: 2rem;
  text-align: left;
`
const Disclaimer = styled(P)`
  margin-top: 3rem;
  text-align: initial;
  font-style: italic;
  font-size: 1.4rem;
`
// endregion

export default HowToRequire
