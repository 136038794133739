import React from 'react'
import styled from 'styled-components'
import { thumbnailUrl } from '../../../config'
import { Link } from 'react-router-dom'
import {IArticle} from '../../../types'

interface ArticleCardProps {
  article: IArticle
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  return (
    <>
      <ArticleContainer>
        <ThumbnailContainer>
          <Img src={`${thumbnailUrl}/${article.thumbnail}`} alt={article.title} />
        </ThumbnailContainer>
        <TextContainer>
          <TitleContainer>{article.title}</TitleContainer>
          <SubTitleContainer>{article.subtitle}</SubTitleContainer>
          <ButtonContainer>
            {article.id && (
              <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`${article.slug || article.id}`}>
                Vedi Articolo
              </Link>
            )}
          </ButtonContainer>
        </TextContainer>
      </ArticleContainer>
    </>
  )
}

export default ArticleCard

const Img = styled.img<any>`
  width: 100%;
  borderradius: 2rem;
  max-height: 187px;
  @media (max-width: 669px) and (min-width: 301px) {
    min-width: 332px;
  }
  @media (min-width: 300px) {
    min-width: 100%;
  }
`

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 4px 7px 11px -2px rgba(112, 112, 112, 0.62);
  @media (max-width: 300px) {
    max-width: 250px;
  }
  @media (min-width: 768px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 30%;
    max-width: 30%;
  }
`

const ThumbnailContainer = styled.div<any>`
  height: 40%;
  max-width: 100%;
  border-radius: 2rem 2rem 0 0;
`

const TextContainer = styled.div`
  height: 60%;
  padding: 0.5rem 2rem;
`
const TitleContainer = styled.h2`
  margin: 2rem 0;
  text-align: left;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SubTitleContainer = styled.h5`
  text-align: left;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ButtonContainer = styled.div`
  font-weight: 700;
  margin: 2rem auto;
  padding: 0.5rem 0;
  cursor: pointer;
  width: 10rem;
  border-width: 1px;
  color: #222222;
  border-color: #18ab29;
  border-radius: 28px;
  background-color: rgb(252, 187, 0);
  box-shadow: 5px 5px 10px 2px rgba(89, 89, 89, 0.39);
  &:active {
    transform: translateY(1px);
  }
`
