import React from 'react'
import styled from 'styled-components'
import Link from '../commons/Link'
import bsLogo from '../../images/banca_sistema_white_new.png'
import qpLogo from '../../img/logo.svg'
import phoneIcon from '../../img/phone_small.svg'
import Collapsable from '../commons/footer/Collapsable'
import { useNavigate } from 'react-router-dom'

interface LandingFooterProps {
  logo?: any,
  disclaimerText?: string
  disclaimerTextComponent?: React.ReactComponentElement<any>
}

const LandingFooter: React.FC<LandingFooterProps> = ({logo = null, disclaimerText, disclaimerTextComponent}) => {
  const navigate = useNavigate()

  return (
    <FooterContainer>
      <FooterInnerContainer>
        <Container>
          <FirstRow>
            <BankLogoContainer>
              <Img src={bsLogo} alt={'Banca Sistema logo'}/>
            </BankLogoContainer>
            <AddressContainer>
              <Title>SEDE LEGALE</Title>
              <WhiteTextContainer>
                Largo Augusto 1A, angolo Via Verziere 13 - 20122 Milano
                <br />
                <a href={'https://www.bancasistema.it'} target={'_blank'} style={{color: 'white'}}>www.bancasistema.it</a>
              </WhiteTextContainer>
              <LegalInfo>
                P.IVA 12870770158
                <br />
                2011-2024 © Banca Sistema S.p.A.
              </LegalInfo>
            </AddressContainer>
            <QPLogoContainer>
              <Img src={logo || qpLogo} alt={'Quinto Puoi logo'}/>
            </QPLogoContainer>
            <GreenNumberContainer>
              <Title>NUMERO VERDE</Title>
              <Link text={'800 124 344'} link={'tel:800124344'} linkStyle={phoneLinkStyle} />
              <WhiteTextContainer style={{ marginTop: '1rem' }}>
                ATTIVO DAL LUNEDÌ AL VENERDÌ
                <br/>
                DALLE ORE 9.00 ALLE ORE 13.00
                <br />
                ASSISTENTE VIRTUALE 7/7 GIORNI 24H
              </WhiteTextContainer>
            </GreenNumberContainer>
          </FirstRow>
          <SecondRow>
            <CollapsableContainer>
              <Collapsable textStyle={collapsableTextStyle} text={disclaimerText} children={disclaimerTextComponent}/>
            </CollapsableContainer>
          </SecondRow>
          <ThirdRow>
            <LinksContainer>
              <LastLink onClick={() => navigate('/privacy')}>PRIVACY</LastLink>&nbsp;
              <LastLink onClick={() => navigate('/cookies')}>COOKIES</LastLink>&nbsp;
              <LastLink onClick={() => navigate('/trasparenza')}>TRASPARENZA</LastLink>&nbsp;
              <StyledA href="https://bancasistema.it/reclami" target="_blank">
                RECLAMI
              </StyledA>
              &nbsp;
              <LastLink onClick={() => navigate('/emergenza-covid-19')}>EMERGENZA COVID-19</LastLink>
              {/* Da scommentare quando sarà attiva sezione NEWS */}
              {/*  <LastLink onClick={() => navigate('/news')}>NEWS</LastLink> */}
            </LinksContainer>
          </ThirdRow>
        </Container>
      </FooterInnerContainer>
    </FooterContainer>
  )
}

// region Style
const collapsableTextStyle = {
  fontWeight: 'unset',
  fontSize: '1.5rem',
  color: '#FFF'
}
const phoneLinkStyle: React.CSSProperties = {
  backgroundImage: `url(${phoneIcon})`,
  paddingLeft: '4rem',
  backgroundPosition: '1.6rem center',
  backgroundRepeat: 'no-repeat',
  minWidth: 'unset'
}

const FooterContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 3rem !important;
  display: block;
`
const FooterInnerContainer = styled.div`
  background-color: #222;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  margin-top: 1rem !important;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const FirstRow = styled.div`
  text-align: initial;
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SecondRow = styled.div`
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const CollapsableContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const ThirdRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const BankLogoContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Img = styled.img`
  max-width: 100%;
  height: auto;
  border-style: none;
`
const AddressContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Title = styled.div`
  color: #ffb800 !important;
  font-weight: 700;
  margin-bottom: 10px;
`
const WhiteTextContainer = styled.div`
  color: #fff;
`
const LegalInfo = styled.div`
  color: #cccccc;
  margin-top: 1rem !important;
`
const QPLogoContainer = styled.div`
  @media (min-width: 992px) {
    order: 4;
  }
  @media (min-width: 992px) {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const GreenNumberContainer = styled.div`
  @media (min-width: 992px) {
    order: 3;
  }
  @media (min-width: 992px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const LinksContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  padding: 20px;
  text-align: center;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const LastLink = styled.div`
  cursor: pointer;
  display: inline-block;
  margin: 0 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
`
const StyledA = styled.a`
  cursor: pointer;
  display: inline-block;
  margin: 0 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
`
// endregion

export default LandingFooter
