import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import H4 from '../H4'
import P from '../P'
import plusIcon from '../../../img/sym_plus.svg'
import minusIcon from '../../../img/sym_minus.svg'
import { useSearchParams } from 'react-router-dom'
import Highlighter from 'react-highlight-words'

interface CollapsableProps {
  title?: string
  text?: string
  textStyle?: React.CSSProperties
  titleStyle?: any
  headerStyle?: React.CSSProperties
  lightTheme?: boolean
  replaceTextContainer?: boolean
  children?: any
  buttonHeight?: string
  buttonWidth?: string
  buttonIconSize?: string;
  keywords?: string[]
}

const defaultTitle = 'Messaggio pubblicitario con finalità promozionale'
const defaultText = 'QuintoPuoi è un prodotto di Banca Sistema S.p.A.. Per informazioni economiche e contrattuali fare riferimento alle “Informazioni Europee di Base sul Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte le filiali della Banca e presso gli agenti in attività finanziaria che operano su mandato diretto della Banca per la promozione e il collocamento del prodotto QuintoPuoi. La concessione del finanziamento è subordinata all’approvazione di Banca Sistema S.p.A..'

const Collapsable = ({title, text, textStyle = {}, headerStyle = {}, titleStyle = {}, lightTheme = false, replaceTextContainer = false, children, buttonWidth, buttonHeight, buttonIconSize, keywords = []}: CollapsableProps) => {

  const [showContent, setShowContent] = useState(false)
  const [urlSearchParams] = useSearchParams()

  useEffect(() => {
    keywords = keywords.filter(k => !!k)
    if (!keywords.length) return

    keywords.forEach(k => {
      const search = k.toLowerCase()
      if ((title || defaultTitle).toLowerCase().includes(search) || (text || defaultText).toLowerCase().includes(search)) {
        setShowContent(true)
      }
    })
  }, [urlSearchParams, text, title])

  const messageButtonOnClick = (e: any) => {
    e.preventDefault()
    setShowContent(!showContent)
  }

  return (
    <Container lightTheme={lightTheme}>
      <Header style={headerStyle}>
        <MessageButton onClick={messageButtonOnClick}>
          <StyledH4 lightTheme={lightTheme} style={titleStyle}>
            <Highlighter autoEscape={true} searchWords={(urlSearchParams.get('ricerca') || '').trim().split(' ')} textToHighlight={title || defaultTitle} />
          </StyledH4>
          <Icon style={showContent ? minusIconStyle : plusIconStyle} buttonWidth={buttonWidth} buttonHeight={buttonHeight} buttonIconSize={buttonIconSize}/>
        </MessageButton>
      </Header>
      {showContent &&
        (
          replaceTextContainer ? children :
            <PromoMessageContainer>
              <PromoMessage lightTheme={lightTheme}>
                <div>
                  { children ? children :
                    <StyledP style={textStyle} lightTheme={lightTheme}>
                      {keywords ?
                        <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={text || defaultText}/>
                        : (text || defaultText) }
                    </StyledP>
                  }
                </div>
              </PromoMessage>
            </PromoMessageContainer>
        )
      }
    </Container>
  )
}

// region Style
const plusIconStyle = {
  borderRadius: '50%',
  backgroundColor: '#FCBB00',
  backgroundImage: `url(${plusIcon})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat'
}
const minusIconStyle = {
  borderRadius: '50%',
  backgroundColor: '#D8D8D8',
  backgroundImage: `url(${minusIcon})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat'
}

const Container = styled.div<CollapsableProps>`
  background-color: ${props => props.lightTheme ? '#FFF' : '#2F2F2F'};
  width: 100%;
  border-left: 5px solid #FCBB00;
  text-align: left;
`
const Header = styled.div`
  min-height: 7rem;
  padding: 1.5rem 1.5rem 1.5rem 4rem;
  display: flex;
`
const MessageButton = styled.button`
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 7rem;
  background: transparent;
  border: none;
  padding: 0;
`
const StyledH4 = styled(H4)<CollapsableProps>`
  color: ${props => props.lightTheme ? '#222' : '#FFF'};
  margin-bottom: 0;
`
const PromoMessageContainer = styled.div`
  padding-left: 4rem;
  padding-right: 7rem;
  overflow: hidden;
}
`
const PromoMessage = styled.div<CollapsableProps>`
  display: block;
  background-color: ${props => props.lightTheme ? '#F5F5F5' : '#505050'};
  color: #F5F5F5;
  padding: 1.5rem 3rem;
  margin-bottom: 1.5rem;
`
const StyledP = styled(P)<CollapsableProps>`
  color: ${props => props.lightTheme ? '#222' : '#FFF'};
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8;
  display: block;
`
const Icon = styled.div<any>`
  flex-shrink: 0;
  ${props => props.buttonWidth ? `width: ${props.buttonWidth}` : 'width: 4rem'};
  ${props => props.buttonHeight ? `height: ${props.buttonHeight}` : 'height: 4rem'};
  ${props => props.buttonIconSize ? `background-size: ${props.buttonIconSize}` : ''};
`
// endregion

export default Collapsable
