import React from "react";
import styled from "styled-components";
import BgHomeSection from "../../../images/bg_home_section.jpg";
import Simple from "../../../images/ico_v_simple.svg";
import Fast from "../../../images/ico_v_fast.svg";
import Safe from "../../../images/ico_v_safe.svg";
import Inno from "../../../images/ico_v_inno.svg";

const IntroDocument = () => {
  return (
    <ContainerIntro>
      <Container>
        <h2>TRASFORMIAMO IL TUO QUINTO IN UN FINANZIAMENTO SU MISURA PER TE</h2>
        <Row>
          <Col>
            <p>
              Banca Sistema eroga il servizio finanziario dedicato a te: il
              prestito per dipendenti pubblici e privati e per i pensionati,
              rimborsabile attraverso la cessione del quinto dello stipendio o
              della pensione.
            </p>
            <p>
              QuintoPuoi è comodo e sicuro, con piccole rate trattenute
              direttamente sullo stipendio o sulla pensione potrai ottenere
              subito il finanziamento richiesto.
            </p>
          </Col>
        </Row>
        <Row style={{ marginLeft: "0", marginRight: "0" }}>
          <ColForIcon>
            <VFeat>
              <VFeatInner>
                <img src={Simple} alt="Mano che schiocca le dita" width="110%" />
              </VFeatInner>
            </VFeat>
            <VFeatText>
              <h3>SEMPLICE</h3>
              <p>Facilità nella compilazione della richiesta del prestito</p>
            </VFeatText>
          </ColForIcon>
          <ColForIcon>
            <VFeat>
              <VFeatInner>
                <img src={Fast} alt="Icona di un cronometro" width="110%" />
              </VFeatInner>
            </VFeat>
            <VFeatText>
              <h3>VELOCE</h3>
              <p>Rapidità dell'erogazione del prestito</p>
            </VFeatText>
          </ColForIcon>
          <ColForIcon>
            <VFeat>
              <VFeatInner>
                <img src={Safe} alt="Icona di un lucchetto" width="110%" />
              </VFeatInner>
            </VFeat>
            <VFeatText>
              <h3>SICURO</h3>
              <p>Finanziamento protetto da polizza assicurativa</p>
            </VFeatText>
          </ColForIcon>
          <ColForIcon>
            <VFeat>
              <VFeatInner>
                <img src={Inno} alt="Icona di una lampadina" width="110%" />
              </VFeatInner>
            </VFeat>
            <VFeatText>
              <h3>INNOVATIVO</h3>
              <p>
                Estinzione anticipata e recupero degli interessi non maturati
              </p>
            </VFeatText>
          </ColForIcon>
        </Row>
      </Container>
    </ContainerIntro>
  );
};

export default IntroDocument;

const ContainerIntro = styled.div`
  background-image: url(${BgHomeSection});
  padding-top: 9rem;
  background-position: center center;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  @media (max-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 992px) {
    text-align: center;
  }
  & h2 {
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0.75em;
    font-size: 3rem;
  }
`;

const Row = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

const Col = styled.div`
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  & p {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
`;

const ColForIcon = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 0;
  padding-left: 0;
  @media (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

const VFeat = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 150%;
`;

const VFeatInner = styled.div`
  width: 145%;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 992px) {
    width: 133%;
  }
`;

const VFeatText = styled.div`
  margin-left: 1rem;
    & h3 {
    font-weight: 700;
    margin-bottom: 0.75em;

    color: inherit;
    background-color: #FCBB00;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-out;
    font-size: 1.75rem;
    border-radius: 2.3rem;
    min-height: 4.6rem;
    padding: 1rem 2rem 1rem 2rem;
    letter-spacing: 1px;
    flex: auto;    
  }
  & p {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
  & p:last-child {
    margin-bottom: 0;
  }
`;
