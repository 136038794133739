import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import H1 from '../../commons/H1'
import checkIcon from '../../../img/ico_check_brand.svg'
import buttonIcon from '../../../img/phone_small_yell.svg'

const ThankYouSection = () => {
  return (
    <Section>
      <Container>
        <div>
          <StyledImg src={checkIcon} alt={'Spunta gialla'}/>
          <StyledH1>Grazie</StyledH1>
          <StyledP>Verrai ricontattato dal team QuintoPuoi di Banca Sistema per una proposta di finanziamento su misura per te!</StyledP>
          <StyledP>
            Se preferisci parlare subito con un nostro consulente, contattaci al numero verde.<br/>
            Saremo lieti di rispondere alle tue esigenze!
          </StyledP>
          <StyledP>
            <A href={'tel:800124344'}>800 124 344</A>
          </StyledP>
          <StyledP style={{marginBottom: 0}}>
            Numero verde attivo dal lunedì al venerdì dalle ore 8.30 alle ore 18.00
          </StyledP>
        </div>
      </Container>
    </Section>
  )
}

// region Style
const Section = styled.div`
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 70%);
  padding-top: 1rem;
  margin: 0 auto;
  margin-top: -16.4rem;
  min-height: 16rem;
  background-color: rgba(34, 34, 34, 0.85);
  position: relative;
  max-width: 114rem;
  z-index: 30;
  border-radius: 4.5rem;
`
const Container = styled.div`
  background-color: #FFF;
  border-radius: 4.5rem;
  height: 44rem;
  padding: 5rem;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledImg = styled.img`
  margin-bottom: 1.5rem;
  width: 5rem;
  border-style: none;
  max-width: 100%;
`
const StyledH1 = styled(H1)`
  padding-bottom: 0.5rem !important;
`
const StyledP = styled(P)`
  color: #767474;
  padding-bottom: 0.5rem !important;
`
const A = styled.a`
  color: #EBB941;
  background-image: url(${buttonIcon});
  padding: 1rem 3rem;
  padding-left: 4rem;
  background-position: 1.6rem center;
  background-repeat: no-repeat;
  background-color: #222;
  position: relative;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.1s ease-out;
  font-size: 1.3rem;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  letter-spacing: 1px;
`
// endregion

export default ThankYouSection
