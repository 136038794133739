import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import TopSliceImg from '../../images/slice_hero_top.svg'
import RightSliceImg from '../../images/slice_hero_right.svg'
import LeftSliceImg from '../../images/slice_hero_left.svg'
import Hero1 from '../../images/hero1.jpg'
import Hero2 from '../../images/hero2.jpg'
import Hero3 from '../../images/hero3.png'

const Hero = () => {
  const heroes = [Hero3, Hero2, Hero1]
  const [currentBgHeroIndex, setCurrentBgHeroIndex] = useState(0)
  const [showSlices, setShowSlices] = useState(false)

  const changeBg = () => {
    setCurrentBgHeroIndex((prev) => {
      if (prev + 1 >= heroes.length) return 0
      return prev + 1
    })
  }

  useEffect(() => {
    setShowSlices(true)
    const intervalId = setTimeout(() => {
      changeBg()
      setShowSlices(false)
    }, 4000)
    return () => clearTimeout(intervalId)
  }, [currentBgHeroIndex])

  return (
    <Section>
      <Wrapper>
        <HeroContainer>
          <HeroItem
            style={{
              backgroundImage: `url(${heroes[currentBgHeroIndex]})`,
              transition: 'background-image 0.7s ease'
            }}>
            {showSlices && <SliceLeft />}
            {showSlices && <SliceTop />}
            {showSlices && <SliceRight />}
            <HeroText>
              <div>
                {
                  currentBgHeroIndex === 2 &&
                  <HeroCaption>
                    <p>
                      Ricevi da subito il tuo
                      <br />
                      <strong>trattamento di fine servizio</strong>
                    </p>
                  </HeroCaption>
                }
                {
                  currentBgHeroIndex === 1 &&
                  <HeroCaption>
                    <p>
                      La{' '}
                      <strong>
                        cessione del <br />
                        quinto{' '}
                      </strong>
                      comodamente <br />
                      da casa tua
                    </p>
                  </HeroCaption>
                }
                {
                  currentBgHeroIndex === 0 &&
                  <HeroCaption>
                    <p>
                      DAI VITA AI TUOI <strong>PROGETTI</strong><br/>
                      REALIZZALI ADESSO
                    </p>
                  </HeroCaption>
                }
              </div>
            </HeroText>
          </HeroItem>
        </HeroContainer>
      </Wrapper>
    </Section>
  )
}

export default Hero

const appearFromTop = keyframes`
  from {
    transform: translateX(-62%) translateY(-100%);
  }
  to {
    transform: translateX(-62%) translateY(0);
  }
`

const appearFromLeft = keyframes`
  from {
    transform: translateX(-100%) translateY(-48%);
  }
  to {
    transform: translateX(0) translateY(-48%);
  }
`

const appearFromRight = keyframes`
  from {
    transform: translateX(100%) translateY(-48%);
  }
  to {
    transform: translateX(0) translateY(-48%);
  }
`
const Section = styled.section`
  position: relative;
  display: block;
  width: 100%;
  z-index: 1;
  min-height: 33rem;
  transform-origin: center;
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
`

const HeroContainer = styled.div`
  width: 100%;
`

const HeroItem = styled.div`
  height: 100%;
  background-position: 80% center;
  background-size: cover;
  transition: all 0.3s ease-out;
  min-height: 70rem;
  position: relative;
  @media (min-width: 769px) {
    background-position: center center;
  }
`

const HeroTextContainer = styled.div`
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 111rem;
  text-align: center;
  text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
`

const SliceTop = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  max-width: 56rem;
  padding-bottom: 58rem;
  width: 50%;
  transition: all 0.24s ease-out;
  opacity: 0.8;
  z-index: 1;
  background-image: url(${TopSliceImg});
  background-position: center top;
  top: 0;
  left: 50%;
  animation: ${appearFromTop} 0.5s ease;
  transform: translateX(-62%);
`

const SliceLeft = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  max-width: 22rem;
  padding-bottom: 58rem;
  width: 15%;
  transition: all 0.24s ease-out;
  opacity: 0.8;
  top: 50%;
  z-index: 1;
  left: 0;
  background-image: url(${LeftSliceImg});
  background-position: left center;
  animation: ${appearFromLeft} 0.7s ease;
  transform: translateY(-48%);
`
const SliceRight = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  max-width: 22rem;
  padding-bottom: 58rem;
  width: 15%;
  transition: all 0.24s ease-out;
  opacity: 0.8;
  top: 50%;
  z-index: 1;

  right: 0;
  background-image: url(${RightSliceImg});
  background-position: right center;
  animation: ${appearFromRight} 0.7s ease;
  transform: translateY(-48%);
`

const HeroText = styled.div`
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 111rem;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
`

const HeroCaption = styled.div`
  margin-bottom: 2rem;
  & p {
    @media (max-width: 1140px) {
      font-size: 4rem;
    }
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
    font-size: 6rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 2px;
    line-height: 1.1;
    font-weight: 700;
    & :last-child {
      margin-bottom: 0;
    }
    & strong {
      color: #fcbb00;
    }
  }
`

/*



  <TopSlice>
            <img
              src={TopSliceImg}
              alt="topSlice"
              style={{ position: "absolute", top: "0", left: "35%" }}
            />
          </TopSlice>
          <LeftSlice>
            <img
              src={LeftSliceImg}
              alt="leftSlice"
              style={{ position: "absolute", top: "0", left: "0" }}
            />
          </LeftSlice>
          <RightSlice>
            <img
              src={RightSliceImg}
              alt="rightSlice"
              style={{ position: "absolute", bottom: "0", right: "0" }}
            />
          </RightSlice>
          <HeroTextContainer>
            {currentBgHero === Hero1 ? (
              <h2
                style={{
                  textTransform: "uppercase",
                  color: "#fff",
                  fontSize: "2.8em",
                  textShadow: "-1px 2px 5px #9C402F",
                }}
              >
                Ricevi subito il tuo
                <br />
                <span
                  style={{
                    color: "#fcbb00",
                    fontSize: "inherit",
                    textShadow: "inherit",
                  }}
                >
                  trattamento di fine servizio
                </span>
              </h2>
            ) : (
              <h2
                style={{
                  textTransform: "uppercase",
                  color: "#fff",
                  fontSize: "2.8em",
                  textShadow: "-1px 2px 5px #9C402F",
                }}
              >
                La{" "}
                <span
                  style={{
                    color: "#fcbb00",
                    fontSize: "inherit",
                    textShadow: "inherit",
                  }}
                >
                  cessione del Quinto{" "}
                </span>
                comodamente da casa tua
              </h2>
            )}
          </HeroTextContainer>


















*/

