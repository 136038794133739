import React from 'react'
import styled from 'styled-components'
import LinksBlock from './LinksBlock'
import InfoBlock from './InfoBlock'

const FooterAssistenza = () => {
  return (
    <FooterContainer>
      <LinksBlock/>
      <InfoBlock/>
    </FooterContainer>
  )
}

// region Style
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 192rem;
  padding-top: 9rem;
  background-color: #F5F5F5;
  justify-content: center;
  margin: auto;
`
// endregion

export default FooterAssistenza
