import React from 'react'
import Highlighter from 'react-highlight-words'
import styled from 'styled-components'
import H4 from '../../../commons/H4'
import P from '../../../commons/P'

interface GlossaryCardProps {
  title?: string
  text?: string
  children?: any
  keywords?: string[]
}

const GlossaryCard = ({title, text, children, keywords = []}: GlossaryCardProps) => {
  return (
    <Container>
      <Box>
        <InnerBox>
          <StyledH4>
            <Em>
              <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={title || ''} />
            </Em>
          </StyledH4>
          {
            children ||
            <StyledP>
              {text}
            </StyledP>
          }
        </InnerBox>
      </Box>
    </Container>
  )
}

// region Style
const Container = styled.div`
  display: flex;
  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 768px){
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Box = styled.div`
  align-items: flex-start;
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.5rem;
  margin-bottom: 3rem;
  width: 100%;
`
const InnerBox = styled.div`
  text-align: initial;
  padding: 4rem 2.5rem;
  position: relative;
  flex: 1 1 70%;
  max-width: 50rem;
  display: inline-block;
`
const StyledH4 = styled(H4)`
  text-transform: uppercase;
  margin-bottom: 0.5em;
`
const StyledP = styled(P)`
  color: #AEAEAE;
  margin-bottom: 0;
`
const Em = styled.em`
  font-style: normal;
  color: #FCBB00;
`
// endregion

export default GlossaryCard
