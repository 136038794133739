import React, { useEffect, useState } from 'react'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import styled from 'styled-components'
import Article from './Article'
import axios from 'axios'
import { baseUrl } from '../../../config'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import Video from './Video'

const News = () => {
  const [articles, setArticles] = useState<any>([])
  const [videos, setVideos] = useState<any>([])

  const getAllArticles = () => {
    const filterByCategory = (item: any) => {
      return item.category?.id === 3
    }
    const filters = {
      and: 'published=true',
      order: 'DESC',
      order_field: 'publicationDate'
    }
    const filtersStringify = qs.stringify(filters)
    const getEndpoint = `${baseUrl}/articles?${filtersStringify}`

    axios
      .get(getEndpoint)
      .then(({ data }) => {
        // TODO: scommentare filter per categoria e selezione primo e secondo video
        const videos = data.payload.filter(filterByCategory)
        const firstAndSecondVideos = videos.slice(0, 2)
        const firstAndSecondArticles = data.payload.filter((article: any) => article.category?.id !== 3).slice(0, 2)

        setArticles(firstAndSecondArticles)
        // TODO: scommenta setVideo
        setVideos(firstAndSecondVideos)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getAllArticles()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <ContentArticles>
          <SectionTitle>
            <h2>Articoli</h2>
            <ToSectionDetail>
              <Link to={`articles`}>
                <span>Vedi tutti</span>
                <FontAwesomeIcon style={{ width: '4rem' }} icon={faArrowRight} />
              </Link>
            </ToSectionDetail>
          </SectionTitle>
          <Section>
            {articles &&
              articles.map((a: any, idx: number) => (
                <Article article={a} key={idx}/>
              ))}
            {/* */}
          </Section>
        </ContentArticles>
        <ContentVideo>
          <SectionTitle>
            <h2>Video</h2>
            <ToSectionDetail>
              <Link to={`video`}>
                <span>Vedi tutti</span>
                <FontAwesomeIcon style={{ width: '4rem' }} icon={faArrowRight} />
              </Link>
            </ToSectionDetail>
          </SectionTitle>
          <Section>
            {/* TODO: sostituire video al posto di articles */}
            {videos &&
              videos.map((a: any, idx: number) => (
                <Video
                  id={a.id}
                  title={a.title}
                  subtitle={a.subtitle}
                  name={a.name}
                  key={idx}
                  thumbnail={a.thumbnail}
                />
              ))}
          </Section>
        </ContentVideo>
      </Container>

      <Footer />
    </>
  )
}

export default News

const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 150px;
`

const ContentArticles = styled.div``
const ContentVideo = styled.div`
  margin-top: 5rem;
  flex-wrap: wrap;
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
`
const ToSectionDetail = styled.div`
  & > a {
    text-decoration: none;
    color: #222222;
    font-weight: 700;
  }
`
