import React from 'react'
import styled from 'styled-components'
import CustomHTML from '../../commons/CustomHTML'

export interface IBodyMessage {
  firstSection: string
  strongSection: string
  secondSection: string
}

interface MessageProps {
  title: string
  body: IBodyMessage
}

const AdvMessage: React.FC<MessageProps> = ({ title, body }) => {
  return (
    <MessageContainer>
      <header
        style={{
          fontWeight: 'bold',
          marginBottom: '25px',
          fontSize: '1.3rem',
          lineHeight: 1.2,
          letterSpacing: '.25px'
        }}>
        {title}
      </header>
      <HTMLContainer>
        <CustomHTML html={body}/>
      </HTMLContainer>
    </MessageContainer>
  )
}

export default AdvMessage

const MessageContainer = styled.div`
  @media (max-width: 575px) {
    margin-top: 1rem;
  }
  position: relative;
  width: 100%;
  border-left: 5px solid #fcbb00;
  padding: 10px;
  text-align: left;
  margin-top: 70px;
`
const HTMLContainer = styled.div`
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1em;
  font-size: 1.2rem;
`
