import React from 'react'
import styled from 'styled-components'

const SubmitButton = () => {
  return (
    <SubmitContainer>
      <Button type={'submit'}>
        <span>
          CHIEDI UN PREVENTIVO GRATUITO**
        </span>
      </Button>
    </SubmitContainer>
  )
}

// region Style
const SubmitContainer = styled.div`
  text-align: center !important;
  margin-bottom: 1.5rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Button = styled.button`
  border: none;
  cursor: pointer;
  background-color: #FCBB00;
  position: relative;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.1s ease-out;
  font-size: 1.3rem;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  padding: 1rem 3rem;
  letter-spacing: 1px;
`
// endregion

export default SubmitButton
