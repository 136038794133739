import React from "react";
import styled from "styled-components";
import Header from "../../commons/Header";
import FooterAssistenza from "../../commons/footer/FooterAssistenza";
import TitleSection from "../../commons/TitleSection";
import TripleTab from "./TripleTab";
import H2 from "../../commons/H2";
import P from "../../commons/P";
import headerBackground from "../../../img/ASSISTENZA.jpg";
import mobileHeaderBackground from "../../../img/assistenza-mobile.jpg";
import mainBackground from "../../../img/bg_intro.jpg";
import greenNumberIcon from "../../../img/green_number.svg";
import sectionBackground from "../../../img/callme.jpg";
import sectionBackgroundSet from "../../../img/callme_2x.jpg";
import CustomerCare from './customerCare/CustomerCare'
import H1 from '../../commons/H1';

const Subtitle = () => {
  return (
    <b>
      Il nostro team supporta le tue richieste
      <br />
      con efficienza e tempestività
    </b>
  );
};
// const FormText = () => {
//   return (
//     <>
//       <StyledH1>Scopri la nostra cessione del quinto. <Em>Richiedi qui il tuo prestito!</Em></StyledH1>
//       <FormStyledP>Il finanziamento è rivolto a pensionati in convenzione INPS, lavoratori assunti a tempo
//         indeterminato da aziende SpA/Srl con più di 15 dipendenti, dipendenti pubblici/statali/parapubblici</FormStyledP>
//     </>
//   )
// }

const Assistenza = () => {
  return (
    <>
      <Header />
      <TitleSection
        backgroundUrl={`url(${headerBackground})`}
        mobileBackgroundUrl={`url(${mobileHeaderBackground})`}
        title={"ASSISTENZA CLIENTI"}
      >
        <Subtitle />
      </TitleSection>
      <Main>
        <HelpContainer>
          <CustomerCareContainer>
            <CustomerCare/>
          </CustomerCareContainer>
          <Container>
            <Row style={{ paddingBottom: "1.5rem" }}>
              <ElementContainer>
                <Box>
                  <InnerBox>
                    <StyledH2>Assistenza clienti QuintoPuoi</StyledH2>
                    <StyledP style={{ marginBottom: "3rem", display:'flex', justifyContent: 'center' }}>
                      <GreenNumber href="tel:800124344">
                        <span>800 124 344</span>
                      </GreenNumber>
                    </StyledP>
                    <StyledP>
                      <strong>
                        Il servizio dei nostri operatori è a tua disposizione dal lunedì al venerdì dalle ore 9.00 alle 13.00
                      </strong>
                    </StyledP>
                    <StyledP>
                      <strong>
                        Ti ricordiamo inoltre che chiamando il Numero Verde troverai sempre attiva 7/7 giorni 24 h<br/>
                        <strong style={{color: '#252523'}}>Miss QuintoPuoi</strong>, la nostra assistente virtuale in grado di orientare tempestivamente ogni risposta alle tue esigenze.
                      </strong>
                    </StyledP>
                    <StyledP>
                      <strong>
                        Se non riesci a recuperare le informazioni che cerchi, ti invitiamo a scriverci a <StyledA href="mailto:assistenzaclienti@quintopuoi.it">assistenzaclienti@quintopuoi.it</StyledA><br/>
                        indicando nome, cognome, numero pratica e numero di telefono dove ricontattarti. Grazie.
                      </strong>
                    </StyledP>
                  </InnerBox>
                </Box>
              </ElementContainer>
            </Row>
            <Row>
              <ImgContainer>
                <Img src={sectionBackground} srcSet={sectionBackgroundSet} alt={'Impiegati di un call center che lavorano'}/>
              </ImgContainer>
            </Row>
            <TripleTab />
          </Container>
        </HelpContainer>
      </Main>
      <FooterAssistenza />
    </>
  );
};

// region Style
const Main = styled.div`
  background-image: url(${mainBackground});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`;
const CustomerCareContainer = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`
const HelpContainer = styled.div`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-top: 7rem;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`;
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  margin-right: -15px;
  margin-left: -15px;
`;
const ElementContainer = styled.div`
  text-align: initial;
  display: flex;
  
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const Box = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1.5rem;
  margin-bottom: 3rem;
  width: 100%;
`;
const InnerBox = styled.div`
  position: relative;
  flex: 1 1 70%;
  display: inline-block;
  padding: 5rem 1.5rem;
  text-align:center;
`;
const StyledH2 = styled(H2)`
  margin-bottom: 3rem;
`;
const StyledP = styled(P)`
  color: #aeaeae;
  font-size: 2.2rem;
    strong {
        color: #706f6f
    }
`;
const GreenNumber = styled.a`
  background-image: url(${greenNumberIcon});
  padding: 33px 8px 8px 33px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  font-size: 33px;
  font-weight: bold;
  text-decoration: none;
  color: #222;
  width: 33rem;
  height: 10rem;
  background-size: 27rem;
`;
const StyledA = styled.a`
  font-weight: bold;
  font-size: 2.2rem;
  text-decoration: none;
  color: #222;
  :hover {
      color: #FCBB00;
  }
`;
const ImgContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;
const Img = styled.img`
  display: block !important;
  max-width: 100%;
  height: auto;
  border-style: none;
`;
const StyledH1 = styled(H1)`
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const FormStyledP = styled(P)`
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
const Em = styled.em`
  font-style: normal;
  color: #FCBB00;
`

// endregion

export default Assistenza;
