import React, { useEffect } from "react";
import styled from "styled-components";

interface LinkProps extends React.ComponentProps<any> {
  link?: string;
  text: string;
  linkStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  subtitle?: string;
  subtitleStyle?: React.CSSProperties;
  target?: string
}

const Link = ({
  text,
  link,
  linkStyle = {},
  textStyle = {},
  subtitle,
  subtitleStyle = {},
  onClick,
  target
}: LinkProps) => {


  return subtitle ? (
    <StyledLink href={link} target={target} style={linkStyle} onClick={onClick}>
      <Title style={textStyle}>{text}</Title>
      <Subtitle style={subtitleStyle}>{subtitle}</Subtitle>
    </StyledLink>
  ) : (
    <StyledLink href={link} target={target} style={linkStyle} onClick={onClick}>
      <Title style={textStyle}>{text}</Title>
    </StyledLink>
  );
};

const StyledLink = styled.a`
  :hover {
    box-shadow: 0 3px 18px 0 rgb(45 46 46 / 15%);
  }
  cursor: pointer;
  min-width: 18rem;
  min-height: 4.6rem;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-out;
  font-size: 1.3rem;
  background-color: #fcbb00;
  border-radius: 2.3rem;
  padding: 1rem 3rem;
  letter-spacing: 1px;
`;
const Title = styled.span`
  padding-top: 0.25em;
  transition: all 0.1s ease-out;
  color: #222;
  white-space: nowrap;
`;

const Subtitle = styled.span`
  font-weight: 700;
  margin-top: 0.4rem;
  margin-bottom: 0;
  color: #aeaeae;
  font-size: 1.1rem;
  line-height: 1.3;
`;

export default Link;
