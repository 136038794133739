import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import {ReactComponent as DownloadAttachmentWhite} from '../../../img/download_attachment_white.svg'
import {ReactComponent as DownloadAttachmentYellow} from '../../../img/download_attachment.svg'

const UsefulDocuments = () => {
  return (
    <Container>
      <InnerContainer>
        <StyledH2>DOCUMENTI UTILI</StyledH2>
        <Row>
          <ElementContainer>
            <YellowDocumentLink href={`${process.env.PUBLIC_URL}/uploads/CONTRATTO_PRESTITOPUOI_COPIA_CONFORME_ALLA_STIPULA.pdf`} target="_blank">
              <Span>Contratto PrestitoPuoi - copia conforme alla stipula</Span>
              <DownloadAttachmentWhite/>
            </YellowDocumentLink>
          </ElementContainer>
          <ElementContainer>
            <YellowDocumentLink href={`${process.env.PUBLIC_URL}/uploads/SECCI_PRESTITOPUOI_COPIA_CONFORME_ALLA_STIPULA.pdf`} target="_blank">
              <Span>Secci PrestitoPuoi - copia conforme alla stipula</Span>
              <DownloadAttachmentWhite/>
            </YellowDocumentLink>
          </ElementContainer>
        </Row>
        <Row>
          <ElementContainer>
            <WhiteDocumentLink href={`${process.env.PUBLIC_URL}/uploads/CONTRATTO_PRESTITOPUOI_DISTANZA_COPIA_CONFORME_ALLA_STIPULA.pdf`} target="_blank">
              <Span>Contratto PrestitoPuoi a distanza - copia conforme alla stipula</Span>
              <DownloadAttachmentYellow/>
            </WhiteDocumentLink>
          </ElementContainer>
          <ElementContainer>
            <WhiteDocumentLink href={`${process.env.PUBLIC_URL}/uploads/SECCI_PRESTITOPUOI_DISTANZA_COPIA_CONFORME_ALLA_STIPULA.pdf`} target="_blank">
              <Span>Secci PrestitoPuoi a distanza - copia conforme alla stipula</Span>
              <DownloadAttachmentYellow/>
            </WhiteDocumentLink>
          </ElementContainer>
        </Row>
      </InnerContainer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  margin-top: 3rem ;
  display: block;
`
const InnerContainer = styled.div`
  padding-bottom: 2rem;
  padding-top: 3rem;
  @media (min-width: 992px) {
    max-width: 1140px;
  }
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const StyledH2 = styled(H2)`
  text-align: center;
`
const Row = styled.div`
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const ElementContainer = styled.div`
  @media (min-width: 992px){
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 1.5rem;
`
const YellowDocumentLink = styled.a`
  background: #FCBB00;
  display: flex;
  align-items: center;
  padding: 40px 30px;
  font-size: 16px;
  text-decoration: none;
  transition: all .3s ease-in-out;
  height: 100%;
  color: inherit;
`
const Span = styled.span`
  text-align: initial;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  padding-right: 15px;
`
const WhiteDocumentLink = styled.a`
  background: #fff;
  display: flex;
  align-items: center;
  padding: 40px 30px;
  font-size: 16px;
  text-decoration: none;
  transition: all .3s ease-in-out;
  height: 100%;
  color: inherit;
`
// endregion

export default UsefulDocuments
