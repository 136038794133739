import React from 'react'
import styled from 'styled-components'
import bgImage from '../../../img/prestitopuoi_choose.jpg'

const ChoosePrestitoPuoi = () => {
  return (
    <Container>
      <Img src={bgImage} alt={"Un'immagine con 4 differente scente e un testo che esorta a scegliere Banca Sistema per il prestito"}/>
    </Container>
  )
}

// region Style
const Container = styled.div`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  margin: 3rem auto 0 auto !important;
  display: block;
`
const Img = styled.img`
  max-width: 120rem;
  width: 100%;
`
// endregion

export default ChoosePrestitoPuoi
