import React, {useState} from 'react'
import styled from 'styled-components'
import ChevronDown from '../../images/ico_chevron_down.svg'

interface SelectProps {
  name: string
  options: Options[]
  placeHolderText: string
  dataFromSelect?: (value: Options['value']) => any
  required?: boolean
}

export interface Options {
  value: string
  label: string
}

const NativeSelect: React.FC<SelectProps> = ({ name, options, placeHolderText, dataFromSelect, required= false }) => {
  const [value, setValue] = useState('')

  return (
    <div style={{ width: '100%' }}>
      <Select
        required={required}
        value={value}
        aria-label={name}
        onChange={(e) => {
          setValue(e.target.value)
          dataFromSelect && dataFromSelect(e.target.value)
        }}>
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </Select>
    </div>
  )
}

export default NativeSelect

const Select = styled.select`
    background-color: #e8e8e8;
    width: 100%;
    border: solid 1px #fff;
    color: #606060;
    font-weight: 700;
    font-family: TradeGothicLTPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    display: block;
    -webkit-appearance: none;
    line-height: 1;
    font-size: 1.3rem;
    border-radius: 2.3rem;
    min-height: 4.6rem;
    padding: 1rem 1.5rem;
    background-image: url(${ChevronDown});
    background-position: center right 10px;
    background-repeat: no-repeat;

    &:focus {
        outline: none;
    }
`

const SelectContainer = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 50px;
  padding: 0;
  margin: 0 5px;
  width: 100%;
  font-family: TradeGothicLTPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #222;
  font-size: 1.3rem;
  z-index: 3;
  border-radius: ${(props) => (props.open ? '30px 30px 0 0' : '30px')};
  transition: border-radius 0.3s;
  position: relative;
  cursor: pointer;
`

const OptionsWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  border: none;
  overflow: hidden;
  box-shadow: 0 3px 18px 0 rgb(45 46 46 / 15%);
  background: #ffffff;
  box-sizing: border-box;
  z-index: 1010;
  border-radius: 0 0 30px 30px;
`

const DropDownList = styled.ul`
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  color: #222;
  border-radius: 0 0 30px 30px;
  z-index: 2;
  margin: 0;
  padding: 0;
  max-height: 240px;

  & > li {
    cursor: pointer;
    text-align: left;
  }
  & > li:last-child {
    border-radius: 0 0 30px 30px;
  }
`

const SelectedValue = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  place-content: center;
  width: 100%;
  letter-spacing: -0.5px;
  font-weight: bold;
`

const SelectedValueLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 1.3rem;
`

const OptionLi = styled.li`
  display: list-item;
  cursor: pointer;
  padding: 0.8rem 1.5rem;
  list-style: none;
  line-height: 1;
  font-size: 1.3rem;
  font-weight: 400;
  width: 100%;
  &:hover {
    background-color: #fcbb00;
    color: #ffffff;
  }
`
