import React from "react";
import styled from "styled-components";

const VideoPlayer = () => {
  return (
    <div
      style={{ maxWidth: "1140px", marginLeft: "auto", marginRight: "auto" }}
    >
      <Container>
        <ContainerPlayer>
          <iframe
            style={{ width: "80%", height: "100%" }}
            title="homeVideo"
            src="https://www.youtube.com/embed/t_vfVrK0fq0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </ContainerPlayer>
      </Container>
    </div>
  );
};

export default VideoPlayer;

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 600px;
  margin-top: 70px;
`;

const ContainerPlayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
`;
