// Testo mostrato sia nella pagina "quinto-puoi" che nella landing page "landing-pensionati"
import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import checkIcon from '../../../img/ico_check_brand.svg'

const PensionatiText = () => {
  return (
    <>
      <H2 style={{marginTop: '3rem'}}>REQUISITI</H2>
      <Ul>
        <Li><span>Per richiedere il finanziamento è necessario percepire una pensione di anzianità o reversibilità (no pensione d’invalidità civile, no pensione minima o assegni sociali) ed essere residenti in Italia. Possibilità di tassi agevolati in Convenzione INPS.</span>
        </Li>
        <Li><span>La concessione del finanziamento è subordinata alla valutazione complessiva della posizione creditizia del Cliente e di conseguenza dell’importo oggetto di richiesta.</span>
        </Li>
      </Ul>
      <P style={{fontSize: '1rem'}}>Per informazioni economiche e contrattuali fare riferimento alle
        “Informazioni Europee di Base sul Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte
        le filiali della Banca e presso gli agenti in attività finanziaria che operano su mandato diretto
        della Banca per la promozione e il collocamento del prodotto QuintoPuoi. La concessione del
        finanziamento è subordinata all’approvazione di Banca Sistema S.p.A..</P>
      <H2 style={{marginTop: '3rem'}}>DOCUMENTI</H2>
      <Ul>
        <Li>Un documento di riconoscimento valido</Li>
        <Li>Il codice fiscale</Li>
        <Li><span>Ultimo cedolino della pensione</span></Li>
        <Li><span>Certificazione Unica</span></Li>
        <Li><span>Modello OBIS-M</span></Li>
      </Ul>
    </>
  )
}

// region Style
const Ul = styled.ul`  
  margin-bottom: 2em;
  padding-left: 1rem;
  list-style: none;
`
const Li = styled.li`
  padding: 0.2rem 0 0.2rem 3.6rem;
  background-size: 2rem;
  background-image: url(${checkIcon});
  background-repeat: no-repeat;
`
// endregion

export default PensionatiText
