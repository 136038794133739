import React, {useEffect, useState} from 'react'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import PreventivatoreTitleSection from '../../preventivatoreCommons/TitleSection'
import PreventivatoreFrom from '../../preventivatoreCommons/PreventivatoreFrom'
import ThankYouSection from './ThankYouSection'
import headerBackground from '../../../img/pgtfs_emo.jpg'
import mobileHeaderBackground from '../../../img/prev_pubblico-NEUTRO.jpg'
import styled from 'styled-components'
import bgUrl from '../../../img/bg_intro.jpg'
import SEO from '../../commons/SEO'
import H2 from '../../commons/H2'
import P from '../../commons/P'

const seoTitle = 'Preventivo anticipo tfs online | QuintoPuoi'
const seoDescription = "Preventivo anticipo tfs: richiedi un preventivo online gratuito e senza impegno per l'anticipo tfs. Compila i campi nel form per essere ricontattato."

const PreventivatoreTfs = () => {
  const [showThanks, setShowThanks] = useState(false)
  useEffect(() => {
    if (window.location.href.includes('grazie')) {
      setShowThanks(true)
    }
  })

  return (
    <>
      <SEO title={seoTitle} description={seoDescription}/>
      <Header/>
      <PreventivatoreTitleSection title={'PREVENTIVATORE TFS'} backgroundUrl={`url(${headerBackground})`} mobileBackgroundUrl={`url(${mobileHeaderBackground})`}/>
      <Main>
        {showThanks ?
          <ThankYouSection/> :
          <>
            <PreventivatoreFrom isTfs={true} lpType={'qplandingtfs'} routeAfterSubmit={'/preventivatore-tfs/grazie'}/>
            <TextContainer>
              <StyledH2>
                COME RICHIEDERE UN PREVENTIVO PER L'ANTICIPO TFS
              </StyledH2>
              <StyledP>
                Grazie a Banca Sistema puoi richiedere un <strong>preventivo gratuito per l’anticipo TFS</strong> in modo facile e intuitivo.
                Ti basterà inserire alcune informazioni nel <strong>Form online</strong> per essere rapidamente contattato dai nostri consulenti
                e ricevere un <strong>preventivo personalizzato</strong> sulla base delle tue esigenze
              </StyledP>
              <StyledP>
                La <strong>compilazione del Form</strong> per la richiesta di preventivo di anticipo TFS è molto semplice e richiede l’inserimento
                di <strong>alcuni campi necessari</strong>. Nel primo dovrai specificare in modo generico la tua <strong>posizione lavorativa</strong>:
                sei un dipendente pubblico? Un privato? Un pensionato? Nei due campi restanti invece ti verrà richiesto di inserire
                <strong> l’importo desiderato del finanziamento</strong> e la <strong>durata</strong> che vorresti sostenere.
                Tra i campi non obbligatori puoi anche inserire la <strong>finalità ultima della tua richiesta</strong>,
                ovvero la motivazione per la quale richiedi la liquidità dell’anticipo TFS.
              </StyledP>
              <StyledP>
                Nella seconda parte del Form, invece, è necessario inserire i tuoi <strong>dati personali</strong>. Nello specifico ti verranno richiesti
                i tuoi <strong>dati anagrafici</strong> (vale a dire nome, cognome, anno di nascita e località di residenza) e i <strong>tuoi contatti</strong>,
                ovvero il tuo <strong>numero di telefono</strong> e la tua <strong>e-mail</strong>. Con questi dati saremo in grado di contattarti per proporti
                la migliore soluzione di finanziamento su misura per te.
              </StyledP>
            </TextContainer>
          </>
        }
      </Main>
      <Footer/>
    </>
  )
}

// region Style
const Main = styled.div`
  background-image: url(${bgUrl});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const TextContainer = styled.div`
  max-width: 1120px;
  padding: 0 1.5rem;
  margin: 9rem auto 0 auto;
`
const StyledH2 = styled(H2)`
  text-align: initial;
`
const StyledP = styled(P)`
  text-align: initial;
`
// endregion

export default PreventivatoreTfs
