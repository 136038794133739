import React from "react";
import styled from "styled-components";
import sliceBackground from "../../img/slice_hero_middle.svg";
import H1 from "./H1";
import P from "./P";

interface TitleSectionProps {
  backgroundUrl?: string;
  title?: string;
  subtitle?: any;
  mobileBackgroundUrl?: string;
  children?: any;
}

const TitleSection = ({
  backgroundUrl,
  mobileBackgroundUrl,
  title,
  subtitle,
  children,
}: TitleSectionProps) => {
  return (
    <SectionContainer>
      <Section>
        <Container1>
          <Container2>
            <Container3>
              <div>
                <Background
                  backgroundUrl={backgroundUrl}
                  mobileBackgroundUrl={mobileBackgroundUrl}
                >
                  <BackgroundSlice />
                  <TextContainer>
                    <StyledH1>{title}</StyledH1>
                    <StyledP>
                      <strong>
                        <span>{subtitle || children}</span>
                      </strong>
                    </StyledP>
                  </TextContainer>
                </Background>
              </div>
            </Container3>
          </Container2>
        </Container1>
      </Section>
    </SectionContainer>
  );
};

// region Style
const SectionContainer = styled.div`
  @media(max-width: 990px) {
    margin-top: 70px;
  }
  margin-top: 128px;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
`;
const Section = styled.section`
  position: relative;
  display: block;
  width: 100%;
  z-index: 1;
  min-height: 33rem;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
`;
const Container1 = styled.div`
  position: relative;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
`;
const Container2 = styled.div`
  position: relative;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
const Container3 = styled.div`
  width: 100%;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  opacity: 1;
  display: block;
  border: none;
  float: left;
  height: 100%;
  min-height: 1px;
`;
const Background = styled.article<TitleSectionProps>`
  @media (max-width: 655px) {
    background-image: ${(props) =>
      props.mobileBackgroundUrl
        ? props.mobileBackgroundUrl
        : props.backgroundUrl};
  }
  background-image: ${(props) => props.backgroundUrl};
  position: relative;
  @media (min-width: 769px) {
    background-position: center center;
  }
  background-position: 80% center;
  width: 100%;
  min-height: 33rem;
  padding-bottom: 20.625%;
  background-size: cover;
  background-color: #767474;
  display: block !important;
  overflow: hidden;
  transition: all 0.1s ease-out;
  z-index: 2;
`;
const BackgroundSlice = styled.div`
  background-image: url(${sliceBackground});
  background-position: center center;
  background-size: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 110%;
  max-width: 86rem;
  padding-bottom: 0;
  height: calc(100% + 2px);
  max-height: 45rem;
  background-repeat: no-repeat;
  position: absolute;
  transition: all 0.24s ease-out;
  opacity: 0.8;
  z-index: 1;
`;
const TextContainer = styled.div`
  top: 37%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 111rem;
  text-align: center;
  z-index: 10;
  text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
`;
const StyledH1 = styled(H1)`
  @media (min-width: 768px) {
    font-size: 5rem !important;
  }
  font-size: 3rem;
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
`;
const StyledP = styled(P)`
  @media (min-width: 768px) {
    font-size: 2.1rem;
  }
  color: #fff;
  margin-bottom: 0;
`;
// endregion

export default TitleSection;
