import React from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import {useNavigate} from 'react-router-dom'

const FormLegend = () => {
  const navigate = useNavigate()

  return (
    <Legend>
      <P style={{fontSize: '11px'}}>* Campi obbligatori.</P>
      <P style={{marginBottom: 0, fontSize: '11px'}}>** L'invio della richiesta di preventivo è gratis e senza impegno ed equivale al conferimento del
        consenso per la finalità di cui al punto 2 dell’informativa, anche per l’utilizzo di tecniche di
        comunicazione a distanza, facoltativo ma necessario per offrirti il servizio.&nbsp;
        <a style={{color: 'inherit', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => navigate('/privacy')}>Informativa privacy completa</a>.
      </P>
    </Legend>
  )
}

// region Style
const Legend = styled.div`
  text-align: initial;
  padding-left: 8px;
  padding-right: 8px;
  margin: 18px 13px;
  @media (min-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  position: relative;
  width: 100%;
`
// endregion

export default FormLegend
