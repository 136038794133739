import React from 'react'
import styled from 'styled-components'
import H1 from '../commons/H1'
import H3 from '../commons/H3'

const TfsFormHeader = () => {
  return (
    <>
      <StyledH1>
        Compila il form per richiedere un preventivo gratuito e
        senza impegno
      </StyledH1>
      <StyledH3>
        CON L'ANTICIPO TFS TI VERRÀ ACCREDITATA SUBITO LA
        LIQUIDAZIONE CHE TI SPETTA, EVITANDO LUNGHE ATTESE!
      </StyledH3>
    </>
  )
}

// region Style
const StyledH1 = styled(H1)`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const StyledH3 = styled(H3)`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  color: #FCBB00;
  font-size: 17px;
  text-align: center;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
// endregion

export default TfsFormHeader
