import React from 'react'
import styled from 'styled-components'
import icoPDF from '../../../../img/pdf.png'

interface AttachmentsAreaProps {
  selectedObject: 'refund' | 'disclaimer' | 'extinctionCount' | 'tfrDisclaimer' | 'death' | 'documentationRequest'
}
const renderList = (object: string) => {
  if (object === 'refund' || object === 'extinctionCount' || object === 'death' || object === 'documentationRequest') return (
    <Ul>
      <Li>
        <p>
          Richiesta scritta e firmata*
        </p>
      </Li>
      <Li>
        <p>
          Documento identità*
        </p>
      </Li>
      <Li>
        <p>
          Tesserino Fiscale*
        </p>
      </Li>
    </Ul>
  )
  if (object === 'disclaimer') return (
    <Ul>
      <Li>
        <p>
          Richiesta scritta e firmata*
        </p>
      </Li>
      <Li>
        <p>
          Documento identità*
        </p>
      </Li>
      <Li>
        <p>
          Tesserino Fiscale*
        </p>
      </Li>
      <Li>
        <p>
          Contabile Bonifico*
        </p>
      </Li>
    </Ul>
  )
  if (object === 'tfrDisclaimer') return (
    <>
      <Ul>
        <Li>
          <p>
            <a href={`${process.env.PUBLIC_URL}/uploads/MODULO_RICHIESTA_ANTICIPO_TFR.pdf`} target={'_blank'} style={{color: 'inherit'}}>
              Modulo compilato e sottoscritto*
              <img style={{width: '25px', marginLeft: '5px'}} src={icoPDF}/>
            </a>
          </p>
        </Li>
        <Li>
          <p>
            Documento identità*
          </p>
        </Li>
        <Li>
          <p>
            Certificato di stipendio aggiornato*
          </p>
        </Li>
        <Li>
          <p>
            Tesserino Fiscale*
          </p>
        </Li>
        <Li>
          <p>
            Ultima Busta Paga*
          </p>
        </Li>
      </Ul>
      <p>In caso di TFR accantonato c/o Fondo:</p>
      <Ul>
        <Li>
          <p>
            Estratto conto aggiornato del Fondo nel quale si evince il TFR
            accantonato e ad oggi effettivamente disponibile*
          </p>
        </Li>
      </Ul>
    </>
  )
  return (
    <Ul>
      <Li>
        <p>
          Richiesta scritta e firmata*
        </p>
      </Li>
      <Li>
        <p>
          Documento identità*
        </p>
      </Li>
      <Li>
        <p>
          Tesserino Fiscale*
        </p>
      </Li>
    </Ul>
  )
}

const AttachmentsArea: React.FC<AttachmentsAreaProps> = ({selectedObject}) => {
  return (
    <Container>
      Allegare cortesemente i seguenti documenti:
      {renderList(selectedObject)}
      I formati validi sono jpg e pdf.<br/>
      I formati di tipo Word ed Excel non verranno accettati. <br/>
      La dimensione complessiva degli allegati non deve superare i 10 MB.
    </Container>
  )
}

// region Style
const Container = styled.div`
  width: 80%;
  margin: 2rem auto 0 auto;
  text-align: initial;
`
const Ul = styled.ul`
  padding-left: 3rem;
  margin-bottom: 1em;
`
const Li = styled.li`
  text-align: initial
`
// endregion

export default AttachmentsArea
