import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import Bold from '../../commons/Bold'

const ReferenceRegulationText = () => {
  return (
    <Container>
      <StyledH2>NORMATIVA DI RIFERIMENTO</StyledH2>
      <StyledP>I prestiti contro cessione del quinto sono disciplinati in Italia dal <Bold>D.P.R. 5 gennaio 1950, n. 180</Bold> (Approvazione del testo unico delle leggi concernenti il sequestro, il pignoramento e la cessione degli stipendi, salari e pensioni dei dipendenti dalle pubbliche Amministrazioni), pubblicato sulla Gazzetta Ufficiale n.99 del 29 aprile 1950.</StyledP>
      <StyledP style={{marginBottom: 0}}>La <Bold>Legge 311/2004</Bold>, cioè la Legge Finanziaria 2005, pubblicata sulla Gazzetta Ufficiale n. 306 del 31/12/2004 ha concesso anche ai dipendenti privati la possibilità di accedere al prestito con cessione del quinto dello stipendio.</StyledP>
    </Container>
  )
}

// region Style
const Container = styled.div`
  background-color: #E3E3E3;
  border-radius: 5px;
  margin-top: 2rem;
  padding: 10px;
`
const StyledH2 = styled(H2)`
  @media (max-width: 767px) {
    font-size: 1rem;
  }
  font-size: 1.5rem
`
const StyledP = styled(P)`
  font-size: 1rem;
`
// endregion

export default ReferenceRegulationText
