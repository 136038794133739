import React, { useEffect, useState } from 'react'
import {Link, useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faBars } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import qpLogo from '../../images/quinto_puoi.svg'
import bsLogo from '../../images/banca_sistema_new.svg'
import qpLogoRedux from '../../images/quinto_puoi_redux.svg'
import bsLogoRedux from '../../images/banca_sistema_redux_new.svg'
import greenNumberIcon from '../../img/green_number.svg'

const Header = () => {
  const navigate = useNavigate()

  let [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

  const [isHeaderShrink, setIsHeaderShrink] = useState<boolean>(false)

  const [headerBoxShadow, setHeaderBoxShadow] = useState<string>('')

  const openHamburgerMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setHeaderBoxShadow('rgb(9 51 64 / 27%) 0px 4px 9px 0px')
      setIsHeaderShrink(true)
    }
    if (window.scrollY === 0) {
      setHeaderBoxShadow('')
    }
    // gestione header
    if (window.scrollY >= 100) {
      setIsHeaderShrink(true)
    } else {
      setIsHeaderShrink(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const checkStringInUrl = (word: string) => {
    return window.location.href.includes(word)
  }

  return (
    <div style={{ position: 'relative' }}>
      <header
        style={{
          boxShadow: headerBoxShadow,
          width: '100%',
          position: 'fixed',
          top: '0',
          zIndex: '10',
          backgroundColor: '#fff',
          transition: 'all 0.1s ease-out'
        }}>
        {location.pathname === '/' && !isHeaderShrink && (
          <TopHeader style={{height: isHeaderShrink ? 0 : 80}}>
            <span style={{color: 'white', fontWeight: 'bold', marginRight: '3rem'}}> Assistenza clienti QuintoPuoi </span>
            <GreenNumber href="tel:800124344">
              <span style={{color: 'white'}}>800 124 344</span>
            </GreenNumber>
          </TopHeader>
        )}

        {
          // distinzione tra hamburger menu o menu normale
          isMobile ? (
            <BottomHeaderMobile>
              <LogosContainer>
                <a onClick={() => navigate('/')}>
                  <div>
                    <img src={qpLogoRedux} alt="Quinto Puoi logo redux" height="30px" style={{ maxWidth: '100%' }} />
                  </div>
                  <div>
                    <img src={bsLogoRedux} alt="Banca Sistema logo redux" height="10px" style={{ maxWidth: '100%' }} />
                  </div>
                </a>
              </LogosContainer>
                <OverlayMenu isVisible={isMenuOpen}>
                  <MenuHeader>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 0,
                        minWidth: '400px'
                      }}>
                      <BurgerLi>
                        <Link className={checkStringInUrl('quinto-puoi') ? 'current-header-tab' : ''} to="/quinto-puoi">Cessione del Quinto</Link>
                      </BurgerLi>
                      <BurgerLi>
                        <Link className={checkStringInUrl('prestitopuoi') ? 'current-header-tab' : ''} to="/prestitopuoi">PrestitoPuoi</Link>
                      </BurgerLi>
                      <BurgerLi>
                        <Link className={checkStringInUrl('anticipo-tfs') ? 'current-header-tab' : ''} to="/anticipo-tfs">Anticipo TFS</Link>
                      </BurgerLi>
                      <BurgerLi>
                        <Link className={checkStringInUrl('chi-siamo') ? 'current-header-tab' : ''} to="/chi-siamo">Chi siamo</Link>
                      </BurgerLi>
                      <BurgerLi>
                        <Link className={checkStringInUrl('la-nostra-rete') ? 'current-header-tab' : ''} to="/la-nostra-rete">La nostra rete</Link>
                      </BurgerLi>
                      <BurgerLi>
                        <Link className={checkStringInUrl('trasparenza') ? 'current-header-tab' : ''} to="/trasparenza">Trasparenza</Link>
                      </BurgerLi>
                      <BurgerLi>
                        <Link className={checkStringInUrl('firma-digitale') ? 'current-header-tab' : ''} to="/firma-digitale">Firma Digitale</Link>
                      </BurgerLi>
                      <BurgerLi>
                        <Link className={checkStringInUrl('assistenza') ? 'current-header-tab' : ''}  to="/assistenza">Assistenza</Link>
                      </BurgerLi>
                      <BurgerLi
                        style={{
                          borderRadius: '100px',
                          backgroundColor: 'rgb(252, 187, 0)',
                          padding: '13px 20px'
                        }}>
                        <Link to="/preventivatore">Chiedi un preventivo gratuito</Link>
                      </BurgerLi>
                    </ul>
                  </MenuHeader>
                </OverlayMenu>

              <BurgerMenuOpener>
                <FontAwesomeIcon
                  onClick={openHamburgerMenu}
                  icon={isMenuOpen ? faClose : faBars}
                  style={isMenuOpen ? {
                    cursor: 'pointer',
                    backgroundColor: '#AEAEAE',
                    borderRadius: '50%',
                    width: '2.5rem',
                    height: '2.5rem',
                    color: '#ffffff',
                    padding: '10px'
                  }: {
                    cursor: 'pointer',
                    backgroundColor: '#fcbb00',
                    borderRadius: '50%',
                    width: '2.5rem',
                    height: '2.5rem',
                    color: '#ffffff',
                    padding: '10px'
                  }}
                />
              </BurgerMenuOpener>
            </BottomHeaderMobile>
          ) : (
            <BottomHeader
              style={{
                height: isHeaderShrink ? '7.4rem' : '12.8rem',
                borderTop: isHeaderShrink ? '4px solid #222' : ''
              }}>
              <ImgDiv>
                <Link to="/">
                  {isHeaderShrink ? (
                    <img
                      src={qpLogoRedux}
                      alt="Quinto Puoi Logo Redux"
                      style={{
                        maxWidth: '100%'
                      }}
                    />
                  ) : (
                    <img
                      src={qpLogo}
                      alt="Quinto Puoi Logo"
                      style={{
                        maxWidth: '100%'
                      }}
                    />
                  )}
                </Link>
              </ImgDiv>
              <MenuHeader>
                <LinksContainer>
                  <ul
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 0
                    }}
                    className="center-items">
                    <Li className={checkStringInUrl('quinto-puoi') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/quinto-puoi">
                        Cessione del Quinto
                      </Link>
                    </Li>
                    <Li className={checkStringInUrl('prestitopuoi') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/prestitopuoi">
                        PrestitoPuoi
                      </Link>
                    </Li>
                    <Li className={checkStringInUrl('anticipo-tfs') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/anticipo-tfs">
                        Anticipo TFS
                      </Link>
                    </Li>
                    <Li className={checkStringInUrl('chi-siamo') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/chi-siamo">
                        Chi siamo
                      </Link>
                    </Li>
                    <Li className={checkStringInUrl('la-nostra-rete') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/la-nostra-rete">
                        La nostra rete
                      </Link>
                    </Li>
                    <Li className={checkStringInUrl('trasparenza') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/trasparenza">
                        Trasparenza
                      </Link>
                    </Li>
                    <Li className={checkStringInUrl('firma-digitale') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/firma-digitale">
                        Firma Digitale
                      </Link>
                    </Li>
                    <Li className={checkStringInUrl('assistenza') ? 'current-header-tab' : ''}>
                      <Link style={{ fontSize: '1.75rem' }} to="/assistenza">
                        Assistenza
                      </Link>
                    </Li>
                    <Li>
                      <Link style={quoteLinkStyle} to="/preventivatore">
                        Chiedi un preventivo gratuito
                      </Link>
                    </Li>
                  </ul>
                </LinksContainer>
              </MenuHeader>
              <ImgDiv>
                <a href="https://bancasistema.it/">
                  {isHeaderShrink ? (
                    <img
                      src={bsLogoRedux}
                      alt="Banca Sistema Logo Redux"
                      style={{
                        maxWidth: '100%'
                      }}
                    />
                  ) : (
                    <img
                      src={bsLogo}
                      alt="Banca Sistema Logo"
                      style={{
                        maxWidth: '100%'
                      }}
                    />
                  )}
                </a>
              </ImgDiv>
            </BottomHeader>
          )
        }
      </header>
    </div>
  )
}

export default Header

//region Style
const quoteLinkStyle = {
  color: 'inherit',
  backgroundColor: '#FCBB00',
  fontWeight: '700',
  textDecoration: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.1s ease-out',
  fontSize: '1.75rem',
  borderRadius: '2.3rem',
  minHeight: '4.6rem',
  padding: '1rem 2rem 1rem 2rem',
  letterSpacing: '1px',
  flex: 'auto'
}

const ImgDiv = styled.div`
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 0 2rem;
  -ms-flex-align: center;
  align-items: center;
`

const TopHeader = styled.div`
  position: relative;
  width: 100%;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-out;
  & a {
    color: #fcbb00;
    font-size: 16px;
    font-weight: bolder;
  }
`

const BottomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.1s ease-out;
`

const BottomHeaderMobile = styled.div`
  position: relative;
  margin-top: 10px;
  display: grid;
  grid-template-rows: auto;
`

const MenuHeader = styled.nav`
  & ul {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  & li {
    color: #222;
    padding: 0 0.9rem;
    font-size: 1.3rem;
    line-height: 1.2;
    font-weight: 900;
    letter-spacing: 1px;
  }
  & a {
    text-decoration: none;
    color: #222;
  }
`

const LogosContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`

const OverlayMenu = styled.div<any>`
  ${props => props.isVisible ? 'opacity: 1;' : 'pointer-events: none; opacity: 0!important;'};
  transition: all 0.1s ease-out;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinksContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  flex: 1 1 auto;
  transition: all 0.1s ease-out;
  justify-content: center;
`
const Li = styled.li`
  a {
    font-size: 1.3rem !important;
  }
  white-space: nowrap;
  flex: auto;
  padding-right: 20px;
`
const BurgerLi = styled.li`
  text-transform: uppercase;
  margin: 1.5rem 0;
  display: block;
  padding-right: 0;
  color: #222;
  padding: 0 0.9rem;
  a {
    font-size: 1.3rem !important;
    line-height: 1;
  }
  line-height: 1.2;
  font-weight: 900;
  letter-spacing: 1px;
`
const BurgerMenuOpener = styled.div`
  z-index: 1000;
  position: absolute;
  right: 10%;
  transition: 'all 0.1s ease-out'
`
const GreenNumber = styled.a`
  background-image: url(${greenNumberIcon});
  padding: 42px 8px 8px 25px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  font-size: 33px;
  font-weight: bold;
  text-decoration: none;
  color: #222;
  width: 15rem;
  height: 10rem;
  background-size: 15rem;
`;
//endregion
