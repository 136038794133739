import React from 'react'
import styled from 'styled-components'

const H4 = styled.h4`
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0.75em;
  font-size: 1.2rem;
  letter-spacing: .25px;
  @media (min-width: 769px) {
    font-size: 1.6rem;
  }
  `

export default H4
