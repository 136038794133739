import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AbandonAlert from '../../commons/AbandonAlert'
import LandingHeader from '../../landingCommons/LandingHeader'
import headerBackground from '../../../img/HEADER_MOBILE-generale_new.jpg'
import smallHeaderBackground from '../../../img/LP-famiglia2_new.jpg'
import LandingIconsRow from '../../landingCommons/LandingIconsRow'
import LandingPreventivatore from '../../landingCommons/landingPreventivatore/LandingPreventivatore'
import LandingProjectsBlock from '../../landingCommons/LandingProjectsBlock'
import LandingVideoBlock from '../../landingCommons/LandingVideoBlock'
import LandingFooter from '../../landingCommons/LandingFooter'
import TripleTab from './TripleTab'
import pageBackground from '../../../img/bgpage.svg'
import ExamplesLanding from './ExamplesLanding'
import { useMediaQuery } from 'react-responsive'
import ExamplesSlider from './ExamplesSlider'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import {baseUrl} from '../../../config'

const LandingGenerale = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' })
  const location = useLocation()
  const [financingExamplesData, setFinancingExamplesData] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // Recupero utms da query string e salvo valori dell'utms in localStorage
  useEffect(() => {
    const urlParamsLand = new URLSearchParams(window.location.search)
    if (urlParamsLand.has('utm_source')) {
      let utms: UtmsType = {}
      utms.utm_source = urlParamsLand.get('utm_source')
      utms.utm_medium = urlParamsLand.get('utm_medium')
      utms.utm_content = urlParamsLand.get('utm_content')
      utms.utm_campaign = urlParamsLand.get('utm_campaign')
      utms.utm_term = urlParamsLand.get('utm_term')
      localStorage.setItem('qp_utms', JSON.stringify(utms))
    }
  }, [])

  // Funzione per triggerare azioni Google Analytics
  const handleGtag = () => {
    //@ts-ignore
    const gtag = window['gtag']
    gtag &&
      gtag('event', 'conversion', {
        send_to: 'AW-707945372/FIDQCNih6KwBEJzHydEC'
      })
  }

  // Funzione per recuperare da backend il testo da mostrare nelle 3 card
  const retrieveFinancingExampleData = async (): Promise<any> => {
    return await axios.get(`${baseUrl}/financing-examples`)
      .then(res => {
        return res.data.payload
      })
      .catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    handleGtag()
    retrieveFinancingExampleData()
      .then((data: any) => {
        setFinancingExamplesData(data)
      })
      .catch((e: any) => console.log(e.message))
  }, [])

  return (
    <Background>
      <AbandonAlert />
      <LandingHeader backgroundImg={headerBackground} smallBackgroundImg={smallHeaderBackground} />
      <PageStart>
        <LandingMainContainer>
          <Row>
            <LandingPreventivatore
              routeAfterSubmit={'/landing-generale/grazie'}
              lpType={'qplandinggeneralista'}
              teadsConversionType={'AddToCart'}
            />
            <LandingIconsRow />
          </Row>
          {isMobile ? <ExamplesSlider data={financingExamplesData}/> : <ExamplesLanding data={financingExamplesData}/>}
          <TripleTab />
          <LandingProjectsBlock />
          <LandingVideoBlock />
        </LandingMainContainer>
      </PageStart>
      <LandingFooter />
    </Background>
  )
}

// region Type

export interface UtmsType {
  utm_source?: string | null
  utm_medium?: string | null
  utm_content?: string | null
  utm_campaign?: string | null
  utm_term?: string | null
}

// endregion

// region Style
const PageStart = styled.div`
  @media (min-width: 769px) {
    margin-top: -5%;
  }
  margin-top: -21%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 2;
`
const Background = styled.div`
  margin: 0;
  background: #f3f3f3 url(${pageBackground}) no-repeat center 410px;
`
const LandingMainContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
// endregion

export default LandingGenerale
