import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import Bold from '../../commons/Bold'
import {useNavigate} from 'react-router-dom'

const ExamplesText = () => {

  const navigate = useNavigate()

  return (
    <Container>
      <H2 style={{marginTop: '3rem'}}>ESEMPI</H2>
      <P>Caratteristica principale di questa forma di finanziamento è che l'importo della <Bold>rata mensile</Bold> non può eccedere il valore di <Bold>1/5</Bold>, cioè il 20% dello stipendio netto o della pensione netta percepita mensilmente.</P>
      <P>
        Quindi per il calcolo della rata mensile in relazione all’importo complessivo richiesto bisogna sempre partire dalla retribuzione mensile o dall'importo mensile della pensione, calcolati al netto delle ritenute.
      </P>
      <A onClick={() => navigate('/#qp-loan-examples')}>Alcuni esempi di rata</A>
    </Container>
  )
}

// region Style
const Container = styled.div`
  margin-bottom: 3rem;
`
const A = styled.a`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`
// endregion

export default ExamplesText
