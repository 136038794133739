import React from 'react'
import styled from 'styled-components'

interface LandingHeaderProps {
  backgroundImg?: string
  smallBackgroundImg?: string
}

const LandingHeader = ({backgroundImg, smallBackgroundImg}: LandingHeaderProps) => {
 return (
   <ImgContainer backgroundImg={backgroundImg} smallBackgroundImg={smallBackgroundImg}/>
  )
}

// region Style
const ImgContainer = styled.div<LandingHeaderProps>`
  :before {
    @media (min-width: 769px){
    padding-top: 29.25%;
    }
    content: '';
    display: block;
    padding-top: 83.73%;
    box-sizing: inherit;
  }
  @media (min-width: 769px) {
    ${props => `background: url(${props.smallBackgroundImg}) no-repeat center top/cover;`}    
  }
  ${props => `background: url(${props.backgroundImg}) no-repeat center top/cover;`}    
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`
// endregion

export default LandingHeader

