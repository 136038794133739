import React from 'react'
import styled from 'styled-components'
import H3 from '../../commons/H3'
import P from '../../commons/P'

const QuoteInfo = () => {
  return (
    <Container>
      <RowContainer>
        <Row>
          <Column>
            <Card>
              <StyledH3>COS'È L'ANTICIPO TFS</StyledH3>
              <P>
                L’anticipo TFS – ossia l’anticipo del <strong>Trattamento di Fine Servizio</strong> –
                è il finanziamento erogato da Banca Sistema ai <strong>dipendenti pubblici e statali</strong> che hanno raggiunto o sono in prossimità della pensione e hanno diritto alla <strong> liquidazione
                maturata.</strong>
              </P>
              <P style={{marginBottom: '0px'}}>
                Gli istituti previdenziali abitualmente erogano il TFS entro 12-36 mesi, noi ti offriamo l’opportunità
                di ottenere anticipatamente
                <strong> in un’unica soluzione fino al 100% della tua liquidazione.</strong>
              </P>
            </Card>
          </Column>
          <Column>
            <Card>
              <StyledH3>A CHI È RIVOLTO</StyledH3>
              <P style={{marginBottom: '0px'}}>
                Il TFS è l’indennità corrisposta dall’ente previdenziale alla fine del rapporto di lavoro ai dipendenti
                pubblici e statali assunti con
                <strong> contratto a tempo indeterminato prima del 1° gennaio 2001. </strong>
                L’anticipo TFS potrà quindi essere erogato esclusivamente alle categorie appena descritte.
              </P>
            </Card>
          </Column>
          <Column>
            <Card>
              <StyledH3>COME FUNZIONA L'ANTICIPO TFS</StyledH3>
              <P>
                L’ <strong>importo massimo del prestito viene determinato in funzione del TFS netto maturato </strong>
                in base al «prospetto di liquidazione» rilasciato dall’ente pensionistico.
              </P>
              <P style={{marginBottom: '0px'}}>
                <strong>Il cliente riceverà quindi in via anticipata fino al 100% della somma definita dal
                  prospetto. </strong>
                Poi l’ente previdenziale corrisponderà direttamente a Banca Sistema le somme, nei modi e nei tempi
                previsti dalla normativa di riferimento,
                a rimborso dell’anticipo erogato da Banca Sistema.
              </P>
            </Card>
          </Column>
        </Row>
      </RowContainer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  margin-top: 80px;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`
const RowContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Column = styled.div`
  @media (min-width: 992px){
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Card = styled.div`
  text-align: initial;
  background: #fff;
  padding: 38px;
  height: 100%;
`
const StyledH3 = styled(H3)`
  border-left: 5px solid #FCBB00;
  padding: 6px 0 4px 8px;
  font-size: 18px;
`
// endregion

export default QuoteInfo
