import React from 'react'
import styled from 'styled-components'
import P from '../../../commons/P'
import Collapsable from '../../../commons/footer/Collapsable'
import {useNavigate} from 'react-router-dom'

interface FormCollapsableProps {
  innerTextBackgroundColor?: string
}

const CollapsableText = ({innerTextBackgroundColor}: FormCollapsableProps) => {
  const navigate = useNavigate()

  return (
    <CollapsableTextContainer innerTextBackgroundColor={innerTextBackgroundColor}>
      <CollapsableInnerTextContainer>
        <CollapsableP>
          Banca Sistema promuove e colloca il prodotto “PrestitoPuoi” in virtù del rapporto di collaborazione con Santander Consumer Bank S.p.A. , che è l’erogatore, senza vincoli di esclusiva e senza costi aggiuntivi per il Cliente.<br/>
          Per informazioni aggiuntive consultare la documentazione di trasparenza disponibile <Span onClick={() => navigate('/prestitopuoi')}>qui</Span>.
        </CollapsableP>
      </CollapsableInnerTextContainer>
    </CollapsableTextContainer>
  )
}

const FormCollapsable = ({innerTextBackgroundColor}: FormCollapsableProps) => {
 return (
   <Collapsable titleStyle={collapsableTitleStyle} headerStyle={collapsableHeaderStyle} replaceTextContainer={true} buttonWidth={'2.4rem'} buttonHeight={'2.4rem'} buttonIconSize={'1.5rem'}>
     <CollapsableText innerTextBackgroundColor={innerTextBackgroundColor}/>
   </Collapsable>
 )
}

// region Style
const collapsableHeaderStyle = {
  background: '#252525',
  minHeight: '4rem',
  padding: '1rem',
  fontWeight: 'unset'
}
const collapsableTitleStyle = {
  textTransform: 'uppercase',
  fontWeight: 'unset',
  fontSize: '1.3rem'
}
const CollapsableInnerTextContainer = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #F5F5F5;
  display: block;
`
const CollapsableTextContainer = styled.div<any>`
  ${props => props.innerTextBackgroundColor ? `background-color: ${props.innerTextBackgroundColor}` : 'background-color: white'};
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;
`
const CollapsableP = styled(P)`
  font-size: 1.2rem;
  margin-bottom: 0;
  background-color: white;
`
const Span = styled.span`
  color: #FCBB00;
  cursor: pointer;
  text-decoration: underline;
`
// endregion

export default FormCollapsable
