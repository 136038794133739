import React from 'react'
import styled, { keyframes } from 'styled-components'

interface ThankYouModalProps {
  closeModal: () => any
}

const ThankYouModal: React.FC<ThankYouModalProps> = ({closeModal}) => {

  return (
    <Backdrop $show={true} onClick={() => closeModal()}>
      <Box onClick={(e) => e.stopPropagation()}>
        <CloseIcon onClick={() => closeModal()}>×</CloseIcon>
        <Title>GRAZIE!</Title>
        <Content>La richiesta è stata inviata, la elaboreremo appena possibile</Content>
      </Box>
    </Backdrop>
  )
}

export default ThankYouModal

//region Style

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`
const Backdrop = styled.div<{ $show: boolean }>`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-content: center;
  z-index: 9999999;
  visibility: ${(props) => (!props.$show ? 'hidden' : 'visible')};
  animation: ${(props) => (!props.$show ? fadeOut : fadeIn)} 0.7s linear;
  transition: visibility 1s linear;
`
const Box = styled.div`
  position: relative;
  width: 480px;
  height: 250px;
  font-size: 15px;
  color: #444;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  background-color: rgba(252, 187, 0, 1);
  border-width: 0px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 10px;
`
const CloseIcon = styled.span`
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #000000;
  display: block;
  text-align: center;
  cursor: pointer;
  font-family: arial, sans-serif !important;
  line-height: 1 !important;
  transition-property: color;
  transition-duration: 0.2s;
  text-decoration: none !important;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    color: #ffffff;
  }
`
const Title = styled.div`
  position: absolute;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  z-index: 503;
  top: 45px;
  width: 480px;
  height: 55px;
  font-size: 36px;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  @media screen and (max-width: 480px) {
    position: unset;
    margin-top: 10px;
    width: 100%;
  }
`
const Content = styled.div`
  position: absolute;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  z-index: 504;
  top: 120px;
  left: 65px;
  width: 355px;
  height: 85px;
  font-size: 19px;
  color: #444444;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
`
//endregion
