import styled from 'styled-components'
import checkIcon from '../../../img/ico_check_brand.svg'

const Li = styled.li`
  padding: 0.2rem 0 0.2rem 3.6rem;
  background-size: 2rem;
  background-image: url(${checkIcon});
  background-repeat: no-repeat;
`

export default Li
