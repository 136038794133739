import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import FAQs from './FAQs'
import Glossary from './glossary/Glossary'
import {useSearchParams} from 'react-router-dom'


const TripleTab = () => {
  const [tabToShow, setTabToShow] = useState<0 | 1 | 2>(0)
  const [urlSearchParams] = useSearchParams()
  const keywords = (urlSearchParams.get('ricerca') || '').trim().split(' ')
  const goTo = urlSearchParams.get('go-to')

  useEffect(() => {
    // Se è stata effettuata una ricerca, scrollo sulle faq
    const section = document.querySelector( '#double-tab' )
    if(keywords[0]) {
      section && section.scrollIntoView()
    } else if (goTo === 'glossary'){
      setTabToShow(1)
      section && section.scrollIntoView()
    }
  }, [])

  return (
    <>
      <Row id={'double-tab'}>
        <LinksContainer>
          <Switch onClick={() => setTabToShow(0)} isActive={tabToShow === 0}>
            FAQ
          </Switch>
          &nbsp;
          <Switch onClick={() => setTabToShow(1)} isActive={tabToShow === 1}>
            GLOSSARIO
          </Switch>
        </LinksContainer>
      </Row>
      <Row>
        <TabContainer>
          <InnerTabContainer>
            <Tab isActive={tabToShow === 0} >
              <FAQs keywords={keywords}/>
            </Tab>
            <Tab isActive={tabToShow === 1}>
              <Glossary keywords={keywords}/>
            </Tab>
          </InnerTabContainer>
        </TabContainer>
      </Row>
    </>
  )
}

// region Style
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const LinksContainer = styled.div`
  text-align: center !important;
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Switch = styled.div<any>`
  ${(props => props.isActive ? 'background-color: #FCBB00;' : '')}
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 0.1s ease-out;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  padding: 1rem 3rem;
  font-weight: bold;
  font-size: 20px;
  color: #222;
`
const TabContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const InnerTabContainer = styled.div`

`
const Tab = styled.div<any>`
  @media (prefers-reduced-motion: reduce){
    transition: none;
  }
  ${(props => props.isActive ? 'display: block;' : 'display: none;')}
  transition: opacity 0.15s linear;
`
// endregion

export default TripleTab
