import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import axios from 'axios'
import {baseUrl} from '../../../../config'
import {useController, useForm, Controller, useWatch} from 'react-hook-form'
import Loading from '../../../commons/Loading'
import Select, {Options} from '../../../commons/Select'
import Disclaimer from './Disclaimer'
import SubmitButton from './SubmitButton'
import AttachmentsArea from './AttachmentsArea'
import {FileDropBox} from '../../../commons/FileDropBox'
import ThankYouModal from './ThankYouModal'
import ErrorModal from './ErrorModal'
import H1 from '../../../commons/H1'
import H3 from '../../../commons/H3'
//@ts-ignore
import ReactRecaptcha3 from 'react-google-recaptcha3'

const ibanRegex = "^(?:(?:CR|DE|ME|RS|VA)\\d{20}|(?:CZ|ES|SE|SK|TN)\\d{22}|(?:DK|FI|FO|GL|SD)\\d{16}|(?:AT|BA|EE|LT|XK)\\d{18}|(?:AE|IL|TL)\\d{21}|(?:LY|PT|ST)\\d{23}|(?:IT|SM)\\d{2}[A-Z]\\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\\d{26}|(?:AL|CY)\\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\\d{12}[A-Za-z0-9]{11}\\d{2}|(?:GB|IE)\\d{2}[A-Z]{4}\\d{14}|(?:KZ|LU)\\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\\d{10}[A-Za-z0-9]{12}|AZ\\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\\d{14}|BG\\d{2}[A-Z]{4}\\d{6}[A-Za-z0-9]{8}|BH\\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\\d{25}[A-Z][A-Za-z0-9]|BY\\d{2}[A-Za-z0-9]{4}\\d{4}[A-Za-z0-9]{16}|DO\\d{2}[A-Za-z0-9]{4}\\d{20}|EG\\d{27}|GE\\d{2}[A-Z]\\d{16}|GT\\d{2}[A-Za-z0-9]{24}|GR\\d{9}[A-Za-z0-9]{16}|HR\\d{19}|IS\\d{24}|JO\\d{2}[A-Z]{4}\\d{4}[A-Za-z0-9]{18}|KW\\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\\d{6}[A-Za-z0-9]{20}|LV\\d{2}[A-Z]{4}\\d{13}|MD\\d{2}[A-Za-z0-9]{20}|MK\\d{5}[A-Za-z0-9]{10}\\d{2}|MR\\d{25}|MT\\d{2}[A-Z]{4}\\d{5}[A-Za-z0-9]{18}|MU\\d{2}[A-Z]{4}\\d{19}[A-Z]{3}|NL\\d{2}[A-Z]{4}\\d{10}|NO\\d{13}|SA\\d{4}[A-Za-z0-9]{18}|SC\\d{2}[A-Z]{4}\\d{20}[A-Z]{3}|SI\\d{17}|SV\\d{2}[A-Z]{4}\\d{20}|TR\\d{8}[A-Za-z0-9]{16}|UA\\d{8}[A-Za-z0-9]{19}|VG\\d{2}[A-Z]{4}\\d{16}|GE\\d{2}[A-Z]{2}\\d{16})$"

// region Utility Components
const FormInput = (props: any) => {
  const { control, name } = props
  const { field } = useController({ control, name })

  return (
    <InputContainer>
      <Input {...field} value={field.value || ''} {...props} aria-label={name}/>
    </InputContainer>
  )
}
const Textarea = ({value, field}: any) => {

  const [text, setText] = React.useState<string>(value)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    setText(value)
    field.onChange(value)
  }

  return (
    <TextAreaContainer>
      <TextAreaLabel> Note </TextAreaLabel>
      <StyledTextArea onChange={handleChange} value={text} aria-label={'Textarea notes'}/>
    </TextAreaContainer>
  )
}
// endregion

const CustomerCare = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showThankYouModal, setShowThankYouModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [attachments, setAttachments] = useState<FilesObject>({})
  const [order, setOrder] = useState<string[]>(Object.keys(attachments))

  // Componente input che serve solo a far visualizzare l'errore html di default se non si inserisce nessun allegato
  const fakeInputRef = useRef(null)

  const { reset, handleSubmit, control, setValue } = useForm<FormValues>()
  const object = useWatch({ name: 'object', control })

  const closeThankYouModal = () => setShowThankYouModal(false)
  const closeErrorModal = () => setShowErrorModal(false)
  const recaptchaErrorHandler = (e: any) => {
    console.error('Error retrieving recaptcha token', e)
    setIsLoading(false)
    setShowErrorModal(true)
  }

  // Ogni volta che gli allegati cambiano devo settare l'input finto come valido
  const changeAttachments = (files: any) => {
    //@ts-ignore
    fakeInputRef?.current?.setCustomValidity('')
    setAttachments(files)
  }

  const onSubmit = handleSubmit((data, event) => {
    ReactRecaptcha3.getToken().then( async (token: string) => {
      event?.preventDefault()
      setIsLoading(true)

      // Se non è stato inserito nessun allegato, mostro l'errore html di default
      if(order.length <= 0) {
        //@ts-ignore
        fakeInputRef?.current?.setCustomValidity('Inserisci tutti gli allegati richiesti')
        setIsLoading(false)
        return
      }

      data.reCaptchaToken = token
      data.taxCode = data.taxCode.toUpperCase()
      data.iban = data.iban.toUpperCase()
      if(data.object !== 'documentationRequest') {
        // @ts-ignore
        delete data.documentType
      }
      if(data.object !== 'refund') {
        // @ts-ignore
        delete data.iban
      }

      const toSubmit = new FormData()
      Object.entries(data).forEach(([key, value]) => {
        toSubmit.append(key, value)
      })

      order.forEach((o) => {
        const file = attachments[o] as any
        if (file) {
          toSubmit.append('fileNames', o)
          toSubmit.append('attachments', file)
        }
      })

      const url = `${baseUrl}/customer-care`

      await axios.post(url, toSubmit)
        .then(() => {
          setShowThankYouModal(true)
        })
        .catch((e) => {
          console.error('Error submitting customer care form', e)
          setShowErrorModal(true)
        })
        .finally(() => setIsLoading(false))

    }, recaptchaErrorHandler)
  })

  useEffect(() => {
    const storageData = JSON.parse(localStorage.getItem('selectData') || '[]')
    const object = storageData.find((data: any) => data.category === 'object')
    const documentType = storageData.find((data: any) => data.category === 'documentType')
    reset({
      ...defaultValues,
      object: object?.value || '',
      documentType: documentType?.value || ''
    })
  }, [reset])

  return (
    <FormContainer>
      {isLoading && <Loading />}
      {showThankYouModal && <ThankYouModal closeModal={closeThankYouModal}/>}
      {showErrorModal && <ErrorModal closeModal={closeErrorModal}/>}
      <form onSubmit={onSubmit}>
        <StyledH1>
          Compila il form per richiedere assistenza
        </StyledH1>
        <StyledH3>
          prenderemo in carica la tua richiesta e ti verrà inviata una mail di riepilogo con tutti i dati inseriti
        </StyledH3>
        <FirstSection>
          <SelectsContainer>
            <SelectsRow>
              <SelectContainer>
                <Select
                  name={'object'}
                  options={objectValues}
                  required={true}
                  placeHolderText={'Oggetto della richiesta'}
                  dataFromSelect={({ value }: any) => {
                    setValue('object', value, { shouldDirty: true })
                  }}
                />
              </SelectContainer>
            </SelectsRow>
          </SelectsContainer>
        </FirstSection>
        <SecondSection>
          <Container>
            <Row>
              <Filler />
              <FormInput
                control={control}
                name={'nameAndSurname'}
                type={'text'}
                id={'name-and-surname'}
                placeholder={'Nome e Cognome*'}
                required={true}
              />
              <FormInput
                control={control}
                name={'taxCode'}
                type={'text'}
                id={'tax-code'}
                placeholder={'Codice Fiscale*'}
                required={true}
                pattern={"^[A-Za-z0-9]+$"}
                minLength={16}
                maxLength={16}
              />
              <FormInput
                control={control}
                name={'practiceNumber'}
                type={'text'}
                id={'practice-number'}
                placeholder={'N° PRATICA'}
                required={false}
              />
              <FormInput
                control={control}
                name={'phone'}
                type={'tel'}
                id={'phone'}
                placeholder={'Telefono*'}
                minLength={8}
                required={true}
              />
              <FormInput
                control={control}
                name={'email'}
                type={'email'}
                id={'email'}
                placeholder={'Email*'}
                required={true}
              />
              {object === 'refund' && <FormInput
                control={control}
                name={'iban'}
                type={'text'}
                id={'iban'}
                placeholder={'Iban*'}
                pattern={ibanRegex}
                maxLength={27}
                required={true}
              />}
              {object === 'documentationRequest' &&
                <DocumentTypeContainer>
                  <Select
                    name={'documentType'}
                    options={documentTypeValues}
                    placeHolderText={'Tipo Documento'}
                    dataFromSelect={({ value }: any) => {
                      setValue('documentType', value, { shouldDirty: true })
                    }}
                    required={true}
                    selectContainerBackground={'unset'}
                  />
                </DocumentTypeContainer>
              }
              <Controller name="notes" render={Textarea} control={control}/>
              <AttachmentsArea selectedObject={object}/>
              <FileDropBoxContainer>
                <FileDropBoxInfo/>
                <FileDropBox
                  id={'attachments'}
                  title={''}
                  files={attachments}
                  setFiles={changeAttachments}
                  fileNames={order}
                  setFileNames={setOrder}
                  reorder
                />
                <input ref={fakeInputRef} aria-label={'Fake input ref'} style={{width: 0, height: 0, opacity: 0}}/>
              </FileDropBoxContainer>
              <Disclaimer/>
              <SubmitButton />
            </Row>
          </Container>
        </SecondSection>
      </form>
    </FormContainer>
  )
}

// region Form And Types
const objectValues: Options[] = [
  { label: 'Richiesta Rimborso', value: 'refund' },
  { label: 'Richiesta Liberatoria', value: 'disclaimer' },
  { label: 'Richiesta Conteggio Estintivo', value: 'extinctionCount' },
  { label: 'Svincolo / Liberatoria TFR', value: 'tfrDisclaimer' },
  { label: 'Sinistro per Perdita Impiego o per Decesso', value: 'death' },
  { label: 'Richiesta Documentazione', value: 'documentationRequest' }
]
const documentTypeValues: Options[] = [
  { label: 'Copia Contratto', value: 'contractCopy' },
  { label: 'Piano di Ammortamento', value: 'amortizationSchedule' },
  { label: 'Estratto Pagamenti', value: 'paymentsHistory' },
]
type FormValues = {
  object: 'refund' | 'disclaimer' | 'extinctionCount' | 'tfrDisclaimer' | 'death' | 'documentationRequest'
  nameAndSurname: string
  taxCode: string
  practiceNumber: string
  phone: string
  email: string
  iban: string
  notes: string
  documentType: 'contractCopy' | 'amortizationSchedule' | 'paymentsHistory',
  reCaptchaToken?: string
}
const defaultValues: FormValues = {
  object: 'refund',
  nameAndSurname: '',
  taxCode: '',
  practiceNumber: '',
  phone: '',
  email: '',
  iban: '',
  notes: '',
  documentType: 'contractCopy'
}
interface FilesObject {
  [key: string]: null | File
}
// endregion

// region Style
const TextAreaContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
`
const TextAreaLabel = styled.div`
  margin: 1em;
`
const StyledTextArea = styled.textarea`
  width: 50%;
  height: 100%;
  resize: none;
  min-height: 100px;
  margin: 1em;
  border-radius: 1rem;
  border: 1px solid #d8d8d8;
  padding: 1rem;
`
const StyledH1 = styled(H1)`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const StyledH3 = styled(H3)`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  color: #FCBB00;
  font-size: 17px;
  text-align: center;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  margin-bottom: 1em;
`
const FormContainer = styled.div`
  margin: 0 auto;
  min-height: 16rem;
  background-color: rgba(34, 34, 34, 0.85);
  position: relative;
  max-width: 114rem;
  border-radius: 4.5rem;
  display: block;
`
const FirstSection = styled.div`
  min-height: 7.6rem;
`
const SelectsContainer = styled.div`
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`
const SelectsRow = styled.div`
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  justify-content: center;
  display: flex;
`
const SelectContainer = styled.div`
  @media (min-width: 992px) {
    flex-basis: 0;
    flex-grow: 1;
  }
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  flex: 0 0 100%;
  max-width: 300px;
  position: relative;
  width: 100%;
`
const SecondSection = styled.div`
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 70%);
  background-color: #fff;
  position: relative;
  display: block;
  padding-bottom: 2rem;
  border-radius: 0 0 4.5rem 4.5rem;
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const DocumentTypeContainer = styled.div`
  @media (min-width: 576px) {
    flex: 0 0 30%;
  };
  flex: 0 0 44%;
  margin-left: 15px;
  border-radius: 2.3rem;
  border: 1px solid #d8d8d8;
  height: 4.6rem;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Filler = styled.div`
  margin-bottom: 3rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const FileDropBoxContainer = styled.div`
  width: 100%;
  padding: 0 10%;
`
const FileDropBoxInfo = styled.p`
  text-align: initial;
  width: 80%;
`
const InputContainer = styled.div`
  @media (min-width: 576px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  margin-bottom: 3rem !important;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Input = styled.input`
  display: block;
  width: 100%;
  margin: 0;
  padding: calc(1rem + 0.25em) 2rem 1rem;
  height: 4.6rem;
  border-radius: 2.3rem;
  color: #222;
  font-size: 1.3rem;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  font-weight: 400;
  letter-spacing: 0.5px;
`

// endregion

export default CustomerCare
