import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

// Componente che mostra un box per avvertire l'utente che l'operazione di richiesta di un preventivo è
// assai veloce, quando tenta di abbandonare la pagina
const AbandonAlert: React.FC = () => {
  const [show, setShow] = useState(false)
  const shown = useRef<boolean>(false)

  useEffect(() => {
    const handler = () => {
      if (shown.current) return
      else {
        setShow(true)
        // @ts-ignore
        shown.current = true
      }
    }
    document.body.addEventListener('mouseleave', handler)
    return () => document.body.removeEventListener('mouseleave', handler)
  }, [])

  return show ? (
    <Backdrop $show={show} onClick={() => setShow(false)}>
      <Box onClick={(e) => e.stopPropagation()}>
        <CloseIcon onClick={() => setShow(false)}>×</CloseIcon>
        <Title>ASPETTA!</Title>
        <Content>Basta solo 1 minuto per terminare la tua richiesta online</Content>
        <Button onClick={() => setShow(false)}>
          <FontAwesomeIcon icon={faPencil} />
          <span>CONTINUA E CHIEDI IL PREVENTIVO</span>
        </Button>
      </Box>
    </Backdrop>
  ) : null
}

export default AbandonAlert

//region Style

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const Backdrop = styled.div<{ $show: boolean }>`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-content: center;
  z-index: 9999999;
  visibility: ${(props) => (!props.$show ? 'hidden' : 'visible')};
  animation: ${(props) => (!props.$show ? fadeOut : fadeIn)} 0.7s linear;
  transition: visibility 1s linear;
`

const Box = styled.div`
  position: relative;
  width: 480px;
  height: 340px;
  font-size: 15px;
  color: #444;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  background-color: rgba(252, 187, 0, 1);
  border-width: 0px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 10px;

  @media screen and (max-width: 480px) {
    width: 100vw;
    height: fit-content;
  }
`

const CloseIcon = styled.span`
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #000000;
  display: block;
  text-align: center;
  cursor: pointer;
  font-family: arial, sans-serif !important;
  line-height: 1 !important;
  transition-property: color;
  transition-duration: 0.2s;
  text-decoration: none !important;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    color: #ffffff;
  }
`

const Title = styled.div`
  position: absolute;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  z-index: 503;
  top: 45px;
  width: 480px;
  height: 55px;
  font-size: 36px;
  color: #252523;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
`

const Content = styled.div`
  position: absolute;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  z-index: 504;
  top: 120px;
  left: 65px;
  width: 355px;
  height: 85px;
  font-size: 19px;
  color: #444444;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
`

const Button = styled.a`
  position: absolute;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  z-index: 506;
  top: 230px;
  left: 80px;
  width: 330px;
  height: 45px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(31, 31, 31, 1);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 35px 1fr;
  place-content: center;
  box-sizing: border-box;
  :active {
    transform: scale(0.9);
    transition-duration: 0.3s;
  }
  & > svg {
    font-size: inherit;
    color: inherit;
    width: 1em;
    margin: 0.5em;
    line-height: inherit;
    display: inline-block;
    vertical-align: middle;
    transform: translateY(-1px);
    text-align: center;
    place-self: center;
    -webkit-font-smoothing: antialiased;
  }

  & > span {
    line-height: 45px;
    width: fit-content;
  }
`
//endregion
