import React, {useState} from 'react'
import styled from 'styled-components'
import Link from '../../commons/Link'
import H2 from '../../commons/H2'
import AdvantagesText from '../quintoPuoi/AdvantagesText'
import PensionatiText from '../quintoPuoi/PensionatiText'
import DipendentiPrivatiText from '../quintoPuoi/DipendentiPrivatiText'
import DipendentiPubbliciText from '../quintoPuoi/DipendentiPubbliciText'
import checkIcon from '../../../img/spli.svg'

const TripleTab = () => {
  const [tabToShow, setTabToShow] = useState(1)

  return (
    <Boxcon>
      <BoxconInner>
        <StyledH2>SCEGLI IL FINANZIAMENTO CHE FA PER TE IN BASE AL TUO PROFILO</StyledH2>
      </BoxconInner>
      <SwitchContainer>
        <Switch onClick={() => setTabToShow(1)} isActive={tabToShow === 1}>Sei un pensionato?</Switch>
        <Switch onClick={() => setTabToShow(2)} isActive={tabToShow === 2}>Sei un dipendente pubblico?</Switch>
        <Switch onClick={() => setTabToShow(3)} isActive={tabToShow === 3}>Sei un dipendente privato?</Switch>
      </SwitchContainer>
      <BoxconInner>
        <Tab isActive={tabToShow === 1}>
          <AdvantagesText/>
          <PensionatiText/>
        </Tab>
        <Tab isActive={tabToShow === 2}>
          <AdvantagesText/>
          <DipendentiPubbliciText/>
        </Tab>
        <Tab isActive={tabToShow === 3}>
          <AdvantagesText/>
          <DipendentiPrivatiText/>
        </Tab>
      </BoxconInner>
      <LinkContainer>
        <Link text={'RICHIEDI PREVENTIVO GRATUITO**'} link={'#preventivatore2'}/>
      </LinkContainer>
    </Boxcon>
  )
}

// region Style
const Boxcon = styled.div`
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  margin: 6rem 0;
`
const BoxconInner = styled.div`
  text-align: initial;
  @media (min-width: 769px) {
    padding: 5rem;
  }
  padding: 1.5rem;
`
const StyledH2 = styled(H2)`
  font-weight: 700;
  font-size: 29px;
  color: #2E2E2E;
  margin-bottom: 0 !important;
`
const SwitchContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`
const Switch = styled.div<any>`
  background: #222;
  :hover {
    background: #EBA918;
  }
  ${props => props.isActive ? 'background-image: linear-gradient(350deg, #FAC963 0%, #EBA918 51%, #FED033 100%)' : ''};
  cursor: pointer;
  color: #fff;
  font-size: 21px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  text-align: center;
  display: block;
  padding: 1.5rem 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0;
  transition: all .3s ease-in-out;
`
const LinkContainer = styled.div`
  padding-bottom: 3rem;
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;
`
const Tab = styled.div<any>`
  ${props => props.isActive ? '' : 'display: none'};
  text-align: initial;
  @media (prefers-reduced-motion: reduce){
    transition: none;
  }
  transition: opacity 0.15s linear;
  
  h2 {
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: .5px;
  }
  
   ul {
    padding: 0;
    margin-bottom: 1.5rem;
    list-style: none;
  }
  li {
    padding: 0.5rem 0 0.5rem 4.4rem;
    padding-left: 3.2rem;
    background: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: unset;
  }
`
// endregion

export default TripleTab
