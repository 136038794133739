import React from "react";
import styled from "styled-components";
import bgBS from "../../../images/bg_bancaSistem.jpg";
import BSWhite from "../../../images/banca_sistema_white_new.png";
import IcoTransfer from "../../../images/ico_about_transfer.svg";
import IcoPedge from "../../../images/ico_about_pedge.svg";
import IcoBanking from "../../../images/ico_about_banking.svg";
import IcoFactoring from "../../../images/ico_about_factoring.svg";
import IcoServices from "../../../images/ico_about_services.svg";

import ListItem from "./ListItem";

const BSJumbo = () => {
  return (
    <JumboContainer>
      <LeftSide>
        <A href="https://bancasistema.it/" rel="noreferrer" target="_blank">
          <img
            src={BSWhite}
            alt="Banca Sistema Logo"
            style={{ maxWidth: "100%" }}
          />
          <p
            style={{
              color: "#fff",
              marginTop: "50px",
              fontWeight: "900",
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '8px'
            }}
          >
            Uniamo le credenziali di una solida banca quotata con i vantaggi di
            uno specialista sempre pronto ad innovare e crescere. Trasformiamo
            l’idea di banca in un servizio su misura per i nostri clienti.
          </p>
        </A>
      </LeftSide>
      <RightSide>
        <ListItem
          img={IcoTransfer}
          title={"Cessione del Quinto "}
          text={"Il finanziamento veloce e sicuro di cui hai bisogno"}
          link={"https://www.quintopuoi.it/quinto-puoi/"}
        ></ListItem>
        <ListItem
          img={IcoPedge}
          title={"Credito su pegno "}
          text={"I tuoi preziosi diventano un finanziamento subito disponibile"}
          link={"https://prontopegno.it/"}
          blank={true}
        ></ListItem>
        <ListItem
          img={IcoBanking}
          title={"Per i tuoi risparmi"}
          text={"Un partner per far rendere al meglio la tua liquidità"}
          link={"https://bancasistema.it/servizi-bancari"}
          blank={true}
        ></ListItem>

        <ListItem
          img={IcoFactoring}
          title={"Factoring"}
          text={"Al fianco delle imprese per trasformare i crediti in valore"}
          link={"https://bancasistema.it/soluzioni-factoring"}
          blank={true}
        ></ListItem>
        <ListItem
          img={IcoServices}
          title={"Servizi"}
          text={"E molti altri servizi innovativi e personalizzati"}
          link={"https://bancasistema.it/altri-servizi"}
          blank={true}
        ></ListItem>
      </RightSide>
    </JumboContainer>
  );
};

export default BSJumbo;

const A = styled.a`
  text-decoration: none;
  max-width: 420px;
  text-align: initial;
`

const JumboContainer = styled.div`
  background-image: url(${bgBS});
  background-position: center center;
  background-size: cover;
  padding-top: 6rem;
  padding-bottom: 6rem;
  margin-top: 6rem;
  display: flex;
  flex-wrap: wrap;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 10px;
  }
`;

const RightSide = styled.div`
  padding-bottom: 3rem;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 768px) {
    margin-top: 100px;
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
