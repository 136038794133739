import React, {useRef} from 'react'
import styled from 'styled-components'
import {Splide, SplideSlide, Options} from '@splidejs/react-splide'
import GlossarySlide from './GlossarySlide'
import GlossaryCard from './GlossaryCard'
import P from '../../../commons/P'
import '@splidejs/react-splide/css'
import Highlighter from 'react-highlight-words'

const Glossary = ({keywords}: any) => {
  const sliderRef = useRef(null)
  const sliderOptions: Options = {
    arrows: false,
    drag: true
  }
  // Funzione che aggiorna l'altezza dello slider in base alla slide mostrata
  // FIXME: non funziona alla prima slide
  const updateHeight = (newIndex: any) => {
    if(sliderRef.current) {
      //@ts-ignore
      const slide = sliderRef.current.slides[newIndex]
      const childHeight = slide.querySelector('.slide-container').offsetHeight
      slide.parentElement.parentElement.style.height = childHeight + 'px'
    }
  }

  return (
    <Container>
      <Splide ref={sliderRef} aria-label="My Favorite Images" options={sliderOptions} onMove={(ref, prev) => updateHeight(prev)}>
        <SplideSlide>
          <GlossarySlide>
            <GlossaryCard keywords={keywords} title={'Assicurazione impiego'}>
              <StyledP>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Assicurazione a copertura di un\'eventuale perdita del posto di lavoro per qualsivoglia motivo.'} /><br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'È obbligatoria per Cessioni del Quinto e per le Delegazioni di Pagamento.'} />
              </StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Assicurazione vita'}>
              <StyledP>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Assicurazione a copertura del rischio premorienza.'} /><br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Anche questa è un\'assicurazione obbligatoria nelle Cessioni del Quinto e nelle Deleghe di pagamento.'} />
              </StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Certificato di stipendio'}>
              <StyledP>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Documento rilasciato dal datore di lavoro che riporta tutti i dati necessari per la valutazione e l\'avvio di una pratica di Cessione del Quinto.'} /><br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Attesta la sussistenza di un rapporto dipendente e riporta la data di assunzione, gli anni di anzianità lavorativa, la retribuzione lorda e netta (annua e mensile), il TFR cumulato, le eventuali già trattenute sullo stipendio per il pagamento di altri finanziamenti.'} />
              </StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Cessione del quinto dello stipendio'}>
              <StyledP>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'La cessione del quinto dello stipendio è una particolare tipologia di prestito personale, destinata a lavoratori dipendenti e a pensionati. In tale forma tecnica il rimborso delle rate avviene tramite cessione di una quota dello stipendio o della pensione a favore del soggetto finanziatore. Tale quota, trattenuta direttamente in busta paga, non può eccedere la quinta parte dell’emolumento netto mensile. La materia è stata originariamente disciplinata dal DPR 5.1.1950, n. 180, integrato e modificato da successivi interventi normativi.'} /><br/>
              </StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Conteggio estintivo'}>
              <StyledP>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'È un calcolo riportante la somma dovuta a una banca o Società Finanziaria al fine di estinguere anticipatamente un debito in corso.'} /><br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Si calcola sottraendo dal montante ancora dovuto (la rata mensile moltiplicata per il numero di rate restanti) l\'ammontare degli interessi non maturati e aggiungendo la commissioni di estinzione anticipata ed eventuali spese accessorie.'} />
              </StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Contratto di credito'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Contratto con cui un finanziatore concede o si impegna a concedere a un consumatore un credito sotto forma di dilazione di pagamento, di prestito o di altra facilitazione finanziaria.'} /></StyledP>
            </GlossaryCard>
          </GlossarySlide>
        </SplideSlide>
        <SplideSlide>
          <GlossarySlide>
            <GlossaryCard keywords={keywords} title={'Costi di istruttoria'}>
              <StyledP>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'I costi relativi alla valutazione e gestione del finanziamento che la banca sostiene nella fase iniziale sino all’erogazione del prestito. Trattasi di costi '} />&nbsp;
                <Em><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'up front'} /></Em>&nbsp;
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'non oggetto di rimborso in caso di estinzione anticipata del finanziamento.'} />
              </StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Creditore'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Soggetto (persona fisica o giuridica) che mette a disposizione di un altro soggetto (debitore) una somma di denaro o altro bene di valore, in cambio della promessa di rimborso futuro.'}/><br />
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'È tipicamente la Banca.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Debito residuo'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Porzione di un prestito (a titolo di capitale, che non include cioè gli interessi) che il debitore deve ancora versare al creditore.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Debitore'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Soggetto che riceve denaro o altro bene di valore in prestito e che si impegna a rimborsarlo entro una data futura, secondo le modalità concordate.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Delegazione di pagamento'}>
              <StyledP style={{marginBottom: '1em'}}>
                <strong><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'La Delegazione di pagamento è un finanziamento che può essere concesso ai lavoratori dipendenti di Amministrazioni pubbliche o private da estinguersi con trattenute mensili in busta paga in forza di delega da essi rilasciata.'} /></strong>&nbsp;
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Tale tipologia di prestito può essere richiesta anche se il lavoratore ha precedentemente sottoscritto un finanziamento con cessione del quinto dello stipendio e per un importo non superiore ad un ulteriore quinto dello stipendio. Per tali ragioni la'} />&nbsp;
                <strong><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'delegazione di pagamento è anche detta doppia cessione del quinto.'} /></strong></StyledP>
              <StyledP>
                <strong><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Nei prestiti con delega il lavoratore richiedente dà mandato al proprio datore di lavoro di trattenere mensilmente la rata da corrispondere al creditore.'} /></strong>&nbsp;
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Il datore di lavoro, tuttavia, non ha l’obbligo di accettare l’incarico di pagamento, così come avviene per la cessione del quinto, in quanto la delega è un atto discrezionale, non dovuto.'} />
              </StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Durata del finanziamento'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Intervallo temporale concordato dal creditore e dal debitore entro cui il debitore dovrà restituire il prestito, unitamente agli interessi maturati. Solitamente si misura in anni.'} /></StyledP>
            </GlossaryCard>
          </GlossarySlide>
        </SplideSlide>
        <SplideSlide>
          <GlossarySlide>
            <GlossaryCard keywords={keywords} title={'Erogazione'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'È il momento in cui si versa a favore del debitore l\'importo concesso in prestito.'} /><br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Il contratto di credito si perfeziona con tale atto.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Estinzione anticipata'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'È la facoltà di estinguere il prestito anticipatamente rispetto al termine previsto dal contratto.'} />&nbsp;<br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'In tale caso al debitore viene richiesto il versamento del capitale residuo.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Finanziatore'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Soggetto che, essendo abilitato a erogare finanziamenti a titolo professionale nel territorio della Repubblica, offre o stipula contratti di credito.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Insolvenza'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Mancato o ritardato rimborso all\'ente finanziatore di una o più rate da parte del debitore.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Intermediario del credito'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'L\'agente in attività finanziaria, il mediatore creditizio nonché il soggetto, diverso dal finanziatore, che nell\'esercizio della propria attività commerciale o professionale, a fronte di un compenso in denaro o di altro vantaggio economico oggetto di pattuizione e nel rispetto delle riserve di attività previste dalla legge, conclude contratti di credito per conto del finanziatore ovvero svolge attività di presentazione o proposta di contratti di credito o altre attività preparatorie in vista della conclusione di tali contratti.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Montante'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'È il prodotto della rata mensile per il numero delle rate. Rappresenta quindi il totale che verrà rimborsato alla Banca nel corso della vita del prestito ovvero l’Importo totale dovuto dal Consumatore.'} /></StyledP>
            </GlossaryCard>
          </GlossarySlide>
        </SplideSlide>
        <SplideSlide>
          <GlossarySlide>
            <GlossaryCard keywords={keywords} title={'Piano di ammortamento'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Il piano di ammortamento è il programma di restituzione rateale del debito. Indica l’importo della rata composta da una quota capitale e una quota interessi, la periodicità delle singole rate e la data di scadenza delle stesse oltre al debito residuo.'} />&nbsp;<br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Viene consegnato al cliente al momento della stipula del contratto.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Quota capitale'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Porzione della rata periodica costante di rimborso che concerne la restituzione del capitale preso in prestito.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Quota interessi'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Porzione della rata periodica costante di rimborso che concerne la corresponsione degli interessi maturati sul capitale residuo.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Rata'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Versamento periodico da corrispondere all\'ente finanziatore come rimborso del finanziamento ricevuto, comprensivo sia della quota capitale che della quota di interessi maturata.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'T.A.E.G. (Tasso annuale effettivo globale)'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Il TAEG rappresenta lo strumento principale di trasparenza nei contratti di credito al consumo. E’ un indice armonizzato a livello comunitario che nelle operazioni di credito al consumo rappresenta il costo totale del credito a carico del consumatore, comprensivo degli interessi e di tutti gli altri oneri da sostenere per l’utilizzazione del credito stesso. Il TAEG è espresso in percentuale del credito concesso e su base annua. Deve essere indicato nella documentazione contrattuale e nei messaggi pubblicitari o nelle offerte comunque formulate.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'T.A.N. (Tasso annuo nominale)'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Il TAN indica il tasso d\'interesse (ossia il prezzo), in percentuale e su base annua, richiesto da un creditore sull\'erogazione di un finanziamento. A differenza del TAEG (vedi) non esprime il "costo complessivo" del finanziamento che può essere anche molto più alto (ad esempio, per spese, oneri e commissioni accessorie). Pertanto un prestito con TAN pari a zero potrebbe avere un TAEG ben maggiore di zero.'} /></StyledP>
            </GlossaryCard>
          </GlossarySlide>
        </SplideSlide>
        <SplideSlide>
          <GlossarySlide>
            <GlossaryCard keywords={keywords} title={'T.E.G.M. (Tasso effettivo globale medio)'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Il Tasso effettivo globale medio indica il valore medio del tasso effettivamente applicato dal sistema bancario e finanziario a categorie omogenee di operazioni creditizie (ad esempio: aperture di credito in c/c, crediti personali, leasing, factoring, mutui, ecc.) nel secondo trimestre precedente. Ai sensi della legge il calcolo del tasso deve tener conto delle commissioni, remunerazioni a qualsiasi titolo e delle spese, escluse quelle per imposte e tasse, collegate all’erogazione del credito. I tassi rilevati sono pubblicati trimestralmente in Gazzetta Ufficiale. Il tasso effettivo globale medio risultante dall’ultima rilevazione e relativamente alla categoria di operazioni in cui il credito è compreso determina la soglia oltre la quale gli interessi sono sempre usurari in base alla legge n. 108/96, come modificata dal d.l. 70/2011. Le banche e gli intermediari finanziari sono tenuti a pubblicizzare nei locali aperti al pubblico il TEGM.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'TFR (Trattamento di Fine Rapporto)'}>
              <StyledP><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'È una somma, accumulata durante gli anni di lavoro, dovuta al lavoratore quando cessa il rapporto di lavoro subordinato.'} />&nbsp;<br/>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Nel caso di finanziamenti con Cessione del Quinto viene vincolata a garanzia del prestito e diviene così indisponibile per il debitore.'} /></StyledP>
            </GlossaryCard>
            <GlossaryCard keywords={keywords} title={'Trattamento dei dati'}>
              <StyledP>
                <Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Qualunque operazione o complesso di operazioni, effettuati anche senza l\'ausilio di strumenti elettronici, concernenti la raccolta, la registrazione, l\'organizzazione, la conservazione, la consultazione, l\'elaborazione, la modificazione, la selezione, l\'estrazione, il raffronto, l\'utilizzo, l\'interconnessione, il blocco, la comunicazione, la diffusione, la cancellazione e la distruzione di dati, anche se non registrati in una banca-dati. In particolare per agevolare l\'accesso al credito al consumo e ridurre il rischio di eccessivo indebitamento da parte degli interessati sono previst adeguate garanzie e modalità di trattamento dei dati a tutela dei diritti degli interessati. Tal modalità sono contenute nel Codice di deontologia e di buona condotta per i sistemi informativi gestit da soggetti privati in tema di crediti al consumo, affidabilità e puntualità nei pagamenti consultabil sul sito dell’'} /><a style={{color: 'inherit'}} href="http://www.garanteprivacy.it/" tabIndex={0}><Highlighter autoEscape={true} searchWords={keywords} textToHighlight={'Autorità garante della privacy'} /></a>.
              </StyledP>
            </GlossaryCard>
          </GlossarySlide>
        </SplideSlide>
      </Splide>
    </Container>
  )
}

// region Style
const Container = styled.div`
`
const StyledP = styled(P)`
  color: #AEAEAE;
  margin-bottom: 0;
`
const Em = styled.em`
  font-style: normal;
  color: #FCBB00;
`
// endregion

export default Glossary
