import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { baseUrl } from '../../../config'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import styled from 'styled-components'
import ArticleCard from './ArticleCard'
import {IArticle} from '../../../types'
import qs from 'query-string'

const Articles = () => {
  const [articles, setArticles] = useState<any>([])
  const getAllArticles = () => {
    const filters = {
      and: 'published=true',
      order: 'DESC',
      order_field: 'publicationDate'
    }
    const filtersStringify = qs.stringify(filters)
    const getEndpoint = `${baseUrl}/articles?${filtersStringify}`

    axios
      .get(getEndpoint)
      .then(({ data }) => {
        setArticles(data.payload?.filter((article: any) => article.category?.id !== 3))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getAllArticles()
  }, [])

  return (
    <>
      <Header></Header>
      <Container>
        <SectionTitle>Articoli</SectionTitle>
        <Section>
          {articles &&
            articles.map((a: IArticle, idx: number) => (
              <ArticleCard article={a} key={idx}/>
            ))}
        </Section>
      </Container>
      <Footer></Footer>
    </>
  )
}

export default Articles

const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 150px;
`
const SectionTitle = styled.h2`
  margin: 2rem 0;
`
const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`
