import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import Bold from '../../commons/Bold'

const HowDoesItWorkText = () => {
  return (
    <Container>
      <H2 style={{marginTop: '3rem'}}>COME FUNZIONA LA CESSIONE DEL QUINTO?</H2>
      <P>Si tratta di un finanziamento con tasso fisso e un rimborso rateale costante.</P>
      <P>
        A differenza dei normali prestiti personali, in cui la rata viene pagata alla banca o alla società finanziaria tramite bollettino postale o RID
        (rapporto interbancario diretto, cioè addebito automatico sul conto corrente), con la cessione del quinto dello stipendio <Bold>l'azienda o
        l'amministrazione pubblica del lavoratore effettua il pagamento della rata</Bold>, trattenendola ogni mese direttamente dalla busta paga.
      </P>
      <P>
        Lo stesso discorso vale per la cessione del quinto della pensione, in cui a pagare la rata è <Bold>l'ente previdenziale</Bold> tramite trattenuta sul cedolino della pensione.
      </P>
    </Container>
  )
}

// region Style
const Container = styled.div`
  margin-bottom: 3rem;
`
// endregion

export default HowDoesItWorkText
