import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import Bold from '../../commons/Bold'

const PayrollDeductionLoanText = () => {
  return (
    <Container>
      <H2 style={{marginTop: '3rem'}}>COS'È LA CESSIONE DEL QUINTO?</H2>
      <P>
        La <Bold>cessione del quinto (CQ)</Bold> è una particolare <Bold>forma di prestito</Bold> non finalizzato, garantito e a tasso fisso che può essere richiesto da lavoratori e
        pensionati del settore pubblico e privato in cui l'importo della rata mensile non può essere superiore al valore di un quinto dello stipendio netto mensile
        o della pensione netta mensile. Per l'estinzione del debito, il pagamento delle rate avviene tramite trattenute effettuate direttamente sulla busta paga o sulla pensione.
      </P>
      <P>
        A seconda che il richiedente sia un lavoratore dipendente o un pensionato, si parla di <Bold>"cessione del quinto dello stipendio"</Bold> e <Bold>"cessione del quinto della pensione"</Bold>.
      </P>
    </Container>
  )
}

// region Style
const Container = styled.div`
  margin-bottom: 3rem;
`
// endregion

export default PayrollDeductionLoanText
