import React, {useState} from 'react'
import styled from 'styled-components'
import P from '../../commons/P'
import H3 from '../../commons/H3'
import {IAgent} from '../../../types'
import {regions} from '../../../constants'

type AgentInfoProps = {
  agents: IAgent[]
}

const getAgentsByRegion = (agents: IAgent[]): any => {
  const agentsByRegion: any = {}
  Object.values(regions).forEach(regionName => agentsByRegion[regionName] = [])

  agents.forEach((agent: IAgent)=> {
    // Se la regione dell'agent non è specificata nell'elenco delle regioni, non inserisco l'agent
    if(!agentsByRegion[agent.region]) return
    agentsByRegion[agent.region].push(agent)
  })

  return agentsByRegion
}

const AgentsInfo = ({agents}: AgentInfoProps) => {
  const [agentsByRegion] = useState(getAgentsByRegion(agents))

  return (
    <>
      {Object.keys(agentsByRegion).sort((a: string, b: string) => a > b ? 1 : -1).map((regionName: string) => {
        if(agentsByRegion[regionName].length > 0) return (
          <div key={regionName} style={{marginBottom: '2.5rem', textAlign: 'justify'}}>
            <RegionNameContainer id={'region-name'}>{regionName}</RegionNameContainer>
            {agentsByRegion[regionName].sort((a: IAgent, b: IAgent) => a.name > b.name ? 1 : -1).map((agent: IAgent) =>
              <div key={agent.name + agent.address}>
                <Anchor id={agent.name + agent.address}/>
                <StyledP key={agent.oam + agent.address} id={agent.name + agent.address}>
                  <span>{agent.name}</span> <br/>
                  N. OAM {agent.oam} - Agente in attività finanziaria con mandato diretto per Banca Sistema S.p.A<br/>
                  <StyledA href={`http://maps.google.com/?q=${agent.address} ${agent.city}`} target={'_blank'}>{agent.address} {agent.city}</StyledA><br/>
                  {agent.email && <><StyledA href={`mailto:${agent.email}`}>{agent.email}</StyledA><br/></>}
                </StyledP>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

// region Style
const RegionNameContainer = styled(H3)`
  margin-bottom: 0.50em;


    text-align: left;
    color: inherit;
    background-color: #FCBB00;
    font-weight: 700;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-out;
    font-size: 1.75rem;
    border-radius: 2.3rem;
    min-height: 4.6rem;
    padding: 1rem 2rem 1rem 2rem;
    letter-spacing: 1px;

`
const StyledP = styled(P)`
  text-align: initial;
    
    & span {
        font-weight: bold;
    } 
    
`
const StyledA = styled.a`
  :hover {
    font-weight: bold;
    color: #FCBB00;
  };
  cursor: pointer;
  color: #222;
  transition: all .3s ease-in-out;
`

// Componente che serve solamente ad aggiustare l'altezza dell'ancora.
// Il link all'ancora si trova sui marker della mappa nella pagina "La nostra rete"
const Anchor = styled.div`
  display: block;
  position: relative;
  top: -250px;
  visibility: hidden;
`
// endregion

export default AgentsInfo
