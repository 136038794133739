import React from 'react'
import styled from 'styled-components'
import H1 from '../commons/H1'
import P from '../commons/P'

const PreventivatoreFormHeader = () => {
  return (
    <>
      <StyledH1>
        Scopri la nostra cessione del quinto. <Em>Richiedi qui il tuo prestito!</Em>
      </StyledH1>
      <StyledP>
        Il finanziamento è rivolto a pensionati in convenzione INPS, lavoratori assunti a
        tempo indeterminato da aziende SpA/Srl con più di 15 dipendenti, dipendenti
        pubblici/statali/parapubblici
      </StyledP>
    </>
  )
}

// region Style
const Em = styled.em`
  font-style: normal;
  color: #FCBB00;
`
const StyledH1 = styled(H1)`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  padding-bottom: 2.4rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const StyledP = styled(P)`
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  font-size: 1em;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
// endregion

export default PreventivatoreFormHeader
