import React, {useMemo, useState} from 'react'
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps"
import {ReactComponent as Pin} from '../../../img/pin.svg'
import {ReactComponent as QpPin} from '../../../img/qp_pin.svg'
import { Tooltip } from 'react-tooltip'
import {useMediaQuery} from 'react-responsive'
import styled from 'styled-components'

//const geoUrl = 'https://raw.githubusercontent.com/deldersveld/topojson/master/countries/italy/italy-regions.json'
const tooltipId = 'qp-map-marker-tooltip'

const AgentsMap = ({agents}: any) => {
  const [scaleFactor, setScaleFactor] = useState(1)
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const mapHeight = useMemo(() => {
    return isMobile ? 800 : 420
  }, [isMobile])

  return (
    <>
      <Tooltip id={tooltipId} />
      <ComposableMap
        projection='geoEqualEarth'
        projectionConfig={{
          scale: isMobile ? 4000 : 2200,
          center: [12, 41.5]
        }}
        cursor={'grab'}
        height={mapHeight}
      >
        <ZoomableGroup
          translateExtent={[
            [0, 0],
            [800, mapHeight]
          ]}
          maxZoom={3}
          onMove={({ zoom }) => setScaleFactor(zoom)}
        >
          <Geographies geography={'/italy.json'} style={{height: '50%'}}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#EAEAEC"
                  stroke="#222222"
                  strokeWidth={0.4}
                  style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                  }}
                />
              ))
            }
          </Geographies>
          {agents.map(({ name, coordinates, associated, address, offsetX, offsetY }: any) => (
            <Marker
              key={name + address}
              coordinates={[coordinates[0] + (offsetX || 0), coordinates[1] + (offsetY || 0)]}
              data-tooltip-id={tooltipId} data-tooltip-content={name}
            >
              <MarkerIcon href={`#${name + address}`} scaleFactor={scaleFactor} isMobile={isMobile}>
                {associated ?
                  <QpPin
                    cursor='pointer'
                    width={isMobile ? 50 / scaleFactor : 22 / scaleFactor}
                    height={isMobile ? 50 / scaleFactor : 22 / scaleFactor}
                  /> :
                  <Pin
                    cursor='pointer'
                    width={isMobile ? 50 / scaleFactor : 22 / scaleFactor}
                    height={isMobile ? 50 / scaleFactor : 22 / scaleFactor}
                  />
                }
              </MarkerIcon>
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
    </>
  )
}

// region Style
const MarkerIcon = styled.a<{scaleFactor: number, isMobile: boolean}>`
  transform: ${({isMobile, scaleFactor}) => `translate(${(isMobile ? -20 : -10) / scaleFactor}px, ${(isMobile ? -45 : -18) / scaleFactor}px)`};
`
// endregion

export default AgentsMap
