import React, { useRef } from 'react'
import styled from 'styled-components'
import '@splidejs/react-splide/css'
import { Options, Splide, SplideSlide } from '@splidejs/react-splide'
import ExamplesCard from '../ExamplesCard'
import AdvMessage, { IBodyMessage } from '../AdvMessage'
import H2 from '../../../commons/H2'

const firstBody: IBodyMessage = {
  firstSection:
    'Esempio rappresentativo di un prestito di cessione del quinto della Pensione riferito ad un pensionato di 65 anni. Importo totale del credito € 18.400,00 (importo netto erogato AL CLIENTE); TAN fisso 4,41%; ',
  strongSection: 'TAEG 5,56%',
  secondSection:
    '; le spese incluse nel costo totale del credito ovvero nel TAEG sono: interessi € 4.604,38, costi di istruttoria € 600,00, costi intermediario del credito € 227,00, imposta sostitutiva € 48,19. Importo totale dovuto dal Consumatore: € 23.880; durata 120 mesi; importo rata mensile € 199,00. Le condizioni riportate nell’esempio indicato possono variare in funzione dell’età del cliente, dell’importo richiesto, della durata del finanziamento. L’importo erogato al cliente di cui all’esempio si intende al netto di tutte le spese e i costi trattenuti al momento della liquidazione. TAEG max applicabile 19,8%.'
}
const secondBody: IBodyMessage = {
  firstSection:
    'Esempio rappresentativo di un prestito di cessione del quinto dello stipendio riferito ad un dipendente pubblico/statale di 30 anni e 10 anni di servizio. Importo totale del credito € 25.000,00 (importo netto erogato AL CLIENTE); TAN fisso 3,11%;',
  strongSection: 'TAEG 3.80%',
  secondSection:
    '; le spese incluse nel costo totale del credito ovvero nel TAEG sono: interessi € 4.251,19, costi di istruttoria € 520,00, costi intermediario del credito € 164,00, imposta sostitutiva € 64,37. Importo totale dovuto dal Consumatore: € 30.000; durata 120 mesi; importo rata mensile € 250,00. Le condizioni riportate nell’esempio indicato possono variare in funzione dell’età del cliente, dell’anzianità di servizio, della natura giuridica del datore di lavoro, dell’importo richiesto, della durata del finanziamento. L’importo erogato al cliente di cui all’esempio si intende al netto di tutte le spese e i costi trattenuti al momento della liquidazione. TAEG max applicabile 19,8%.'
}
const thirdBody: IBodyMessage = {
  firstSection:
    'Esempio rappresentativo di un prestito di cessione del quinto dello stipendio riferito ad un dipendente privato di 35 anni e 15 anni di servizio. Importo totale del credito € 18.000,00 (importo netto erogato AL CLIENTE); TAN fisso 5.43%;',
  strongSection: 'TAEG 6.76%',
  secondSection:
    '; le spese incluse nel costo totale del credito ovvero nel TAEG sono: interessi € 5.657,00, costi di istruttoria € 640,00, costi intermediario del credito € 254,50, imposta sostitutiva € 47,35. Importo totale dovuto dal Consumatore: € 24.600; durata 120 mesi; importo rata mensile € 205,00. Le condizioni riportate nell’esempio indicato possono variare in funzione dell’età del cliente, dell’anzianità di servizio, della natura giuridica del datore di lavoro, dell’importo richiesto, della durata del finanziamento. L’importo erogato al cliente di cui all’esempio si intende al netto di tutte le spese e i costi trattenuti al momento della liquidazione. TAEG max applicabile 19,8%.'
}

const ExamplesSlider = ({data}: any) => {
  const sliderRef = useRef(null)
  const sliderOptions: Options = {
    arrows: false,
    drag: true
  }

  return (
    <>
      <StyledH2>ALCUNI ESEMPI</StyledH2>
      <SliderContainer>
        <Splide ref={sliderRef} aria-label="My Favorite Images" options={sliderOptions}>

          {data.map((feData: any, index: number) =>
            <SplideSlide key={index}>
              <Example>
                <ExamplesCard
                  title={feData?.title}
                  amount={feData?.amount}
                  amountPerMonth={feData?.installment}
                  installments={feData?.installmentNumber}
                  tan={feData?.tan}
                  taeg={feData?.taeg}
                />
                <AdvMessage
                  title={feData?.disclaimerTitle}
                  body={feData?.disclaimerText}/>
              </Example>
            </SplideSlide>
          )}
        </Splide>
      </SliderContainer>
    </>
  )
}

// region Style
const Example = styled.div`
  max-width: 1140px;
  flex-basis: calc(95% / 3);
  margin-bottom: 5rem;
`
const SliderContainer = styled.div`
  margin: 3rem;
`
const StyledH2 = styled(H2)`
  margin-top: 8rem;
`
// endregion

export default ExamplesSlider
