import React from 'react'
import styled from 'styled-components'
import H4 from '../H4'

interface FooterLinkProps {
  link?: string
  linkStyle?: React.CSSProperties
  title?: string
  subtitle?: string
}

const FooterLink = ({link, linkStyle = {}, title, subtitle}: FooterLinkProps) => {
  return (
    <StyledA href={link} style={linkStyle}>
      <Title>
        <StyledH4>
          {title}
        </StyledH4>
        {subtitle &&
          <Subtitle>
            {subtitle}
          </Subtitle>
        }
      </Title>
    </StyledA>
  )
}

// region Style
const StyledA = styled.a`
  :hover {
    box-shadow: 0 3px 18px 0 rgb(45 46 46 / 15%);
  }

  width: fit-content;
  padding-left: 6rem!important;
  background-position: 2.4rem center;
  
  margin-bottom: 2rem;
  
  min-width: 18rem;
  
  background-repeat: no-repeat;
  
  padding: 2rem 3rem;
  border-radius: 6rem;
  min-height: 9rem;
  
  position: relative;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.1s ease-out;
  font-size: 1.3rem;
  background-color: #F5F5F5;
  letter-spacing: 1px;
  
  ext-align: center !important;
  
  color: inherit;
  
  margin: 0
`
const Title = styled.span`
  white-space: normal;
  display: inline;
  padding-top: 0.25em;
  transition: all 0.1s ease-out;
  color: #222;
`
const StyledH4 = styled(H4)`
  margin-bottom: 0;
`
const Subtitle = styled.p`
  font-weight: 700;
  margin-top: 0.4rem;
  margin-bottom: 0;
  color: #222;
  font-size: 1.1rem;
  line-height: 1.3;
  display: block; 
`
// endregion

export default FooterLink
