import React, { useState, useEffect } from 'react'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { baseUrl, thumbnailUrl } from '../../../config'
import axios from 'axios'
import CustomHTML from '../../commons/CustomHTML'
import SEO from '../../commons/SEO'
import {IArticle} from '../../../types'

const ArticlePage = () => {
  const [article, setArticle] = useState<IArticle | null>(null)

  const { id } = useParams()

  const getArticle = () => {
    const getByIdEndpoint = `${baseUrl}/articles/${id}`
    axios
      .get(getByIdEndpoint)
      .then(({ data }) => {
        setArticle(data.payload)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getArticle()
  }, [id])

  return (
    <>
      <SEO title={article?.metaTitle || ''} description={article?.metaDescription || ''}/>
      <Header></Header>
      {article && (
        <Main>
          <HeroImage style={{ backgroundImage: `url(${thumbnailUrl}/${article?.thumbnail})` }}/>
          <Container>
            <TitleBox>
              <Title> {article?.title}</Title>
              <Subtitle> {article?.subtitle}</Subtitle>
            </TitleBox>
            <ContentBox className={'article-container'}>
              <CustomHTML html={article?.content || ''} />
            </ContentBox>
          </Container>
        </Main>
      )}

      <Footer></Footer>
    </>
  )
}

export default ArticlePage

// region Style
const Main = styled.div`
  * {
    font-family: inherit!important;
  }
  
  p, span {
    font-size: 1.5rem!important;
  }
  
  h2 * {
    font-size: 3rem!important;
  }
  
  h3 * {
    font-size: 2rem!important;
  }
  
  a {
    text-decoration: underline!important;
    font-weight: bold!important;
    cursor: pointer!important;
    color: #222222!important;
  }
  width: 100%;
  margin-top: 135px;
  @media (max-width: 992px) {
    margin-top: 0px;
  }
`
const HeroImage = styled.div`
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 300px) {
    height: 250px;
  }

  @media (min-width: 301px) and (max-width: 450px) {
    height: 335px;
  }

  @media (min-width: 451px) and (max-width: 1000px) {
    height: 430px;
  }

  @media (min-width: 1200px) {
    height: 550px;
  }

  @media (min-width: 1500px) {
    height: 670px;
  }

  @media (min-width: 1921px) {
    height: 750px;
  }

  @media (min-width: 2700px) {
    height: 900px;
  }
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 150px;
`
const TitleBox = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: rgb(252, 187, 0);
  border-radius: 12rem;
  margin-top: -240px;
  padding: 5rem 0;
  box-shadow: 4px 7px 11px -2px rgba(112, 112, 112, 0.62);
  @media (max-width: 500px) {
    width: 100%;
  }
`
const Title = styled.h1``
const Subtitle = styled.h3`
  width: 90%;
  margin: 3rem auto 0 auto;
`
const ContentBox = styled.div`
  margin-top: 10rem;
  text-align: start;
`
//endregion
