import { UtmsType } from '../pages/landingGenerale/LandingGenerale'
import moment from 'moment'

// Funzione per creare il campo "campagna" da inviare al backend al submit dei preventivatori
export const createCampaign = (utms: UtmsType, landingPage: string) => {
  const campaign = {
    canale: utms.utm_medium || null,
    id_campagna: utms.utm_content || null,
    sorgente: utms.utm_source || null,
    landing_atterraggio: landingPage,
    data_creazione: moment().format('YYYY-MM-DD HH:mm:ss')
  }

  return campaign
}
