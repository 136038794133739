import React from 'react'
import styled from 'styled-components'

const H2 = styled.h2`
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0.75em;
  @media (max-width: 767px) {
    font-size: 2rem;
  }
  font-size: 3rem
`

export default H2
