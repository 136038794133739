import React from 'react'
import styled from 'styled-components'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import H1 from '../../commons/H1'
import P from '../../commons/P'
import mainBackground from '../../../img/bg_intro.jpg'

const Privacy = () => {
  return (
    <>
      <Header/>
      <Main>
        <PageContainer>
          <Container>
            <Row>
              <RowInnerContainer>
                <H1 style={{marginBottom: '0.75em'}}>Privacy</H1>
                <P>
                  Gentile Cliente,<br/>
                  in questo documento, nel rispetto della normativa in materia di protezione dei dati e tutela della vita privata nel settore delle comunicazioni elettroniche, Banca Sistema S.p.A. La aiuta a capire quali dati vengono raccolti durante la navigazione web e come questi vengono utilizzati.<br/>
                  Le indicazioni fornite di seguito si ispirano alla Raccomandazione n. 2/2001 che le Autorità europee per la protezione dei dati personali, riunite nel Gruppo istituito dall’art. 29 della Direttiva n. 95/46/CE, hanno adottato il 17 maggio 2001 per individuare alcuni requisiti minimi per la raccolta di dati personali on-line, e, in particolare le modalità, i tempi e la natura delle informazioni che i titolari del trattamento devono fornire agli Utenti quando questi si collegano a pagine web, indipendentemente dagli scopi del collegamento.
                </P>
                <P><strong>TITOLARE DEL TRATTAMENTO DEI DATI</strong></P>
                <P>Banca Sistema S.p.A., con sede legale in Milano, Largo Augusto 1/A, ang. via Verziere 13 – 20122 Milano (“Banca Sistema”).</P>
                <P>
                  IL RESPONSABILE PER LA PROTEZIONE DEI DATI<br/>
                  (DATA PROTECTION OFFICIER – DPO)
                </P>
                <P>Il Responsabile per la protezione dei dati (DPO) può essere contattato a:</P>
                <Ul>
                  <li>indirizzo postale di Banca Sistema S.p.A.: Largo Augusto 1/A, ang. via Verziere 13, 20122 Milano</li>
                  <li>indirizzo email: privacy@bancasistema.it</li>
                </Ul>
                <P><strong>TIPOLOGIE DI DATI OGGETTO DI TRATTAMENTO</strong></P>
                <P><strong>DATI DI NAVIGAZIONE</strong></P>
                <P>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.</P>
                <P>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche sull’uso del sito e per controllarne il corretto funzionamento.</P>
                <P><strong>DATI FORNITI VOLONTARIAMENTE DALL’UTENTE</strong></P>
                <P>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste e degli eventuali altri dati personali inseriti nella missiva. Specifiche informative di sintesi potrebbero progressivamente esser riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.</P>
                <P><strong>MODALITÀ DI TRATTAMENTO</strong></P>
                <P>Il trattamento dei dati avviene mediante strumenti manuali, informatici e telematici con logiche strettamente correlate alle finalità per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti e non corretti ed accessi non autorizzati.</P>
                <P>Questo trattamento ha luogo nei limiti in cui Lei abbia prestato il Suo consenso facoltativo. Lei ha il diritto di chiedere in qualsiasi momento a chi fa uso di tali soluzioni la cancellazione delle informazioni raccolte tramite cookie.</P>
                <P><strong>DIRITTI DEGLI INTERESSATI</strong></P>
                <P>L’utente potrà, in qualunque momento, esercitare i diritti di cui agli articoli da 15 a 23 del GDPR rivolgendosi a:</P>
                <P>
                  Banca Sistema S.p.A.<br/>
                  Largo Augusto 1/A, ang. via Verziere 13 – 20122 Milano<br/>
                  Indirizzo E-mail: compliance&amp;antiriciclaggio@bancasistema.it<br/>
                  DPO email: privacy@bancasistema.it
                </P>
                <P>L’esercizio dei diritti indicati nella presente sezione non è soggetto ad alcun vincolo di forma ed è gratuito. Il Titolare del trattamento sarà tenuto a fornire informazioni in merito all’azione intrapresa dal soggetto interessato senza ingiustificato ritardo, e al più tardi entro un mese dal ricevimento della richiesta. Sono ammesse proroghe ai sensi dell’art. 12 comma 3 del Regolamento UE.</P>
                <P>In relazione ai trattamenti descritti nella presente informativa Lei potrà esercitare i seguenti diritti:</P>
                <Ul>
                  <li>diritto di accesso;</li>
                  <li>diritto di rettifica, anonimizzazione o cancellazione dei dati;</li>
                  <li>diritto di revoca del consenso prestato in precedenza in qualsiasi momento, ferma la liceità del trattamento basata sul consenso prima della revoca;</li>
                  <li>diritto di limitazione del trattamento;</li>
                  <li>diritto alla portabilità dei dati;</li>
                  <li>diritto di opposizione;</li>
                  <li>diritto di opposizione al marketing diretto;</li>
                  <li>diritto a proporre un reclamo al Garante per la protezione dei dati personali qualora ritenga che i propri diritti non siano stati rispettati.</li>
                </Ul>
                <P>Le eventuali rettifiche o cancellazioni o limitazioni del trattamento effettuate su richiesta dell’interessato – salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato – saranno comunicate dal Titolare del trattamento a ciascuno dei destinatari cui sono stati trasmessi i dati personali.</P>
                <P><strong>COMUNICAZIONE E DIFFUSIONE</strong></P>
                <P>I dati raccolti potranno essere trasferiti o comunicati ad altre società controllate o collegate alla Banca per attività strettamente connesse e strumentali all’operatività del servizio, come la gestione del sistema informatico, o per trattamenti effettuati dalle altre società del gruppo con le medesime finalità. I dati personali forniti dagli utenti che inoltrano richieste di invio di materiale informativo (brochure, materiale informativo, ecc.) sono utilizzati al solo fine di eseguire il servizio o la prestazione richiesta e sono comunicati a terzi nel solo caso in cui ciò sia a tal fine necessario (società che forniscono servizi di imbustamento, etichettatura, spedizione). Al di fuori di questi casi, i dati non saranno comunicati, né concessi ad alcuno, salvo previsione contrattuale o autorizzazione dei soggetti. In questo senso, i dati personali potrebbero essere trasmessi a terze parti, ma solo ed esclusivamente nel caso in cui:</P>
                <Ul>
                  <li>vi sia consenso esplicito a condividere i dati con terze parti;</li>
                  <li>vi sia la necessità di condividere con terzi le informazioni al fine di prestare il servizio richiesto;</li>
                  <li>ciò sia necessario per adempiere a richieste dell’Autorità Giudiziaria o di Pubblica Sicurezza.</li>
                </Ul>
                <P>Nessun dato derivante dal servizio web viene diffuso.</P>
                <P><strong>DOCUMENTAZIONE</strong></P>
                <Ul>
                  <li><A href={`${process.env.PUBLIC_URL}/uploads/04.2020_PRIVACY-SIC.pdf`}>INFORMATIVA SIC</A></li>
                  <li><A href={`${process.env.PUBLIC_URL}/uploads/Privacy_Informativa_202006.pdf`}>PRIVACY CLIENTI&nbsp;</A></li>
                </Ul>
              </RowInnerContainer>
            </Row>
          </Container>
        </PageContainer>
      </Main>
      <Footer/>
    </>
  )
}

// region Style
const Main = styled.div`
  @media(max-width: 990px) {
    margin-top: 70px;
  }
  margin-top: 128px;
  background-image: url(${mainBackground});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
const PageContainer = styled.div`
  padding-top: 4rem;
  display: block;
`
const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  margin-bottom: 3rem !important;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const RowInnerContainer = styled.div`
  text-align: initial;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Ul = styled.ul`
  list-style: disc;
  padding-left: 3rem;
  margin-bottom: 1em;
`
const A = styled.a`
  color: inherit;
`
// endregion

export default Privacy
