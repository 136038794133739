import React, {useState} from 'react'
import styled from 'styled-components'
import checkIcon from '../../../img/ico_check_brand.svg'

const DoubleTab = () => {
  const [showFirstTab, setShowFirstTab] = useState(true)
  const [teadsSent, setTeadsSent] = useState(false)

  const documentsOnClick = () => {
    setShowFirstTab(true)

    //@ts-ignore
    const teads = window.teads_e
    if(teads && !teadsSent) {
      setTeadsSent(true)
      teads.push({
        conversionType: 'Purchase'
      })
    }
  }

  return (
    <Container>
      <InnerContainer>
        <Row>
          <LinksContainer>
            <Switch onClick={documentsOnClick} isActive={showFirstTab}>
              DOCUMENTI PER ATTIVARE LA RICHIESTA
            </Switch>
            &nbsp;
            <Switch onClick={() => setShowFirstTab(false)} isActive={!showFirstTab}>
              CONDIZIONI ECONOMICHE DEL PRODOTTO
            </Switch>
          </LinksContainer>
        </Row>
        <Row>
          <TabContainer>
            <InnerTabContainer>
              <Tab isActive={showFirstTab}>
                <Ul>
                  <Li>
                    due documenti di identità in corso di validità;
                  </Li>
                  <Li>
                    codice fiscale o tessera sanitaria;
                  </Li>
                  <Li>
                    documentazione reddituale (ultimo cedolino pensione e ultima certificazione unica);
                  </Li>
                  <Li>
                    prospetto TFS rilasciato dall’ente pensionistico;
                  </Li>
                  <Li>
                    Iban per accredito dell’importo della liquidazione anticipato dalla banca.
                  </Li>
                </Ul>
              </Tab>
              <Tab isActive={!showFirstTab}>
                <Table>
                  <tbody>
                    <tr>
                      <Td style={{borderTop: 'none'}}>
                        <strong>DURATA DEL PIANO</strong>
                      </Td >
                      <Td style={{borderTop: 'none'}}>Da 1 a 96 mesi, con un minimo di 1 rata fino a un massimo di 3 rate</Td>
                    </tr>
                    <tr>
                      <Td>
                        <strong>TASSO</strong>
                      </Td>
                      <Td>Fisso</Td>
                    </tr>
                    <tr>
                      <Td>
                        <strong>COMMISSIONI DI ISTRUTTORIA</strong>
                      </Td>
                      <Td>Non previste</Td>
                    </tr>
                    <tr>
                      <Td>
                        <strong>IMPOSTA DI BOLLO</strong>
                      </Td>
                      <Td>Pari a € 16,00</Td>
                    </tr>
                  </tbody>
                </Table>
              </Tab>
            </InnerTabContainer>
          </TabContainer>
        </Row>
      </InnerContainer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  margin-top: 3rem;
  display: block;
`
const InnerContainer = styled.div`
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const LinksContainer = styled.div`
  text-align: center !important;
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const Switch = styled.div<any>`
  ${(props => props.isActive ? 'background-color: #FCBB00;' : '')}
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 0.1s ease-out;
  border-radius: 2.3rem;
  min-height: 4.6rem;
  padding: 1rem 3rem;
  font-weight: bold;
  font-size: 20px;
  color: #222;
`
const TabContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const InnerTabContainer = styled.div`

`
const Tab = styled.div<any>`
  @media (prefers-reduced-motion: reduce){
    transition: none;
  }
  ${(props => props.isActive ? 'display: block;' : 'display: none;')}
  background-color: #fff;
  padding: 50px;
  transition: opacity 0.15s linear;
`
const Ul = styled.ul`
  padding:0;
  text-align: initial;
  column-gap: 2rem;
  column-count: 2;
  column-fill: auto;
  list-style: none;
`
const Li = styled.li`
  text-align: initial;
  background-image: url(${checkIcon});
  min-height: 22px;
  background-position: left 9px;
  padding: 0.5rem 0 0.5rem 40px;
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 28px;
  color: #222;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 24px;
`
const Table = styled.table`
  text-align: initial;
  font-size: 16px;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
`
const Td = styled.td`
  border-top: 1px solid #dee2e6;
  border-top-color: #FCBB00;
  padding: 0.75rem;
  vertical-align: top;
`
// endregion

export default DoubleTab
