import React from 'react'
import styled from 'styled-components'
import { ICategory } from './Article'
import { VideoProps } from './Video'

const VideoDetail: React.FC<VideoProps> = ({ thumbnail, title, subtitle }) => {
  return (
    <>
      <VideoWrapper>
        <VideoContainer>
          <IFrame
            src={thumbnail}
            // src="https://www.youtube.com/embed/09ra4UluNTg"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
            allowFullScreen={true}
          />
        </VideoContainer>
        <VideoTitle>
          <TitleContainer>{title}</TitleContainer>
          <SubTitleContainer>{subtitle}</SubTitleContainer>
        </VideoTitle>
      </VideoWrapper>
    </>
  )
}

export default VideoDetail

const VideoContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  padding-top: 56.25%;
  display: block;
  content: '';
  box-sizing: inherit;
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
`
const VideoTitle = styled.div``

const TitleContainer = styled.h2`
  margin: 2rem 0;
  text-align: left;
  font-weight: 700;
  padding: 0 2rem;
`

const SubTitleContainer = styled.h5`
  text-align: left;
  font-weight: 700;
  padding: 0 2rem 2rem 2rem;
`
const VideoWrapper = styled.div`
  box-shadow: 4px 7px 11px -2px rgba(112, 112, 112, 0.62);
  border-radius: 1rem;
  margin-top: 5rem;
  width: 100%;
`
