import React, {useState} from 'react'
import styled from 'styled-components'
import H1 from '../../commons/H1'
import H4 from '../../commons/H4'
import P from '../../commons/P'
import Link from '../../commons/Link'
import {useNavigate} from 'react-router-dom';

const QuoteBanner = () => {
  const navigate = useNavigate()
  const [teadsSent, setTeadsSent] = useState(false)

  const linkOnClick = () => {
    //@ts-ignore
    const teads = window.teads_e
    if(teads && !teadsSent) {
      setTeadsSent(true)
      teads.push({
        conversionType: 'CompleteRegistration'
      })
    }
    navigate('/preventivatore-tfs')
  }

  return (
    <Container>
      <StyledH1>
        Richiedi un preventivo gratuito e senza impegno
      </StyledH1>
      <StyledH4>
        CON L'ANTICIPO TFS TI VERRÀ ACCREDITATA SUBITO LA LIQUIDAZIONE CHE TI SPETTA, EVITANDO LUNGHE ATTESE!
      </StyledH4>
      <LinkContainer>
        <Link onClick={linkOnClick} text={'CHIEDI ORA IL PREVENTIVO'}/>
      </LinkContainer>
      <StyledP>
        Il finanziamento è riservato a dipendenti pubblici e statali prossimi alla pensione, o già pensionati,
        <br/>assunti con contratto a tempo indeterminato prima del 1° gennaio 2001
      </StyledP>
    </Container>
  )
}

// region Style
const Container = styled.div`
  padding-top: 1rem;
  position: relative;
  max-width: 114rem;
  margin: 0 auto;
  z-index: 30;
  margin-top: -8rem;
  background-color: #222;
  border-radius: 4.5rem;
  padding-bottom: 1rem !important;
  display: block;
`
const StyledH1 = styled(H1)`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  position: relative;
  text-align: center;
  padding-top: 2.8rem;
  margin-bottom: 0;
  border-radius: 4.5rem 4.5rem 0 0;
  color: #fff;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  padding-bottom: 1rem !important;
`
const StyledH4 = styled(H4)`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  color: #FCBB00;
  font-size: 17px;
  text-align: center;
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const LinkContainer = styled.div`
  text-align: center !important;
  margin-bottom: 1.5rem !important;
`
const StyledP = styled(P)`
  margin-bottom: 0;
  @media (min-width: 769px) {
    margin: 0 7rem 1rem;
  }
  color: #fff !important;
  text-align: center;
  font-size: 1em;
  line-height: 1.4 !important;
  margin: 0 2rem 1rem;
`
// endregion

export default QuoteBanner
