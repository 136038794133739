import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import AbandonAlert from '../../commons/AbandonAlert'
import LandingHeader from '../../landingCommons/LandingHeader'
import headerBackground from '../../../img/HEADER_MOBILE-pubblico_new.jpg'
import smallHeaderBackground from '../../../img/LP-dipendenti_pubblici2_new.jpg'
import LandingIconsRow from '../../landingCommons/LandingIconsRow'
import LandingPreventivatore from '../../landingCommons/landingPreventivatore/LandingPreventivatore'
import FinancingExample from '../../landingCommons/FinancingExample'
import AdvantagesText from '../quintoPuoi/AdvantagesText'
import DipendentiPubbliciText from '../quintoPuoi/DipendentiPubbliciText'
import Link from '../../commons/Link'
import LandingProjectsBlock from '../../landingCommons/LandingProjectsBlock'
import LandingVideoBlock from '../../landingCommons/LandingVideoBlock'
import LandingFooter from '../../landingCommons/LandingFooter'
import pageBackground from '../../../img/bgpage.svg'
import H2 from '../../commons/H2'
import checkIcon from '../../../img/spli.svg'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import {baseUrl} from '../../../config'

// import smallHeaderBackground from '../../../img/top_d_pubblici.jpg'

const LandingDipendentiPubblici = () => {
  const location = useLocation()
  const [financingExampleData, setFinancingExampleData] = useState([])

  // Funzione per recuperare da backend il testo da mostrare per l'esempio di finanziamento
  const retrieveFinancingExampleData = async (): Promise<any> => {
    return await axios.get(`${baseUrl}/financing-examples/2`)
      .then(res => {
        return res.data.payload
      })
      .catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  useEffect(() => {
    //@ts-ignore
    const gtag = window['gtag']
    gtag && gtag('event', 'conversion', { send_to: 'AW-707945372/BimMCMTc86wBEJzHydEC' })

    retrieveFinancingExampleData()
      .then((data: any) => {
        setFinancingExampleData(data)
      })
      .catch((e: any) => console.log(e.message))
  }, [])

  return (
    <Background>
      <AbandonAlert />
      <LandingHeader backgroundImg={headerBackground} smallBackgroundImg={smallHeaderBackground} />
      <PageStart>
        <LandingMainContainer>
          <Row>
            <LandingIconsRow />
            <LandingPreventivatore
              routeAfterSubmit={'/landing-dipendenti-pubblici/grazie'}
              lpType={'qplandingpubblici'}
            />
          </Row>
          <FinancingExample data={financingExampleData}/>
          <Boxcon>
            <BoxconInner>
              <StyledH2>SOLUZIONI PER DIPENDENTE PUBBLICO</StyledH2>
              <TextRow>
                <TextContainer>
                  <AdvantagesText />
                  <DipendentiPubbliciText />
                </TextContainer>
              </TextRow>
            </BoxconInner>
            <LinkContainer>
              <Link text={'RICHIEDI PREVENTIVO GRATUITO**'} link={'#preventivatore2'} />
            </LinkContainer>
          </Boxcon>
          <LandingProjectsBlock />
          <LandingVideoBlock />
        </LandingMainContainer>
      </PageStart>
      <LandingFooter />
    </Background>
  )
}

// region Style
const PageStart = styled.div`
  @media (min-width: 769px) {
    margin-top: -5%;
  }
  margin-top: -21%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 2;
`
const Background = styled.div`
  margin: 0;
  background: #f3f3f3 url(${pageBackground}) no-repeat center 410px;
`
const LandingMainContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Boxcon = styled.div`
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  margin: 6rem 0;
`
const BoxconInner = styled.div`
  @media (min-width: 769px) {
    padding: 5rem;
  }
  padding: 1.5rem;
`
const StyledH2 = styled(H2)`
  font-weight: 700;
  font-size: 29px;
  color: #2e2e2e;
`
const TextRow = styled.div`
  h2 {
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 0.5px;
  }
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const TextContainer = styled.div`
  text-align: initial;
  @media (min-width: 992px) {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ul {
    padding: 0;
    margin-bottom: 1.5rem;
    list-style: none;
  }
  li {
    padding: 0.5rem 0 0.5rem 4.4rem;
    padding-left: 3.2rem;
    background: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: unset;
  }
`
const LinkContainer = styled.div`
  padding-bottom: 3rem;
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
// endregion

export default LandingDipendentiPubblici
