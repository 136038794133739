import React from 'react'
import styled from 'styled-components'
import H2 from '../commons/H2'

const LandingVideoBlock = () => {
  return (
    <Boxcon>
      <BoxconInner>
        <StyledH2>GUARDA IL VIDEO E SCOPRI QUANTO È SEMPLICE SOTTOSCRIVERE IL TUO FINANZIAMENTO CON LA FIRMA DIGITALE</StyledH2>
        <VideoContainer>
          <IFrame
            src="https://www.youtube.com/embed/09ra4UluNTg"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
            allowFullScreen={true}
          />
        </VideoContainer>
      </BoxconInner>
    </Boxcon>
  )
}

// region Style
const Boxcon = styled.div`
  background: #fff;
  border-radius: 33px;
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  margin: 6rem 0;
`
const BoxconInner = styled.div`
  @media (min-width: 769px){
      padding: 5rem;
  }
  padding: 1.5rem;
`
const StyledH2 = styled(H2)`
  text-align: initial;
  font-weight: 700;
  font-size: 29px;
  color: #2E2E2E;
`
const Span = styled.span`
  color: #FFC200;
`
const VideoContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  padding-top: 56.25%;
  content: "";
  box-sizing: inherit;
`
const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
`
// endregion

export default LandingVideoBlock
