// Testo mostrato sia nella pagina "quinto-puoi" che nella landing page "landing-dipendenti-pubblici"
import React from 'react'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import Ul from './Ul'
import Li from './Li'

const DipendentiPubbliciText = () => {
  return (
    <>
      <H2 style={{marginTop: '3rem'}}>REQUISITI</H2>
      <Ul>
        <Li><span>Per richiedere il finanziamento è necessario avere almeno 18 anni, essere un dipendente con un contratto di lavoro a tempo indeterminato ed essere residente in Italia.&nbsp;</span>
        </Li>
        <Li><span>La concessione del finanziamento è subordinata alla valutazione complessiva della posizione creditizia del Cliente e di conseguenza dell’importo oggetto di richiesta.</span>
        </Li>
      </Ul>
      <P style={{fontSize: '1rem'}}>
        Per informazioni economiche e contrattuali fare riferimento alle
        “Informazioni Europee di Base sul Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte
        le filiali della Banca e presso gli agenti in attività finanziaria che operano su mandato diretto
        della Banca per la promozione e il collocamento del prodotto QuintoPuoi. La concessione del
        finanziamento è subordinata all’approvazione di Banca Sistema S.p.A..
      </P>
      <H2 style={{marginTop: '3rem'}}>DOCUMENTI</H2>
      <Ul>
        <Li>Un documento di riconoscimento valido&nbsp;</Li>
        <Li>Il codice fiscale</Li>
        <Li>Il certificato di stipendio o l’Attestato di servizio o allegati ministeriali</Li>
        <Li>Ultime due buste paga</Li>
        <Li>La Certificazione Unica</Li>
      </Ul>
    </>
  )
}

export default DipendentiPubbliciText
