import React, { useState } from 'react'
import styled from 'styled-components'
import AdvMessage from './AdvMessage'
import ExamplesCard from './ExamplesCard'

const Examples = ({data}: any) => {
  const [hovering, setHovering] = useState(1)

  return (
    <ExampleContainer>
      <div>
        <h2 style={{ textTransform: 'uppercase', fontSize: '3rem' }}>Alcuni Esempi</h2>
        <Row
          style={{
            display: 'flex',
            marginTop: '70px',
            flexWrap: 'wrap',
            gap: '0 20px'
          }}>
          {data?.map((feData: any, index: number) =>
            <Example
              key={index}
              onMouseOver={() => {
                setHovering(index)
              }}
              onMouseOut={() => {
                setHovering(1)
              }}>
              <ExamplesCard
                title={feData?.title}
                amount={feData?.amount}
                amountPerMonth={feData?.installment}
                installments={feData?.installmentNumber}
                tan={feData?.tan}
                taeg={feData?.taeg}
                classHover={hovering === index ? 'is-highlighted-in-hp' : ''}/>
              <AdvMessage
                title={feData?.disclaimerTitle}
                body={feData?.disclaimerText}/>
            </Example>
          )}
        </Row>
      </div>
    </ExampleContainer>
  )
}

export default Examples

const Example = styled.div`
  max-width: 1140px;
  flex-basis: calc(95% / 3);
`

const ExampleContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  margin-top: 100px;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  align-items: center;
`
