import React from 'react'
import Header from '../commons/Header'
import Footer from '../commons/footer/Footer'

const NotFound = () => {
  return (
    <>
      <Header/>
      <div style={{textAlign: 'start'}}>
        Sorry, we couldn't find what you're looking for.
      </div>
      <Footer/>
    </>
  )
}

export default NotFound
