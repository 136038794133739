import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ThanksFooter from './ThanksFooter'
import thanksBackground from '../../../img/gr_bgpage.jpg'
import thanksLogo from '../../../img/gr_bglogo_2x.png'
import H1 from '../../commons/H1'
import P from '../../commons/P'
import {useLocation} from 'react-router-dom'
import prestitopuoiLogo from "../../../img/prestitopuoi_logo_2rows_white.png";
import SEO from "../../commons/SEO";

// region Helpers
// Funzione per triggerare l'evento di conversione generico Google
const handleGenericGoogleConversion = () => {
  console.log('Handling generic thank you page conversion')
  //@ts-ignore
  const gtag = window['gtag']
  gtag && gtag('event', 'conversion', {'send_to': 'AW-707945372/SgsBCMec1-cYEJzHydEC'})
}

// Funzione per triggerare l'evento di conversione per il prodotto PrestitoPuoi
const handlePPGoogleConversion = () => {
  console.log('Handling PP thank you page conversion')
  //@ts-ignore
  const gtag = window['gtag']
  gtag && gtag('event', 'conversion_event_request_quote', {
    // <event_parameters>
  })
}

// endregion

const Thanks = () => {
  const location = useLocation()
  const [showPublicEmployeeRetiredDEM, setShowPublicEmployeeRetiredDEM] = useState(false)
  const [showPrivateEmployeeDEM, setShowPrivateEmployeeDEM] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // Funzione per triggerare pixel DEM
  const handleDEMPixel = () => {
    // Prendo i dati sulla scelta della professione salvati nel local storage
    const selectData = localStorage.getItem('selectData')
    if(selectData) {
      // Se è stato selezionato Pubblico-statale o Pensionato, mostro un pixel
      const selectedProfession = JSON.parse(selectData).find((item: any) => item.category === 'macroprofessione')
      if(selectedProfession && selectedProfession.value && (selectedProfession.value === 'Pubblico-Statale' || selectedProfession.value === 'Pensionato')) {
        setShowPublicEmployeeRetiredDEM(true)
        setShowPrivateEmployeeDEM(false)
      }
      // Altrimenti se è stato selezionato "Privato", mostro un altro pixel
      if(selectedProfession && selectedProfession.value && selectedProfession.value === 'Privato') {
        setShowPrivateEmployeeDEM(true)
        setShowPublicEmployeeRetiredDEM(false)
      }
    }
  }

  useEffect(() => {
    // Se sono arrivato dalla pagina landing-generale, devo triggerare i pixel DEM e l'evento di conversione generico Google
    if(location.pathname.includes('landing-generale')) {
      handleDEMPixel()
      handleGenericGoogleConversion()
    }

    // Se sono arrivato dalla pagina landing-prestitopuoi, devo triggerare l'evento di conversione generico Google
    if(location.pathname.includes('landing-prestitopuoi')) {
      handlePPGoogleConversion()
    }
  }, [])

  const seoTitle = 'PrestitoPuoi'
  const seoDescription = "PrestitoPuoi"

  return (
    <>
      <SEO title={seoTitle} description={seoDescription}/>
      <Container>
        <LogoContainer>
          <img src={prestitopuoiLogo} width={'380px'} alt={'prestitopuoi-logo'}/>
        </LogoContainer>
        <TextContainer>
          <TextInnerContainer>
            <StyledH1>
              Grazie per la tua richiesta
            </StyledH1>
            <UpperTextP>
              Verrai ricontattato dal team di Banca Sistema per una proposta di finanziamento su misura per te!
            </UpperTextP>
          </TextInnerContainer>
        </TextContainer>
        {showPublicEmployeeRetiredDEM && <img src="https://trk.lolaleadvr.com/aff_l?offer_id=7182&adv_sub=DEM" width="1" height="1"/>}
        {showPrivateEmployeeDEM && <img src="https://trk.lolaleadvr.com/aff_goal?a=l&goal_name=privati&adv_id=1564&offer_id=7182&adv_sub=DEM" width="1" height="1" />}
      </Container>
      <ThanksFooter/>
    </>
  )
}

// region Style
const Container = styled.div`
  background: url(${thanksBackground}) no-repeat center top/cover;
  padding: 0 20px;
`
const LogoContainer = styled.div`
  background: url(${thanksLogo}) no-repeat center top/contain;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  max-width: 525px;
  max-height: 227px;
`
const TextContainer = styled.div`
  padding-bottom: 200px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const TextInnerContainer = styled.div`
  @media (min-width: 992px){
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  text-align: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledH1 = styled(H1)`
  @media (max-width: 769px){
    font-size: 35px;
  }
  font-size: 70px;
  color: #dede;
  text-transform: uppercase;
  margin-top: 60px;
  margin-bottom: 10px;
`
const UpperTextP = styled(P)`
  @media (max-width: 769px){
    font-size: 20px;
  }
  font-size: 30px;
  font-family: TradeGothicLTPro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  padding-bottom: 1.5rem;
`
// endregion

export default Thanks
