import React, {useEffect} from 'react'
import styled from 'styled-components'
import mainBackground from '../../../img/bg_intro.jpg'
import headerBackground from '../../../img/pgtfs_emo_2x-scaled.jpeg'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import TitleSection from '../../commons/TitleSection'
import QuoteBanner from './QuoteBanner'
import QuoteInfo from './QuoteInfo'
import Benefits from './Benefits'
import DoubleTab from './DoubleTab'
import UsefulDocuments from './UsefulDocuments'
import ChooseBS from './ChooseBS'
import FAQs from './FAQs'
import { UtmsType } from '../../pages/landingGenerale/LandingGenerale'
import SEO from '../../commons/SEO'

const seoTitle = 'Anticipo tfs: la tua liquidazione in anticipo | QuintoPuoi'
const seoDescription = "Anticipo tfs: scopri cos'è e come funziona l'anticipo tfs per i dipendenti pubblici e statali, quali sono i vantaggi e le procedure per poterlo ottenere."

const AnticipoTfs = () => {

  // Recupero utms da query string e salvo valori dell'utms in localStorage
  useEffect(()=>{
    const urlParamsLand = new URLSearchParams(window.location.search);
    if(urlParamsLand.has('utm_source')) {
      let utms: UtmsType = {}
      utms.utm_source = urlParamsLand.get('utm_source')
      utms.utm_medium = urlParamsLand.get('utm_medium')
      utms.utm_content = urlParamsLand.get('utm_content')
      utms.utm_campaign = urlParamsLand.get('utm_campaign')
      utms.utm_term = urlParamsLand.get('utm_term')
      localStorage.setItem('qp_utms',JSON.stringify(utms))
    }
  }, [])

  return (
    <>
      <SEO title={seoTitle} description={seoDescription}/>
      <Header/>
      <TitleSection backgroundUrl={`url(${headerBackground})`} title={'ANTICIPO TFS'} subtitle={'La tua liquidazione per i tuoi progetti'}/>
      <Main>
        <QuoteBanner/>
        <QuoteInfo/>
        <Benefits/>
        <DoubleTab/>
        <UsefulDocuments/>
        <ChooseBS/>
        <FAQs/>
      </Main>
      <Footer/>
    </>
  )
}

// region Style
const Main = styled.div`
  background-image: url(${mainBackground});
  opacity: 1;
  position: relative;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  display: block;
  transition: all 0.1s ease-out;
  background-position: center 22rem;
  background-repeat: no-repeat;
`
// endregion

export default AnticipoTfs
