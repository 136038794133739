import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import Collapsable from '../../commons/footer/Collapsable'
import P from '../../commons/P'

// region Testi FAQ
const collapsable1Title = 'Cos’è l’Anticipo del Trattamento di Fine Servizio (TFS)?'
const Collapsable1Text = () => {
  return (
  <CollapsableTextContainer>
    <CollapsableTextP style={{marginBottom: 0}}>
      L’Anticipo del Trattamento di Servizio in quiescenza (TFS) è un finanziamento a tasso fisso riservato ai Pensionati ex
      Dipendenti Pubblici e Statali che vogliono ricevere in anticipo la loro liquidazione maturata, in un’unica soluzione.
      L’Anticipo del TFS è garantito dalla cessione pro solvendo in favore della Banca dei crediti derivanti dal Trattamento
      di Fine Servizio maturato.
    </CollapsableTextP>
  </CollapsableTextContainer>
  )
}

const collapsable2Title = 'Come avviene il rimborso del prestito con l’Anticipo TFS?'
const Collapsable2Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        Non è previsto il rimborso mediante rate mensili decurtate dalla pensione. A rimborso del finanziamento la
        Banca riceverà dall’ente previdenziale il TFS oggetto di cessione da parte del cliente.
        Inoltre, la banca tratterrà direttamente dalla somma spettante al Cliente (TFS) gli interessi
        relativi al servizio finanziario erogato, ossia all’anticipo del TFS. In caso di mancato rimborso da parte
        dell'ente previdenziale, il Cliente dovrà restituire alla Banca l’anticipo ricevuto.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable3Title = 'Quali sono le tempistiche per l’erogazione dell’Anticipo del TFS?'
const Collapsable3Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        Le tempistiche di erogazione dell’Anticipo del TFS sono strettamente collegate alla ricezione della documentazione
        relativa alla presa d’atto da parte dell’INPS. In caso di esito positivo, l’operazione si conclude nell’arco di 30 giorni.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable4Title = 'È possibile richiedere un’estinzione anticipata dell’Anticipo TFS?'
const Collapsable4Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        Si, è sempre possibile richiedere un’estinzione anticipata dell’Anticipo del Trattamento di Fine Servizio.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable5Title = 'Come faccio ad ottenere un Anticipo del TFS?'
const Collapsable5Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        È possibile richiedere informazioni o un preventivo gratuito contattando il numero verde 800 124 344 di Banca Sistema.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable6Title = "C'è differenza tra TFR e TFS?"
const Collapsable6Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        Il&nbsp;<strong>TFR</strong>&nbsp;(Trattamento di fine rapporto) spetta a tutti i&nbsp;lavoratori dipendenti del settore pubblico&nbsp;(rientranti nel pubblico impiego contrattualizzato) e del settore privato.<br/>
        Il&nbsp;<strong>TFS</strong>&nbsp;(Trattamento di fine servizio) è sempre una somma di denaro corrisposta alla cessazione del lavoro ma in questo caso&nbsp;si riferisce solo ai dipendenti pubblici assunti a tempo indeterminato prima del 1° gennaio 2001.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}

const collapsable7Title = "Posso ricevere l'anticipo TFS in un'unica soluzione?"
const Collapsable7Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP>In base alla normativa di riferimento, l’erogazione dell’anticipo TFS può avvenire secondo le seguenti
        casistiche:
      </CollapsableTextP>
      <Ul style={{marginBottom: 0}}>
        <li>in un’unica soluzione se l’importo è pari o inferiore a 50.000€;</li>
        <li>
          in due rate annuali se l’importo è compreso tra 50.000€ euro e inferiore 100.000€ (in tal caso la prima
          rata sarà pari a 50.000 € mentre la seconda all’importo residuo);
        </li>
        <li>in tre rate annuali, se l’importo è pari o superiore a 100.000€.</li>
      </Ul>
    </CollapsableTextContainer>
  )
}

const collapsable8Title = "Servono delle garanzie per richiedere l'anticipo TFS?"
const Collapsable8Text = () => {
  return (
    <CollapsableTextContainer>
      <CollapsableTextP style={{marginBottom: 0}}>
        No, per l’erogazione anticipata del TFS <strong>non servono particolari garanzie</strong>. Nel dettaglio, non sono necessarie firme a garanzia, cambiali, ipoteche o altre tipologie di garanzie reali o personali. Per quanto riguarda i <strong>dipendenti pubblici e statali</strong> che desiderano ricevere l’anticipo del TFS è importante essere in possesso del <strong>“Prospetto di liquidazione del TFS”</strong>. Lo Stato, a tutela degli enti che anticipano il TFS, ha comunque previsto un Fondo di Garanzia ad hoc, attraverso l’articolo 23, comma 3, decreto-legge 4/2019.
      </CollapsableTextP>
    </CollapsableTextContainer>
  )
}
// endregion

const FAQs = () => {
  return (
    <FAQ>
      <Container>
        <InnerContainer>
          <StyledH2>
            FAQ TFS
          </StyledH2>
          <CollapsableContainer>
            <CollapsableWrapper>
              <Collapsable title={collapsable1Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable1Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable2Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable2Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable3Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable3Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable4Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable4Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable5Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable5Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable6Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable6Text/>
              </Collapsable>
            </CollapsableWrapper>
            <CollapsableWrapper>
              <Collapsable title={collapsable7Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
                <Collapsable7Text/>
              </Collapsable>
            </CollapsableWrapper>
            <Collapsable title={collapsable8Title} titleStyle={{textTransform: 'uppercase'}} lightTheme={true}>
              <Collapsable8Text/>
            </Collapsable>
          </CollapsableContainer>
          <StyledP>
            (*) Per informazioni economiche e contrattuali fare riferimento alle “Informazioni Europee di Base sul
            Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte le filiali della Banca e presso gli agenti
            in attività finanziaria che operano su mandato diretto della Banca per la promozione e il collocamento del
            prodotto Anticipo TFS. La concessione del finanziamento è subordinata all’approvazione di Banca Sistema
            S.p.A..
          </StyledP>
        </InnerContainer>
      </Container>
    </FAQ>
  )
}

// region Style
const FAQ = styled.div`
  @media (min-width: 993px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  margin-top: 3rem;
  display: block;
`
const Container = styled.div`
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const InnerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledH2 = styled(H2)`
  text-align: center;
`
const CollapsableContainer = styled.div`
  margin-bottom: 3rem;
`
const CollapsableWrapper = styled.div`
  margin-bottom: 0.4rem;
`
const StyledP = styled(P)`
  margin-bottom: 0;
  font-size: 11px;
  color: #222;
  text-align: center;
  line-height: 16px;
`
const Ul = styled.ul`
  list-style: disc;
  padding-left: 3rem;
  margin-bottom: 1em;
  font-size: inherit;
`
const CollapsableTextContainer = styled.div`
  color: #222;
  font-size: 1.5rem;
  @media (max-width: 769px) {
    font-size: 1.2rem;
  }
`
const CollapsableTextP = styled(P)`
  font-size: inherit;
`
// endregion

export default FAQs
