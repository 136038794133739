export const regions = {
  abruzzo: 'Abruzzo',
  basilicata: "Basilicata",
  calabria: "Calabria",
  campania: "Campania",
  emiliaRomagna: "Emilia-Romagna",
  friuliVeneziaGiulia: "Friuli-Venezia Giulia",
  lazio: "Lazio",
/*
  liguria: "Liguria",
*/
  lombardia: "Lombardia",
  marche: "Marche",
  molise: "Molise",
  piemonte: "Piemonte",
  puglia: "Puglia",
  sardegna: "Sardegna",
  sicilia: "Sicilia",
  toscana: "Toscana",
  trentinoAltoAdige: "Trentino-Alto Adige/Südtirol",
  umbria: "Umbria",
  valleDAosta: "Valle d'Aosta/Vallée d'Aoste",
  veneto: "Veneto",
}
