import React from "react"
import styled from "styled-components"

const LiquidityText = () => {
  return (
    <ContainerIntro>
      <Container>
        <Row>
          <Col>
            <p>
              Vuoi anticipare il tuo TFS per ottenere immediata liquidità? Con <strong>Banca Sistema</strong>, realtà finanziaria indipendente già specializzata
              nei prestiti contro cessione del quinto, puoi ottenere in modo <strong>semplice</strong> e <strong>veloce</strong> l’anticipo del tuo <strong>TFS</strong>, anche fino al 95%.
            </p>
          </Col>
        </Row>
      </Container>
    </ContainerIntro>
  )
}

export default LiquidityText

// region Style
const ContainerIntro = styled.div`
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  @media (max-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`
const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 992px) {
    text-align: center;
  }
  & h2 {
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0.75em;
    font-size: 3rem;
  }
`
const Row = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const Col = styled.div`
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  & p {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.5rem;
  }
`
// endregion
