import React from 'react'
import styled from 'styled-components'
import Collapsable from './Collapsable'
import {ReactComponent as Logo} from '../../../img/quinto_puoi_redux_white.svg'
import {ReactComponent as BancaSistema} from '../../../img/banca_sistema_redux_white_new.svg'
import H4 from '../H4'
import P from '../P'
import {ReactComponent as FacebookLogo} from '../../../img/facebook.svg'
import {ReactComponent as LinkedinLogo} from '../../../img/linkedin.svg'
import {ReactComponent as InstagramLogo} from '../../../img/instagram.svg'

const InfoBlock = () => {
  return (
    <Container>
      <InnerContainer>
        <InfoContainer>
          <InnerInfoContainer>
            <LogoContainer>
              <Logo className={'footerLogoIcon'}/>
              <BancaSistema className={'footerBSIcon'}/>
              <StyledP>QuintoPuoi è un prodotto di<br/>Banca Sistema S.p.A.</StyledP>
            </LogoContainer>
            <LegalInfoContainer>
              <StyledH4>
                Sede legale:
              </StyledH4>
              <StyledP style={{margin: 0}}>
                Largo Augusto 1/A, ang. via Verziere 13 – 20122 Milano<br/>
                <a href={'https://www.bancasistema.it'} target={'_blank'} style={{color: 'white'}}>www.bancasistema.it</a>
              </StyledP>
              <StyledP style={{marginTop: 0}}>P.IVA 12870770158 2011-2024 Banca Sistema S.p.A.</StyledP>
            </LegalInfoContainer>
          </InnerInfoContainer>
          <SocialContainer>
            <StyledH4>Seguici sui social <hr/></StyledH4>
            <LogosContainer>
              <LogoLink href={'https://www.facebook.com/bancasistema/'} target={'_blank'} aria-label={'Facebook icon'}>
                <FacebookLogo/>
              </LogoLink>
              <LogoLink href={'https://www.linkedin.com/company/banca-sistema/'} target={'_blank'} aria-label={'Linkedin icon'}>
                <LinkedinLogo/>
              </LogoLink>
              <LogoLink href={'https://www.instagram.com/bancasistema/'} target={'_blank'} aria-label={'Instagram icon'}>
                <InstagramLogo style={{width: 'inherit', height: 'inherit'}}/>
              </LogoLink>
            </LogosContainer>
          </SocialContainer>
        </InfoContainer>
        <PromoContainer>
          <PromoInnerContainer>
            <Collapsable/>
          </PromoInnerContainer>
        </PromoContainer>
      </InnerContainer>
    </Container>
  )
}

// Region Style
const Container = styled.div`
  background-color: #222;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;

  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
`
const InnerContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const InfoContainer = styled.div`
  @media (max-width: 769px) {
    flex-direction: column;
    gap: 3rem;
  }
  padding-bottom: 3rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
`
const InnerInfoContainer = styled.div`
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: baseline;
    align-self: baseline;
    gap: 3rem;
  }
  display: flex;
`
const LogoContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: initial;
`
const LegalInfoContainer = styled.div`
  @media (max-width: 769px) {
    margin: unset;
  }
  text-align: initial;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 103px;
`
const SocialContainer = styled.div`
  padding: 0 15px 0 15px;
  align-self: baseline;
`
const LogosContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`
const LogoLink = styled.a`
  width: 30px;
  cursor: pointer;
`
const PromoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const PromoInnerContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledH4 = styled(H4)`
  text-align: left;
  color: #FFF;
`
const StyledP = styled(P)`
  color: #FFF;
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.8;
  display: block;
`
// endregion

export default InfoBlock
