import React from 'react'
import styled from 'styled-components'
import H2 from '../../commons/H2'
import P from '../../commons/P'
import benefitsImg1 from '../../../img/v_lo_1.svg'
import benefitsImg2 from '../../../img/v_lo_2.svg'
import benefitsImg3 from '../../../img/v_lo_3.svg'
import benefitsImg4 from '../../../img/v_lo_4.svg'
import benefitsImg5 from '../../../img/v_lo_5.svg'
import benefitsImg6 from '../../../img/v_lo_6.svg'
import benefitsImg7 from '../../../img/v_lo_7.svg'
import benefitsImg8 from '../../../img/v_lo_8.svg'
import benefitsImg9 from '../../../img/v_lo_9.svg'

const Benefits = () => {
  return (
    <Container>
      <Title>
        <H2>
          VANTAGGI
        </H2>
      </Title>
      <GridContainer>
        <Grid>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg1} alt={"Icona del simbolo percentuale"}/>
              </ImageContainer>
              <Text>
                Possibilità di richiedere fino al 95% del TFS maturato
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg2} alt={'Due carte di credito che si scambiano'}/>
              </ImageContainer>
              <Text>
                Erogazione dell’importo in un’unica soluzione direttamente sul tuo conto corrente
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg3} alt={'Tuono che trasla'}/>
              </ImageContainer>
              <Text>
                Rapidità di erogazione
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg4} alt={"Icona dell'euro"}/>
              </ImageContainer>
              <Text>
                Erogazione fino a 180.000€
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg5} alt={'Icone di operazioni matematiche: più, meno, per ed uguale'}/>
              </ImageContainer>
              <Text>
                Tasso d’interesse fisso applicato direttamente sulla somma erogata
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg6} alt={'Icona dello smile'}/>
              </ImageContainer>
              <Text>
                Nessuna rata mensile, nessuna trattenuta dalla pensione
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg7} alt={'Icona di un occhio barrato'}/>
              </ImageContainer>
              <Text>
                Nessuna garanzia richiesta oltre al TFS maturato
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg8} alt={'Icoan della spunta gialla'}/>
              </ImageContainer>
              <Text>
                Zero spese di istruttoria (*)
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <ElementContainer>
            <ElementInnerContainer>
              <ImageContainer>
                <Img src={benefitsImg9} alt={'Icona di una coppa'}/>
              </ImageContainer>
              <Text>
                Non è richiesta l'apertura di un conto presso Banca Sistema
              </Text>
            </ElementInnerContainer>
          </ElementContainer>
          <Disclaimer>
            <StyledP>
              (*) Per informazioni economiche e contrattuali fare riferimento alle “Informazioni Europee di Base sul Credito ai Consumatori” (SECCI) che puoi richiedere presso tutte le filiali della Banca e presso gli agenti in attività finanziaria che operano su mandato diretto della Banca per la promozione e il collocamento del prodotto Anticipo TFS. La concessione del finanziamento è subordinata all’approvazione di Banca Sistema S.p.A..
            </StyledP>
          </Disclaimer>
        </Grid>
      </GridContainer>
    </Container>
  )
}

// region Style
const Container = styled.div`
  @media (min-width: 993px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  padding-left: 1.10294rem;
  padding-right: 1.10294rem;
  display: block;
`
const Title = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
`
const GridContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`
const Grid = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const ElementContainer = styled.div`
  text-align: center;
  @media (min-width: 992px){
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const ElementInnerContainer = styled.div`
  @media (min-width: 992px) {
    padding: 3rem;
  }
`
const ImageContainer = styled.div`
  margin-bottom: 1.5rem;
`
const Img = styled.img`
  max-width: 100%;
  height: auto;
  border-style: none;
`
const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #222;
`
const Disclaimer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`
const StyledP = styled(P)`
  margin-bottom: 0;
  font-size: 11px;
  color: #222;
  text-align: center;
  line-height: 16px;
`
// endregion

export default Benefits
