import React, { useEffect, useState } from 'react'
import Header from '../../commons/Header'
import Footer from '../../commons/footer/Footer'
import styled from 'styled-components'
import { VideoProps } from './Video'
import axios from 'axios'
import qs from 'qs'
import { baseUrl } from '../../../config'
import VideoDetail from './VideoDetail'

const Videos = () => {
  const [video, setVideo] = useState<any>()

  const getAllVideos = () => {
    // Funzione per filtrare i video dalla getAll degli articoli
    const filterByCategory = (item: any) => {
      return item.category?.id === 3
    }
    const filters = {
      and: 'published=true',
      order: 'DESC',
      order_field: 'publicationDate'
    }
    const filtersStringify = qs.stringify(filters)
    const getEndpoint = `${baseUrl}/articles?${filtersStringify}`
    axios
      .get(getEndpoint)
      .then(({ data }) => {
        // TODO: scommentare filter per categoria
        const videos = data.payload.filter(filterByCategory)
        setVideo(videos)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getAllVideos()
  }, [])

  return (
    <>
      <Header></Header>
      <Container>
        <SectionTitle>Video</SectionTitle>
        <Section>
          {video &&
            video.map((a: VideoProps, idx: number) => (
              <VideoDetail
                id={a.id}
                title={a.title}
                subtitle={a.subtitle}
                name={a.name}
                key={idx}
                thumbnail={a.thumbnail}/>
            ))}
        </Section>
      </Container>
      <Footer></Footer>
    </>
  )
}

export default Videos

const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 150px;
`
const SectionTitle = styled.h2`
  margin: 2rem 0;
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10rem;
`
