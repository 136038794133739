import React from 'react'
import styled from 'styled-components'
import { thumbnailUrl } from '../../../config'
import { Link } from 'react-router-dom'
import {IArticle} from '../../../types'

export interface ICategory {
  id: number
  name: string
  description: string
}

export interface ArticleProps {
  article: IArticle
}

const Article: React.FC<ArticleProps> = ({ article }) => {
  return (
    <>
      <ArticleContainer>
        <ThumbnailContainer>
          <Img src={`${thumbnailUrl}/${article.thumbnail}`} alt={article.title} />
        </ThumbnailContainer>
        <TitleContainer>{article.title}</TitleContainer>
        <SubTitleContainer>{article.subtitle}</SubTitleContainer>
        <ButtonContainer>
          {article.id && (
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`articles/${article.slug || article.id}`}>
              Vedi Articolo
            </Link>
          )}
        </ButtonContainer>
      </ArticleContainer>
    </>
  )
}

export default Article

//  border: 1px solid rgba(0, 0, 0, 0.12);

const Img = styled.img`
  width: 100%;
  borderradius: 1rem;
  max-height: 280px;
  @media (max-width: 767px) {
    min-width: 497px;
  }
  @media (max-width: 530px) {
    min-width: 350px;
  }
  @media (max-width: 380px) {
    min-width: 250px;
  }
  @media (max-width: 270px) {
    min-width: 150px;
  }
`

const ArticleContainer = styled.div`
  box-shadow: 4px 7px 11px -2px rgba(112, 112, 112, 0.62);
  border-radius: 1rem;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: 768px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
`
const ThumbnailContainer = styled.div`
  max-width: 100%;
  border-radius: 1rem;
`
const TitleContainer = styled.h2`
  margin: 1rem 0;
  text-align: left;
  font-weight: 700;
  padding: 0 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SubTitleContainer = styled.h5`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: 700;
  padding: 0 2rem;
`
const ButtonContainer = styled.div`
  margin: 2rem auto;
  width: 15rem;
  border-width: 1px;
  color: #222222;
  border-color: #18ab29;
  border-radius: 28px;
  background-color: rgb(252, 187, 0);
  box-shadow: 4px 7px 11px -2px rgba(112, 112, 112, 0.62);
`
